const fieldsBoolean = [
    'isBaseUnit',
    'isConsumerUnit',
    'isDispatchUnit',
    'isInvoiceUnit',
    'isOrderableUnit',
    'isTradeItemAService',
    'isVariableWeightItem'
];

export default fieldsBoolean;