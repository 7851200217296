let userTypes = {
    1 : "Supplier Admin",
    2 : "Data Recipient Admin",
    3 : "GS1 Admin",
    4 : "Data Recipient [Public]",
    5 : "Supplier User",
    6 : "Data Recipient User",
    7 : "GS1 User",
}

export default userTypes;