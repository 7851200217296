import { useState } from "react";

export default function useUser() {
    const getUser = () => {
        let userData = undefined
        if(localStorage.getItem("user") !== undefined && localStorage.getItem("user") !== ''){
            userData = JSON.parse(localStorage.getItem("user"));
        } 
        
        return userData;
    };
    const [user, setUser] = useState(getUser());
    const saveUser = (userData) => {
        localStorage.setItem("user", JSON.stringify(userData));
        setUser(userData);
    };
    return {
        setUser: saveUser,
        user,
    };
}
