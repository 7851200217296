import React, { useState, useEffect } from "react";
import { Grid, Select, Radio, FormLabel, FormControl, RadioGroup, FormControlLabel } from '@mui/material';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';

export function ContactSupplierDialog({ open, onCloseSuccess, onCloseFail }) {
	const [message, setMessage] = useState("")

	const handleChange = e => {
    	setMessage({ ...e, [e.target.name]: e.target.value });
  	};

    const handleCloseSuccess = () => {
      	onCloseSuccess(message.message);
    };

    const handleCloseFail = () => {
    	onCloseFail();
  	};

	return (
	    <Dialog open={open} onClose={handleCloseFail} fullWidth={true} maxWidth={"sm"}>
	      	<DialogTitle>Contact Supplier</DialogTitle>
	      	<DialogContent>
	          	<Box noValidate component="form">
          			<FormControl>
		                <TextField
				          	id="message"
				          	label="Message"
				          	name="message"
				          	multiline
				          	variant="filled"
				          	rows={4}
				         	sx={{ width: 550 }}
				         	onChange={handleChange}
				        />
		            </FormControl>
          		</Box>
	      	</DialogContent>
	      	<DialogActions>
	        	<Button variant="orange" onClick={handleCloseFail}>Cancel</Button>
	        	<Button variant="orange" onClick={handleCloseSuccess}>Send message</Button>
	      	</DialogActions>
	    </Dialog>
	  );	
}