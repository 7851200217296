import React from "react";
import { Grid, Typography } from '@mui/material';
import nutrientCodes from './helpers/NutrientCodes';
import nutrientFamily from "./helpers/NutrientFamily";

export default function NutritionalDetails({details}) {    

    const displayNutritionalDetail = (detail) => {
        let nutrientStructure = buildNutrients(detail)
        
        let result = Object.keys(nutrientStructure).map((keyFam)=>{
            let familyNutrient = getNutrient(nutrientStructure[keyFam], keyFam);
            return  <Grid item container direction="row" >
                        {familyNutrient !== undefined ?
                                <Grid container direction="row" width={"100%"} style={lineStyle}>
                                    <Grid item container direction="row" columnSpacing={1} width={"75%"} alignItems="center">
                                        <Grid item><Typography variant="bold" fontSize="12px">{nutrientCodes[familyNutrient["nutrientTypeCode"]]}</Typography></Grid>
                                        <Grid item><Typography fontSize="12px">{Array.isArray(familyNutrient["quantityContained"])?familyNutrient["quantityContained"][0]:familyNutrient["quantityContained"]} {familyNutrient["quantityContainedUom"] !== undefined?familyNutrient["quantityContainedUom"]:''}</Typography></Grid>
                                    </Grid>
                                    <Grid item container direction="row" justifyContent="flex-end" width={"25%"} alignItems="center">
                                        {familyNutrient["dailyValueIntakePercent"] !== undefined?
                                        <Typography fontSize="13px">{parseFloat(familyNutrient["dailyValueIntakePercent"]).toFixed(2)}%</Typography>
                                        :familyNutrient["dailyvalueIntakePercent"] !== undefined?
                                            <Typography fontSize="13px">{parseFloat(familyNutrient["dailyvalueIntakePercent"]).toFixed(2)}%</Typography>
                                            :''
                                        }
                                    </Grid>
                                </Grid>
                            :
                                ''
                        }
                        {
                            nutrientStructure[keyFam].map((nut) => (
                                keyFam !== nut["nutrientTypeCode"] ?
                                        <Grid item container direction="row" width={"100%"} style={lineStyle}>
                                            <Grid item container direction="row" columnSpacing={1} width={"75%"} paddingLeft={keyFam=="OTHER" ? "0px" : "25px"} alignItems="center">
                                                <Grid item><Typography variant={keyFam=="OTHER" ? "bold" : ""}  fontSize="12px">{nutrientCodes[nut["nutrientTypeCode"]]}</Typography></Grid>
                                                <Grid item><Typography fontSize="12px">{Array.isArray(nut["quantityContained"])?nut["quantityContained"][0]:nut["quantityContained"]} {nut["quantityContainedUom"] !== undefined?nut["quantityContainedUom"]:''}</Typography></Grid>
                                            </Grid>
                                            <Grid item container direction="row" justifyContent="flex-end" width={"25%"} alignItems="center">
                                                {nut["dailyValueIntakePercent"] !== undefined?
                                                    <Typography fontSize="13px">{parseFloat(nut["dailyValueIntakePercent"]).toFixed(2)}%</Typography>
                                                :nut["dailyvalueIntakePercent"] !== undefined?
                                                    <Typography fontSize="13px">{parseFloat(nut["dailyvalueIntakePercent"]).toFixed(2)}%</Typography>
                                                    :''
                                                }
                                            </Grid>
                                        </Grid>
                                    :
                                    ''
                            ))
                        }
                    </Grid>
        })

        return result;
    }

    const getNutrient = (list, code) => {
        let nutrient = undefined;
        let list_code = '';
        list.map((n) => {
            list_code = Array.isArray(n['nutrientTypeCode']) ? n['nutrientTypeCode'][0] : n['nutrientTypeCode'];
            if(n['nutrientTypeCode'] == code) {
                nutrient = n;
            }
        });
        return nutrient;
    }

    const getNutrientFamily = (code) => {
        let family = 'OTHER';
        Object.keys(nutrientFamily).map((key) => {
            if(nutrientFamily[key].includes(code)) {
                family = key;
            }
        });

        return family;
    }

    const buildNutrients = (arrayNutrients) => {
        let nutrientStructure = {};
        if(Array.isArray(arrayNutrients)) {
            arrayNutrients.map((value) => {
                let code = Array.isArray(value['nutrientTypeCode']) ? value['nutrientTypeCode'][0] : value['nutrientTypeCode'];
                let family = getNutrientFamily(code);
                if(nutrientStructure[family] === undefined) {
                    nutrientStructure[family] = [];
                }
                nutrientStructure[family].push(value);
            });
        } else {
            let code = arrayNutrients.nutrientTypeCode;
            let family = getNutrientFamily(code);
            if(nutrientStructure[family] === undefined) {
                nutrientStructure[family] = [];
            }
            nutrientStructure[family].push(arrayNutrients);
        }
        return nutrientStructure;
    }

    let lineStyle = { borderBottom: "1px solid #bbb"}

    return displayNutritionalDetail(details)
}