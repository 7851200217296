import React, { useState, useEffect } from "react";
import DashboardProducts from "../DashboardProducts/DashboardProducts";
import DashboardMessages from "../DashboardMessages/DashboardMessages";
import DashboardWidgets from "../DashboardWidgets/DashboardWidgets";
import Header from "../Header/Header";
import { Grid, Box, Typography } from '@mui/material';

import { useSelector } from 'react-redux';
import { Can } from "@casl/react";

export default function Dashboard() {
    const userAbility = useSelector((state) => state.userAbility.value);

    let mainStyle = {
        marginTop: "20px"
    };
    let prodsStyle = {
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: "20px"
    };
    let msgStyle = {
        maxHeight: "360px",
        overflowY: 'auto',
        scrollPadding: "20px",
    };
    let msgStyleDad = {
        paddingLeft: "20px",
        paddingRight: "20px"
    };
    let widgetsDash = {
        paddingTop: '20px'
    };
    let widgetsDashRecent = {
        height: "400px",
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: "20px"
    };
    let dashboardContainer = {
        overflowY: 'auto'
    }

    return  <Box name = "Dashboard" height="100%">
                <Header name="Dashboard" />
                {userAbility !== undefined ?
                    <Can I="view" this="Dashboard" ability={userAbility}>
                        <Box style={dashboardContainer}>
                            <Grid xs={12} container direction="row" style={mainStyle}>
                                <Grid style={prodsStyle} item xs={5}>
                                    <DashboardProducts/>
                                </Grid> 
                                <Grid item xs={7} style={widgetsDashRecent}>
                                    <DashboardWidgets/>
                                </Grid>
                            </Grid>
                            <Grid xs={12} container direction="row" style={msgStyleDad}>
                                <Typography component="p" variant="productTitle" style={{paddingBottom: "10px"}}>Messages</Typography>
                                <Grid style={msgStyle} item xs={12}>
                                    <DashboardMessages/>
                                </Grid>
                            </Grid>
                        </Box>
                    </Can>
                    :
                    ''}
            </Box>;
}
