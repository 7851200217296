import { Typography } from '@mui/material';
import fieldsOrder from './fieldsOrder.js';
import fieldsBoolean from './fieldsBoolean.js';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

export default function QcDetailDifferencesTable({modalContentAttrLabel, showDifferences, modalContentProducts, productRevisions}) {
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    let tableHeader = {fontSize: '16px', color: '#8A98AB', fontFamily: 'Proxima Nova'};
    let tableLabel = {textTransform: 'uppercase', fontSize: '14px', fontFamily: 'Proxima Nova', color: '#8A98AB'};

	return  <TableContainer sx={{ minHeight: 200, maxHeight: 450, border: '1px solid #E4E4E4', borderRadius: '5px'}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={tableHeader}>Field Name</TableCell>
                            <TableCell style={tableHeader}>Current Value</TableCell>
                            <TableCell style={tableHeader}>Previous Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fieldsOrder.map((name, index) => (
                            modalContentAttrLabel[0] !== undefined && modalContentProducts[0] !== undefined?
                                !showDifferences?
                                <StyledTableRow key={index}>
                                    <TableCell style={tableLabel}>
                                        {modalContentAttrLabel[0][name] !== undefined && modalContentAttrLabel[0][name] !== null?modalContentAttrLabel[0][name]:name}
                                    </TableCell>
                                    <TableCell>
                                        <Typography fontSize="14px" fontFamily="Proxima Nova" color="#8A98AB" className={productRevisions[name] !== undefined && productRevisions[name] !== '' && modalContentProducts[0][name] !== undefined && modalContentProducts[0][name] !== '' && (productRevisions[name] !== modalContentProducts[0][name])?"difference":""}>
                                            {modalContentProducts[0][name] !== undefined && modalContentProducts[0][name] !== null?(fieldsBoolean.indexOf(name) > -1 ? (modalContentProducts[0][name] == '1' ? 'True' : (modalContentProducts[0][name] == '0' ? 'False' : '') ): modalContentProducts[0][name] ) : ''}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography fontSize="14px" fontFamily="Proxima Nova" color="#8A98AB" className={productRevisions[name] !== undefined && productRevisions[name] !== '' && modalContentProducts[0][name] !== undefined && modalContentProducts[0][name] !== '' && (productRevisions[name] !== modalContentProducts[0][name])?"difference":""}>
                                            {typeof productRevisions[name] !== undefined?productRevisions[name] !== undefined && productRevisions[name] !== null?productRevisions[name]:'':''}
                                        </Typography>
                                    </TableCell>
                                </StyledTableRow>:
                                <StyledTableRow key={index} className={productRevisions[name] !== undefined && productRevisions[name] !== '' && modalContentProducts[0][name] !== undefined && modalContentProducts[0][name] !== '' && (productRevisions[name] !== modalContentProducts[0][name])?'differences':'hide'}>
                                    <TableCell style={tableLabel}>
                                        {modalContentAttrLabel[0][name] !== undefined && modalContentAttrLabel[0][name] !== null?modalContentAttrLabel[0][name]:name}
                                    </TableCell>
                                    <TableCell >
                                        <Typography fontSize="14px" fontFamily="Proxima Nova" color="#8A98AB" className={productRevisions[name] !== undefined && productRevisions[name] !== '' && modalContentProducts[0][name] !== undefined && modalContentProducts[0][name] !== '' && (productRevisions[name] !== modalContentProducts[0][name])?"difference":""}>
                                            {modalContentProducts[0][name] !== undefined && modalContentProducts[0][name] !== null?(fieldsBoolean.indexOf(name) > -1 ? (modalContentProducts[0][name] == '1' ? 'True' : (modalContentProducts[0][name] == '0' ? 'False' : '') ): modalContentProducts[0][name] ) : ''}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography fontSize="14px" fontFamily="Proxima Nova" color="#8A98AB" className={productRevisions[name] !== undefined && productRevisions[name] !== '' && modalContentProducts[0][name] !== undefined && modalContentProducts[0][name] !== '' && (productRevisions[name] !== modalContentProducts[0][name])?"difference":""}>
                                            {typeof productRevisions[name] !== undefined?productRevisions[name] !== undefined && productRevisions[name] !== null?productRevisions[name]:'':''}
                                        </Typography>
                                    </TableCell>
                                </StyledTableRow>
                                :
                                <TableRow></TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

}