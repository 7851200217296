import React, { useState, useEffect } from "react";
import { Box, Grid, Button, Typography, TextField } from '@mui/material';

export default function CompanyInfo({definition, company}) {
    var primaryUserslisting = company.dataControllers.concat(company.userlist);

    if(primaryUserslisting !== undefined && primaryUserslisting !== null){
        primaryUserslisting.forEach(value => {
            if(value !== undefined && value !== null && value.id === company.primaryUser){
                company["ContactName"]= value.first_name + " " + value.last_name
                company["ContactEmail"] = value.email
                company["ContactTel"] = value.user_telephone
            }
        });
    }

    const renderData = (data, company, label) => {
        return  Object.keys(data).map((field) => (
                data[field]["hide"] === undefined ||  data[field]["hide"] === false ?
                    <Grid item container xs={12} direction="row" justifyContent="center" alignItems="center" columnSpacing={2}>
                        <Grid item xs={6}><Typography variant="PDNewLabel">{data[field].textTransform === false?data[field].label:data[field].label.toLowerCase()}:</Typography></Grid>
                        <Grid item xs={6}>
                            <Typography variant="PDNewValue">
                            {company[field] !== null?
                                company[field]["display"] !== undefined ? 
                                    company[field]["display"](company[field]) 
                                    : 
                                    data[field].options !== undefined && Array.isArray(data[field].options) ?
                                        data[field].options.find(({ value }) => value == company[field]).label
                                    :
                                        company[field]
                                    : ''
                            }
                            </Typography>
                        </Grid>
                    </Grid>: ''   
                ))
    }

    let gridStyle = {
        background: "#FFFFFF",
        marginBottom: "10px",
        marginTop: "10px",
        marginRight: '30px',
        padding: "40px 35px",
        boxShadow: "6px 6px 10px rgba(189, 189, 189, 0.1)",
        borderRadius: "10px"
    }

    return  Object.keys(definition).map((def) => (
                <Grid container item rowSpacing={1.5} style={gridStyle}>
                    <Grid item container xs={12} alignItems="center" >
                        <Typography variant="companyDetailsTitle" fontWeight={"700"}>{definition[def].label}</Typography>
                    </Grid>
                    <Grid item xs={12}><Typography variant="PDNewValue">{definition[def].description}</Typography></Grid>
                    {renderData(definition[def].data, company, definition[def].label)}
                </Grid>
            ))
}

