import React, { useState, useEffect } from "react";
import { Grid, Select, Radio, FormLabel, FormControl, RadioGroup, FormControlLabel } from '@mui/material';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

export function MultiExportDialog({ open, onCloseSuccess, onCloseFail }) {
    const [nameType, setNameType] = useState("0")

    const nameOptions = [
      {id: "0", label:"[GTIN][YYYY-MM-DD].pdf"},
      {id: "1", label:"[GTIN][Brand Name][Functional Name][Variant].pdf"},
      {id: "2", label:"[GTIN][Brand][Sub Brand][Functional Name][Variant][Net Content].pdf"},
    ]
    
    const handleCloseSuccess = () => {
      onCloseSuccess(nameType);
    };

    const handleCloseFail = () => {
      onCloseFail();
  };

  return (
    <Dialog open={open} onClose={handleCloseFail} fullWidth={true} maxWidth={"sm"} >
      <DialogTitle>PDF Options</DialogTitle>
      <DialogContent>
          <Box
              noValidate
              component="form"
              sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  m: 'auto',
                  width: 'fit-content',
              }}
          >
            <Grid container direction="column" rowSpacing={2} style={{marginTop: "5px"}}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={nameType}
                  onChange={(e) => {setNameType(e.target.value)}}
                >
                  {nameOptions.map((name) => (
                    <FormControlLabel value={name.id} control={<Radio />} label={name.label} />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="orange" onClick={handleCloseFail}>Cancel</Button>
        <Button variant="orange" onClick={handleCloseSuccess}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
}