import React, { useEffect } from "react";
import useToken from '../App/useToken';
import DashboardMsgElem from "../DashboardMsgElem/DashboardMsgElem";
import { Grid, Box, Typography } from '@mui/material';
import toolsDashboardApi from "../../tools/toolsDashboardApi";
import CircularProgress from '@mui/material/CircularProgress';
import {
    useQuery,
  } from '@tanstack/react-query'

export default function DashboardMessages(props) {
    const { token, setToken } = useToken();
    const { getMessages } = toolsDashboardApi();

    const { isLoading, isError, data, error } = useQuery(['dash_msgs'], () => getMessages({"token": token}), {
        staleTime: 60 * 1000,
        refetchOnMount: true,
    });

    if(!isLoading && !isError && data !== undefined && data.data !== undefined) {
        let msgs = data.data;
        return  <Box>
                    {msgs.map(elem =>{
                        return <DashboardMsgElem data={elem} />
                    })}
                </Box>;
    } else {
        return <Grid item container justifyContent="center" alignContent="center" xs={12} sx={{width: '100%', height: '100%'}}><CircularProgress /></Grid>;
    }
}   