import { useState } from "react";

export default function useProductDetailFilter() {
    const [editProductListFilter, setEditProductListFilter] = useState(
        {
            cu_gtin_code: "",
            ipGLN: "",
            marketCode: "",
            token: "",
        }
    );

    const [productSyncListFilter, setProductSyncListFilter] = useState(
        {
            gln: "",
            gtin: "",
            marketCode: "",
            publishedGTIN:"",
            token: "",
        }
    );

    const setEditProductListFilterKeyValue = (key, value) => {
        let copyOfObject = { ...editProductListFilter };
        copyOfObject[key] = value;
        setEditProductListFilter(copyOfObject);
    };

    const setProductSyncListFilterKeyValue = (key, value) => {
        let copyOfObject = { ...productSyncListFilter };
        copyOfObject[key] = value;
        setProductSyncListFilter(copyOfObject);
    };

    const setGTIN = (value) => {
        setEditProductListFilterKeyValue("cu_gtin_code", value);
        setProductSyncListFilterKeyValue("gtin", value);
    }

    const setPublishedGTIN = (value) => {
        setProductSyncListFilterKeyValue("publishedGTIN", value);
    }

    const setGLN = (value) => {
        setEditProductListFilterKeyValue("ipGLN", value);
        setProductSyncListFilterKeyValue("gln", value);
    }

    const setMarketCode = (value) => {
        setEditProductListFilterKeyValue("marketCode", value);
        setProductSyncListFilterKeyValue("marketCode", value);
    }

    const setToken = (value) => {
        setEditProductListFilterKeyValue("token", value);
        setProductSyncListFilterKeyValue("token", value);
    }

    const checkCompleteEditProductList = () =>{
        return  editProductListFilter.cu_gtin_code !== "" && 
                editProductListFilter.ipGLN !== "" && 
                editProductListFilter.marketCode !== "" &&
                editProductListFilter.token !== "";
    }

    const checkCompleteProductSyncList = () =>{
        return  productSyncListFilter.gln !== "" && 
                productSyncListFilter.gtin !== "" && 
                productSyncListFilter.marketCode !== "" &&
                productSyncListFilter.token !== "";
    }

    return {
        editProductListFilter,
        productSyncListFilter,
        setEditProductListFilter,
        setProductSyncListFilter,
        checkCompleteEditProductList,
        checkCompleteProductSyncList,
    };
}
