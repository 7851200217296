import React, { useState } from "react";
import { Grid, Typography, Select, MenuItem, Button } from '@mui/material';
import toolsManagementApi from "../../../../tools/toolsManagementApi";
import useToken from "../../../App/useToken";
import AddIcon from '@mui/icons-material/Add';

export default function SelectDataControllers({setUpdated, dataControllers, companyId}) {
    const { addDataController} = toolsManagementApi();
    const { token } = useToken();
    const [newDC, setNewDC] = useState(0);

    const onChangeNewDC = (value) => {
        setNewDC(value);
    } 

    const addDataControllerHandler = () => {
        if(newDC !== 0) {
            addDataController({token: token, data_controller_id: newDC}, companyId).then(result => {
                if(result.error === false) {
                    setNewDC(0);
                    setUpdated(true);
                }
            });
        }
    }

    let inputStyle = { 
        width: "100%", 
        fontFamily: "Proxima Nova", 
        height: "40px",
        "&:root": {
            fontWeight: "100",
            heigth: "1rem",
            fontSize: "1rem",
            lineHeight: "1rem",
        },
    };

    return  <Grid container item justifyContent="baseline" alignItems="flex-end" direction="row" rowSpacing={1} columnSpacing={1}>
                <Grid container item xs={7} direction="row">
                    <Grid item container xs={12}  paddingBottom="10px" paddingTop="30px">
                        <Typography variant="userInfoValue">Select Data Controller</Typography>
                    </Grid>
                    <Grid item container xs={12}>
                        <Select
                            fullWidth
                            value={newDC}
                            style={inputStyle}
                            onChange={(e) => onChangeNewDC(e.target.value)}
                        >
                            <MenuItem value={0}>Select User</MenuItem>
                            {dataControllers.map((dc, index) => (
                                <MenuItem value={dc.id}>{dc.user_name} - {dc.email}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
                <Grid item xs={5}>
                    <Button fullWidth variant="orange-nosize" onClick={addDataControllerHandler} startIcon={<AddIcon />} ><Typography>Add Data Controller</Typography></Button>
                </Grid>
            </Grid>
}