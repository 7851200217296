import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Box, Chip, Typography } from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function Multiselect({style, renderLabel, options, value, setValue, onChange, labelRenderized}) {
    const renderValues = () => {
        if(value.length === 0){
            return <Typography>{renderLabel}</Typography>
        }

        return <Typography>{value.join(", ")}</Typography>
    };

    const renderValuesLabel = () => {
        if(value.length === 0){
            return renderLabel
        }

        let renderVal = [];
        options.map(elem => {
            if (value.indexOf(elem.value) > -1) {
                renderVal.push(elem.label);
            }
        })

        return renderVal.join(", ")
    };

    return (
        <Select
            sx={style}
            multiple
            value={value}
            onChange={e => {
                setValue(e.target.value); 
                if(onChange !== undefined) {
                    onChange(e.target.value);
                }
            }}
            native={false}
            displayEmpty={true}
            renderValue={labelRenderized === true ? renderValuesLabel : renderValues}
        >
            {options.map((option) => (
                <MenuItem key={option.key} value={option.value}>
                    <ListItemIcon>
                        <Checkbox checked={value.indexOf(option.key) > -1} />
                    </ListItemIcon>
                    <ListItemText primary={option.label} />
                </MenuItem>
            ))}
        </Select>
        
  );
}
