import React, { useState, useEffect } from "react";
import ToolsApi from './ToolsApi';
import config from "../config/config"

export default function toolsReportsApi() {
    const { post } = ToolsApi();

    const searchReports = async (body, path) => {
        let url = config.API_URL + "/api/"+path+"-list";
        
        let query = undefined
        return post(url, query, body);
    };

    const searchReportsCount = async (body, path) => {
        let url = config.API_URL + "/api/"+path+"-count";
        
        let query = undefined
        return post(url, query, body);
    };

    const prodExportCsv = async (body, path) => {
        let url = config.API_URL + "/api/export-xls";
        
        let query = undefined
        return post(url, query, body);
    }; 
    
    return {
        searchReports,
        searchReportsCount,
        prodExportCsv,
    };
}