import React from "react";
import { Grid, ToggleButtonGroup, ToggleButton, Typography } from '@mui/material';

export default function SourceSelector({value, setValue}) {
    const handleChange = (event, nextValue) => {
        if (nextValue !== null) {
            setValue(nextValue);
        }
    };

    return  <Grid item container direction="row"  alignItems="center" columnSpacing={2} paddingLeft="25px">
                <Grid item>
                    <Typography variant="productSubtitle">
                        Source:
                    </Typography>
                </Grid>
                <Grid item>
                    <ToggleButtonGroup
                        color="primary"
                        value={value}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                    >
                        <ToggleButton value="1ws">1WS</ToggleButton>
                        <ToggleButton value="TS">TrustedSource</ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>;
        
}