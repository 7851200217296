const fieldsOrder = [
    'sender',
    'receiver',
    'dataRecipientGLN',
    'creationDateTime',
    'publishedGTIN',
    'countryOfOriginCountryCode',
    'informationProviderGLN',
    'informationProviderName',
    'targetMarket',
    'targetMarketName',
    'GTIN',
    'productType',
    'gdsnRegistryStatus',
    'alternateItemIdentificationAgency',
    'alternateItemIdentificationId',
    'gtinName',
    'brandname',
    'subBrand',
    'functionalname',
    'variant',
    'netContent',
    'netContentUom',
    'productDescription',
    'invoiceName',
    'globalClassificationCategoryCode',
    'globalClassificationCategoryDefinition',
    'isBaseUnit',
    'isConsumerUnit',
    'isDispatchUnit',
    'isInvoiceUnit',
    'isOrderableUnit',
    'isTradeItemAService',
    'isVariableWeightItem',
    'effectiveDate',
    'lastModifiedDate',
    'publicationDate',
    'startAvailabilityDate',
    'endAvailabilityDate',
    'discontinueDate',
    'minimumTradeItemLifespanFromProduction',
    'minimumTradeItemLifespanFromArrival',
    'depth',
    'depthUom',
    'height',
    'heightUom',
    'width',
    'widthUom',
    'netWeight',
    'netWeightUom',
    'grossWeight',
    'grossWeightUom',
    'packagingTypeCode',
    'packagingWeight',
    'packagingWeightUom',
    'ti',
    'nonGTINPalletTi',
    'hi',
    'nonGTINPalletHi',
    //'numberOfItemsPerPallet', NOT IN INITIAL DATA
    'totalQuantityOfNextLowerTradeItem',
    'percentageOfAlcoholPerVolume',
    'irradiatedcode',
    'geneticallyModifiedDeclarationCode',
    'gs1TradeItemIdentificationKeyCode',
    'gs1TradeItemIdentificationKeyValue',
    'isBarCodeOnPkgVariableMeasureBarCode',
    'isBarcodeSymbologyDerivable',
    'dutyFeeTaxAgencyCode',
    'dutyFeeTaxTypeCode',
    'dutyFeeTaxRate',
    'tradeItemDateOnPackagingTypeCode',
    'maximumTemperature',
    'minimumTemperature',
    'publicationType',
    'typeOfItem',
    'parentGTIN',
    'childGTIN',
    'nappiCode',
    'drugScheduleCode',
    'receivedDate',
    'documentID',
    //'RejectionCode',
    'imageUrl'
];

export default fieldsOrder;