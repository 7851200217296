import React from "react";
import Checkbox from "@mui/material/Checkbox";
import CircleCheckedFilled from "@mui/icons-material/CheckCircle";
import CircleUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

function CheckboxWrapper(props) {
  return (
    <FormGroup>
      {props.inputProps["aria-label"] !== 'Select row' && props.inputProps["aria-label"] !== 'Unselect row'?
        <FormControlLabel control={<Checkbox {...props } style={{display: 'none'}} />} label="PDF" labelPlacement="top" className={'pdfLabel'}/>:
        <FormControlLabel control={<Checkbox {...props } />} />
      }
    </FormGroup>
   
  );
}

export default CheckboxWrapper;