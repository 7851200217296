import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, Autocomplete, FormControlLabel, Switch, Link, Chip} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import usePRFilter from '../App/usePRFilter';
import dayjs from 'dayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export function PrFiltersDialog({ fixedFilters, availableCategories, setCatAutocompleteValues, loadSubcategory, catAutocompleteValues, selectableSubCategories, setSubcatAutocompleteValues, subcatAutocompleteValues, availableBrands, setBrandAutocompleteValues, brandAutocompleteValues, availableStatus, setStatusAutocompleteValues, statusAutocompleteValues, open, setOpen, handleSearch, token, setExportFilterSearchKeyValue, setCategory, setSubCategory, setStatus, setBrand, setSyncDateStart, setSyncDateEnd, setPublicationDateStart, setPublicationDateEnd, filter, setImages,setCallToken, clearFilters, syncDate, setSyncDate, publishDate, setPublishDate, setProdCount, setProducts, setLocalPage}) {
    const handleClose = () => {
        setOpen(false);
    };

    const handleReset = () => {
        clearFilters();
        setCatAutocompleteValues([]);
        setSubcatAutocompleteValues([]);
        setBrandAutocompleteValues([]);
        setStatusAutocompleteValues([]);
        setSyncDate([
            undefined,
            undefined,
        ]);
        setPublishDate([
            undefined,
            undefined,
        ]);
        setProdCount(0);
        setProducts(undefined)
        setLocalPage(0)
    };

    const handleCategoryChange = (value) => {
        let selectedCategories = [];
        value.map((item, index) => {
            selectedCategories.push(item.value)
        });
        setCategory(selectedCategories);
        setExportFilterSearchKeyValue("category", selectedCategories);
    };

    const handleSubcategoryChange = (value) => {
        let selectedSubCategories = [];
        value.map((item, index) => {
            selectedSubCategories.push(item.value)
        });
        setSubCategory(selectedSubCategories);
        setExportFilterSearchKeyValue("sub_category", selectedSubCategories);
    };

    const handleStatusChange = (value) => {
        let selectedStatus = [];
        value.map((item, index) => {
            selectedStatus.push(item.value)
        });
        setStatus(selectedStatus);
        setExportFilterSearchKeyValue("status", selectedStatus);
    };

    const handleBrandChange = (value) => {
        let selectedBrands = [];
        value.map((item, index) => {
            selectedBrands.push(item.value)
        });
        setBrand(selectedBrands);
        setExportFilterSearchKeyValue("brand", selectedBrands);
    };

    function subcategoriesComboBox(props) {
        return <TextField {...props} label="All Subcategories" />
    }

    function categoriesComboBox(props) {
        return <TextField {...props} label="All Categories" />
    }

    function statusComboBox(props) {
        return <TextField {...props} label="All Status" />
    }

    function brandComboBox(props) {
        return <TextField {...props} label="All Brands" />
    }

    function BootstrapDialogTitle(props) {
      const { children, onClose, ...other } = props;

      return (
        <DialogTitle style={{padding: "25px 40px", fontSize: '20px', color:'#3284C6', fontWeight: 400, lineHeight:'24px', backgroundColor: '#ffffff', boxShadow: '0px -1px 8px rgba(30, 177, 224, 0.1)'}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                  aria-label="close"
                  onClick={onClose}
                  sx={{
                    position: 'absolute',
                    right: 40,
                    top: 25,
                    color: "#3284C6",
                    padding: '0'
                  }}
                >
                    <CloseIcon/>
                </IconButton>
              ) : null}
        </DialogTitle>
      );
    }

    useEffect(() => {
        if((syncDate[0] === undefined || syncDate[0] === null) && (syncDate[1] === undefined || syncDate[1] === null)) {
            setExportFilterSearchKeyValue("dateRangeSyncDate", ["",""]);
            setSyncDateStart(null);
            setSyncDateEnd(null);
        } else {
            let dates = ["",""];
            if(syncDate[0] !== undefined && syncDate[0] !== null) {
                dates[0] = syncDate[0].format("YYYY-MM-DD")
                setSyncDateStart(syncDate[0].format("YYYY-DD-MM"))
            }
            if(syncDate[1] !== undefined && syncDate[1] !== null) {
                dates[1] = syncDate[1].format("YYYY-MM-DD")
                setSyncDateEnd(syncDate[1].format("YYYY-DD-MM"))
            }
            setExportFilterSearchKeyValue("dateRangeSyncDate", dates);
        }
        
    }, [syncDate]);

    useEffect(() => {
        if((publishDate[0] === undefined || publishDate[0] === null) && (publishDate[1] === undefined || publishDate[1] === null)) {
            setExportFilterSearchKeyValue("dateRangePublicationDate", ["",""]);
            setPublicationDateStart(null);
            setPublicationDateEnd(null);
        } else {
            let dates = ["",""];
            if(publishDate[0] !== undefined && publishDate[0] !== null) {
                dates[0] = publishDate[0].format("YYYY-MM-DD")
                setPublicationDateStart(publishDate[0].format("YYYY-MM-DD"))
            }
            if(publishDate[1] !== undefined && publishDate[1] !== null) {
                dates[1] = publishDate[1].format("YYYY-MM-DD")
                setPublicationDateEnd(publishDate[1].format("YYYY-MM-DD"))
            }
            setExportFilterSearchKeyValue("dateRangePublicationDate", dates);
        }
        
    }, [publishDate]);

    const shortcutsItems = [
        { label: 'Reset', getValue: () => [null, null] }
    ];

    BootstrapDialogTitle.propTypes = {
      children: PropTypes.node,
      onClose: PropTypes.func.isRequired,
    };

    let gridCellStyle = {padding:"5px 5px"};
    let inputText = {fontWeight: "100", width:"100%", fontFamily: "Proxima Nova"};

    return (
        <Dialog open={open} 
                onClose={handleClose} 
                fullWidth="true" maxWidth="xl" 
                TransitionComponent={Transition}
                transitionDuration={1000}
                PaperProps={{style: {backgroundColor: '#FBFBFB',borderRadius: '10px 0px 0px 10px'}}}
                >   
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                Select your filters
            </BootstrapDialogTitle>
            <DialogContent sx={{padding: "40px!important"}}>
                <Grid container item  xs={12} sm={12} md={12} lg={12} xl={12} paddingBottom="40px">{fixedFilters}</Grid>
                <Grid container item  xs={12} sm={12} md={12} lg={12} xl={12} paddingBottom="40px">
                    <Grid item xs={6} sm={4} md={4} lg={4} xl={4} style={gridCellStyle}>
                        <Autocomplete
                            multiple
                            loading
                            limitTags={2}
                            disablePortal
                            fullWidth
                            disableCloseOnSelect
                            id="combo-box-categories"
                            options={availableCategories}
                            onChange={(e, v) => {setCatAutocompleteValues(v);loadSubcategory(v);handleCategoryChange(v)}}
                            renderInput={categoriesComboBox}        
                            value={catAutocompleteValues}
                            ChipProps={{
                                color:"multiChips",
                                variant:"multipleChips",
                                sx:{fontSize: '11px', 
                                    fontFamily: 'Proxima Nova', 
                                    lineHeight: '13px', 
                                    border: '1px solid #1EB1E0', 
                                    borderRadius: '57px', 
            
                                }
                            }}
                        />                 
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} lg={4} xl={4} style={gridCellStyle}>
                        <Autocomplete
                            multiple
                            loading
                            limitTags={2}
                            disablePortal
                            fullWidth
                            disableCloseOnSelect
                            id="combo-box-subcategories"
                            options={selectableSubCategories}
                            onChange={(e, v) => {setSubcatAutocompleteValues(v);handleSubcategoryChange(v)}}
                            renderInput={subcategoriesComboBox}        
                            value={subcatAutocompleteValues}
                            ChipProps={{
                                color:"multiChips",
                                variant:"multipleChips",
                                sx:{fontSize: '11px', 
                                    fontFamily: 'Proxima Nova', 
                                    lineHeight: '13px', 
                                    border: '1px solid #1EB1E0', 
                                    borderRadius: '57px', 
            
                                }
                            }}

                        />
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} lg={4} xl={4} style={gridCellStyle}>
                        <Autocomplete
                            multiple
                            loading
                            limitTags={2}
                            disablePortal
                            fullWidth
                            disableCloseOnSelect
                            id="combo-box-brands"
                            options={availableBrands}
                            onChange={(e, v) => {setBrandAutocompleteValues(v);handleBrandChange(v)}}
                            renderInput={brandComboBox}        
                            value={brandAutocompleteValues}
                            ChipProps={{
                                color:"multiChips",
                                variant:"multipleChips",
                                sx:{fontSize: '11px', 
                                    fontFamily: 'Proxima Nova', 
                                    lineHeight: '13px', 
                                    border: '1px solid #1EB1E0', 
                                    borderRadius: '57px', 
            
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container item  xs={12} sm={12} md={12} lg={12} xl={12} paddingBottom="40px">
                    <Grid item xs={6} sm={4} md={4} lg={4} xl={4} style={gridCellStyle}>
                        <Autocomplete
                            multiple
                            loading
                            limitTags={2}
                            disablePortal
                            fullWidth
                            disableCloseOnSelect
                            id="combo-box-status"
                            options={availableStatus}
                            onChange={(e, v) => {setStatusAutocompleteValues(v);handleStatusChange(v)}}
                            renderInput={statusComboBox}        
                            value={statusAutocompleteValues}
                            ChipProps={{
                                color:"multiChips",
                                variant:"multipleChips",
                                sx:{fontSize: '11px', 
                                    fontFamily: 'Proxima Nova', 
                                    lineHeight: '13px', 
                                    border: '1px solid #1EB1E0', 
                                    borderRadius: '57px', 
            
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} lg={4} xl={4} style={gridCellStyle}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateRangePicker
                                disableFuture
                                localeText={{ start: 'Sync Start', end: 'Sync End' }}
                                value={syncDate}
                                onChange={(newValue) => setSyncDate(newValue)}
                                slotProps={{
                                    shortcuts: {
                                        items: shortcutsItems,
                                    },
                                }}
                            /> 
                        </LocalizationProvider>
                    </Grid>
                     <Grid item xs={6} sm={4} md={4} lg={4} xl={4} style={gridCellStyle} >                    
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateRangePicker
                                disableFuture
                                localeText={{ start: 'Publish Start', end: 'Publish End' }}
                                value={publishDate}
                                onChange={(newValue) => setPublishDate(newValue)}
                                slotProps={{
                                    shortcuts: {
                                        items: shortcutsItems,
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                <Grid container item  xs={12} sm={12} md={12} lg={12} xl={12} paddingBottom="40px" justifyContent="end">
                    <FormControlLabel labelPlacement="bottom" control={<Switch style={{color:"primary"}} defaultChecked={filter.searchTerms.images == "images"} onChange={(e) => {setImages(e.target.checked ? "images" : null); setExportFilterSearchKeyValue("images", e.target.checked ? "images" : null);}}/>} label="With images" />
                </Grid>
            </DialogContent>
            <DialogActions sx={{backgroundColor: '#FFFFFF', boxShadow: '0px -1px 8px rgba(30, 177, 224, 0.1)', padding: '30px 40px'}}>
                <Grid item container justifyContent={"space-between"} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Button variant="orange-secondary" style={inputText} onClick={() => {handleClose();handleReset();}}>Reset filters</Button>
                    <Button variant="orange" style={inputText} onClick={() => {handleClose();handleSearch()}}>Apply filters</Button>
                </Grid>
            </DialogActions>
        </Dialog>
    );
  }