import React from "react";
import DashPanel from "./DashPanel";
import AssignmentIcon from '@mui/icons-material/Assignment';

export default function AllActiveDashPanel({panel}) {
    let panelStyle = {
        backgroundColor: "#94D96E", 
        color: "white",
        borderRadius: "10px",
        padding: "25px 10px 25px 40px",
        justifyContent: "center",
        fontWeight: "400",
        fontSize: "18px",
        lineHeight: "22px",
        marginBottom: "10px",
        marginRight: "10px"
    }
    return  <DashPanel panelStyle={panelStyle} panelData={panel} icon={<AssignmentIcon />} status={"0"}/>;
}