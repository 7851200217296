import { configureStore } from '@reduxjs/toolkit'
import dashboardReducer from './features/dashboardSlice'
import productBrowserReducer from './features/productBrowserSlice'
import userAbilityReducer from './features/userAbilitySlice'
import openAlertReducer from './features/openAlertSlice';

export default configureStore({
  reducer: {
    dashboard: dashboardReducer,
    productBrowser: productBrowserReducer,
    userAbility: userAbilityReducer,
    openAlert: openAlertReducer,
  },
})