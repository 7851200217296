import React, { useRef, useEffect } from "react";
import { Grid, Box, Tab, Tabs, TextField, Button, Typography} from '@mui/material';
import Menu from '../Menu/Menu';
import { BrowserRouter, useNavigate  } from 'react-router-dom';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./Bootstrap.css";
import { AbilityContext } from '../../permission/Can'
import ability from '../../permission/defineAbility'
import useUser from "../App/useUser";
import defineAbilityFor from "../../permission/defineAbility";
import { useDispatch } from "react-redux";
import {setUserAbility} from "../../features/userAbilitySlice"
import ToolsNotifications from "../../tools/ToolsNotifications";
import { useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


export default function Bootstrap({children}) {
    const openAlert = useSelector((state) => state.openAlert.value);
    const {close: closeNotification} = ToolsNotifications();
    const ref = useRef(null);
    const { user } = useUser();
    const dispatch = useDispatch();

    useEffect(() => {
        if(user !== undefined) {
            dispatch(setUserAbility(defineAbilityFor(user)));
        }
    }, [user])

    const hadleAlertClose = () => {
        closeNotification();
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: "rgb(48, 120, 176)",
                contrastText: "#FAFAFA"
            },
            secondary: {
                main: "#FAFAFA",
                contrastText: "#444"
            },
            orange:{
                main: "#E16C43",
            },
            positive:{
                main: "#94D96E",
            },
            red: {
                main: "#E30505"
            },
            lightOrange: {
                main: "#FFA27E"
            },
            green: {
                main: "#94D96E"
            },
            multiChips: {
                main: "rgba(30, 177, 224, 0.1)",
                contrastText: "#3284C6"
            },
            ghostBlue: {
                main: '#3284C6'
            },
            rejectedReason: {
                main: '#B91C1C'
            }
        },
        MuiDataGrid: {
            styleOverrides: {
                row:{         
                    "&:hover": {
                        backgroundColor: "blue !important"
                    }
                }
            }
        },
        typography: {
            bold: {
                fontWeight: "600",
            },
            important: {
              color: 'white',
              backgroundColor: '#444',
              padding: '5px',
              borderRadius: '3px',
              fontWeight: '700',
              fontSize: '16px'
            },
            importantHierarchy: {
              color: 'white',
              backgroundColor: '#444',
              padding: '5px',
              borderRadius: '3px',
              fontWeight: '700',
              fontSize: '14px'  
            },
            importantOrange: {
                color: 'white',
                backgroundColor: '#E16C43',
                padding: '5px',
                borderRadius: '3px',
                fontWeight: '700'
            },
            importantCircle: {
                color: 'white',
                padding: '10px',
                borderRadius: '115px',
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "28px",
                lineHeight: "34px",
                alignItems: "center",
                textAlign: "center",
            },
            productType: {
                color: 'white',
                backgroundColor: '#444',
                paddingTop: '5px',
                paddingBottom: '5px',
                paddingLeft: '25px',
                paddingRight: '25px',
                borderRadius: '3px',
                fontWeight: '700',
                fontSize: '14px'
            },
            allergenTitle : {
                color: "#8495A6",
                textTransform: "uppercase",
            },
            userInfoTitle: {
                fontWeight: '400',
                fontSize: '17px',
                color: '#3284C6',
                fontFamily: 'DM Sans',
                textDecoration: 'underline',
            },
            userInfoLabel: {
                fontWeight: '700',
                fontSize: '14px',
                color: '#4D4D4D',
                fontFamily: 'DM Sans',
            },
            userInfoValue: {
                fontWeight: '400',
                fontSize: '14px',
                color: '#4D4D4D',
                fontFamily: 'Proxima Nova',
            },
            productTitle: {
                fontStyle: 'normal',
                fontWeight: '700',
                fontSize: '20px',
                lineHeight: '24px',
                color: '#3078B0',
                textTransform: 'uppercase',
            },
            userDisabled: {
                background: "#B6B6B6",
                borderRadius: "4px",
                fontSize: "12px",
                lineHeight: '15px',
                padding: "5px 10px",
                color: "#fff",
                fontFamily: 'Proxima Nova',
                textAlign:'center'
            },
            userActive: {
                background: "#444",
                borderRadius: "4px",
                fontSize: "12px",
                lineHeight: '15px',
                padding: "5px 17px",
                color: "#fff",
                fontFamily: 'Proxima Nova',
                textAlign:'center'
            },
            userTitle : {
                color: "#4D4D4D",
                fontSize: "24px",
                fontFamily: 'DM Sans',
                fontWeight: '500',
            },
            userSubtitle: {
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '18px',
                lineHeight: '22px',
                color: '#B6B6B6',
                fontFamily: 'DM Sans'
            },
            productSubtitle: {
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '18px',
                lineHeight: '22px',
                color: '#B6B6B6',
            },
            PDTitle:{
                fontWeight: "700",
                fontSize: "27px",
                lineHeight: "34px",
                color: "#DF5E3F",
                paddingTop: '10px'
            },
            PDNewLabel:{
                fontFamily: "DM Sans!important",
                fontWeight: "500",
                color: "#4D4D4D",
                textTransform: "capitalize",
                fontSize: "12px",
            },
            PDNewValue:{
                fontFamily: "Proxima Nova",
                color: "#4D4D4D",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "16px"
            },
            PDLabel:{
                fontWeight: "700",
                color: "#444",
                textTransform: 'uppercase',
                fontSize: '12px',
            },
            PDValue:{
                color: "#4D4D4D",
                fontSize: '12px'
            },
            CUTitle:{
                fontWeight: "700",
                fontSize: "27px",
                color: "#DF5E3F",
            },
            selected: {
                color: '#444',
                fontWeight: '700',
            },
            profileLabel: {
                fontWeight: '700',
                fontSize: '12px',
                color: '#FFFFFF',
                fontFamily: 'Proxima Nova',
                textTransform: 'uppercase'
            },
            profileValue: {
                fontWeight: '400',
                fontSize: '12px',
                color: '#FFFFFF',
                fontFamily: 'Proxima Nova'
            },
            subtitle1: {fontWeight: "400", fontSize:"16px", lineHeight:"18px", color: "#4D4D4D", fontFamily: 'Proxima Nova' },
            subtitle2: {fontWeight: "700", fontSize:"15px", lineHeight:"15px", color: "#4D4D4D", fontFamily: 'Proxima Nova' },
            profileName: {fontWeight: "400", fontSize:"18px", lineHeight:"22px", color: "#FFFFFF", fontFamily: 'Proxima Nova', cursor: 'pointer'},
            profileCompany: {fontWeight: "700", fontSize:"14px", lineHeight:"17px", color: "#FFFFFF", fontFamily: 'Proxima Nova', cursor: 'pointer'},
            circular: {
                fontWeight: '700',
                fontSize: '22px',
                lineHeight: '27px',
                color: '#4D4D4D',
            },
            blue: {
                color:"rgb(48, 120, 176)",
                fontSize: '14px',
                lineHeight: '17px'
            },
            blueHierarchy: {
                color:"#444",
                fontSize: '14px',
                lineHeight: '17px',
                fontWeight: '700'
            },
            qcProductDetailTitle: {
                padding: '10px 15px',
                fontSize: '18px',
                lineHeight: '22px',
                fontFamily: 'Proxima Nova',
                backgroundColor: '#444',
                color: '#FFFFFF',
                fontWeight: '400',
                width: '100%'
            },
            qcProductDetailTitleBlue: {
                padding: '10px 15px',
                fontSize: '18px',
                lineHeight: '22px',
                fontFamily: 'Proxima Nova',
                backgroundColor: '#3284C6',
                color: '#FFFFFF',
                fontWeight: '400',
                width: '100%'
            },
            keyword: {
                border: "1px solid #3284C6",
                borderRadius: "4px",
                fontWeight: "700",
                alignItems: "center",
                textAlign: "center",
                color: "#3284C6",
                padding: "10px",
                fontSize: '12px',
                lineHeight: '12px'
            },
            nutritionalTagTitle: {
                fontWeight: "700",
                fontSize: "3rem",
                margin: "0 0 0.25rem",
                color: "#203040",
            },
            nutritionalTagSubTitle: {
                fontSize: "12px",
                fontWeight: "normal",
                margin: "10px 0px 10px 0px",
                color: "#203040",
                textTransform: "uppercase",
            },
            nutritionalTagSmallUpper: {
                fontSize: "9px",
                padding: "0!important",
                color: "#203040",
                textTransform: "uppercase",
            }, 
            nutritionalTagSmall: {
                fontSize: "12px",
                padding: "0!important",
                color: "#203040",
            }, 
            nutritionalTagXS: {
                fontSize: "8px",
                padding: "0!important",
                color: "#203040",
            },PDTitleGrey:{
                fontWeight: "700",
                fontSize: "28px",
                lineHeight: "34px",
                color: "#B6B6B6",
            },
            grey: {
                color: "#B6B6B6"
            },
            enrichmentsButton: {
                textTransform: "capitalize",
            },
            mainEnrichmentsText: {
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "20px",
                textAlign: "justify",
                fontFamily: "Proxima Nova",
                color: "#4D4D4D",
                paddingTop: "16px",
            },
            mainTitleEnrichments: {
                fontFamily: 'Proxima Nova',
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "28px",
                lineHeight: "34px",
                alignItems: "center",
                color: "#444",
                paddingLeft: "20px",
            },
            titleEnrichments: {
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "28px",
                lineHeight: "34px",
                alignItems: "center",
                color: "#DF5E3F",
                textTransform: "capitalize",
            },
            subtitleEnrichments: {
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: "19px",
                alignItems: "center",
                color: "#B6B6B6"
            },
            companyName: {
                color: '#3284C6', 
                fontWeight: '400', 
                fontSize: '24px', 
                lineHeight: '30px'
            },
            companyDetailsTitle: {
                fontSize: '20px',
                lineHeight: '24px',
                color: '#DF5E3F'
            },
            companyUserName: {
                fontFamily: 'DM Sans',
                fontWeight: '500',
                fontSize: '12px',
                lineHeight: '16px',
                color: '#4D4D4D'
            },
            companyUserRol: {
                fontFamily: 'DM Sans',
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '16px',
                color: '#B6B6B6'
            },
            companyNoDC: {
                color: '#3284C6',
                fontSize: '20px',
                lineHeight: '24px',
            },
            companyNoUsers: {
                color: '#3284C6',
                fontSize: '30px',
                lineHeight: '37px'
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                "@global": {
                    "&::WebkitScrollbarThumb": {
                        width: "11px",
                        height: "11px",
                        background: "#3284C6",
                        borderRadius: "40px"
                    },
                    "&::WebkitScrollbarTrack": {
                        background: "white"
                    }
                }
            }
        },
        components: {
            MuiToggleButton: {
                styleOverrides: {
                    root: {
                        "&":{
                            color: "#B6B6B6",
                            backgroundColor: '#E4E4E4',
                            fontFamily: 'Proxima Nova',
                            fontSize: '16px',
                            fontWeight: '700'
                        },
                        "&.Mui-selected": {
                            color: "#FAFAFA",
                            backgroundColor: '#444',
                            fontFamily: 'Proxima Nova',
                            fontSize: '16px',
                            fontWeight: '700'
                        },
                        "&.Mui-selected:hover": {
                            color: "#FAFAFA",
                            backgroundColor: '#444',
                            fontFamily: 'Proxima Nova',
                            fontSize: '16px',
                            fontWeight: '700'
                        },
                        "&:hover": {
                            color: '#FAFAFA',
                            backgroundColor: '#444',
                            fontFamily: 'Proxima Nova',
                            fontSize: '16px',
                            fontWeight: '700'
                        }
                    }
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        color: "#444",
                        fontFamily: 'Proxima Nova'
                    }
                }
            },
            MuiSelect: {
                styleOverrides: {
                  select: {
                    backgroundColor: "#FAFAFA",
                    color: "#444",
                    fontFamily: 'Proxima Nova'
                  }
                }
            },
            MuiButton:{
                variants: [
                    {
                        props: { variant: 'emailButton' },
                        style: {
                            "&": {
                                color: "#666E71",
                                border: "1px solid #666E71",
                                borderRadius: "0px",
                                textTransform: "none",
                                fontFamily: "DM Sans",
                                fontSize: '10px',
                                lineHeight: '12px',
                                fontWeight: '400!important'
                            },
                            "&:hover": {
                                color: "#666E71",
                                border: "1px solid #666E71",
                                borderRadius: "0px",
                                textTransform: "none",
                                fontFamily: "DM Sans",
                                fontSize: '10px',
                                lineHeight: '12px',
                                fontWeight: '400!important'
                            }
                        },
                    },
                    {
                        props: { variant: 'userDisabledFull' },
                        style: {
                            "&": {
                                backgroundColor: "#8495a6",
                                fontWeight: '400',
                                borderRadius: "4px",
                                fontSize: "15px",
                                padding: "3px 4px",
                                color: "#fff",
                                fontFamily: 'Proxima Nova',
                                minWidth: '50px',
                                textAlign:'center',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                            },
                            "&:hover": {
                                backgroundColor: "#8495a6",
                                fontWeight: '400',
                                borderRadius: "4px",
                                fontSize: "15px",
                                padding: "3px 4px",
                                color: "#fff",
                                fontFamily: 'Proxima Nova',
                                minWidth: '50px',
                                textAlign:'center',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                            }
                        },
                    },
                    {
                        props: { variant: 'userActiveFull' },
                        style: {
                            "&": {
                                width: "100%",
                                backgroundColor: "#444",
                                fontWeight: '400',
                                borderRadius: "4px",
                                fontSize: "15px",
                                padding: "3px 4px",
                                color: "#fff",
                                fontFamily: 'Proxima Nova',
                                minWidth: '50px',
                                textAlign:'center', 
                                textTransform: 'none',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                            },
                            "&:hover": {
                                width: "100%",
                                backgroundColor: "#444",
                                fontWeight: '400',
                                borderRadius: "4px",
                                fontSize: "15px",
                                padding: "3px 4px",
                                color: "#fff",
                                fontFamily: 'Proxima Nova',
                                minWidth: '50px',
                                textAlign:'center',
                                textTransform: 'none',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                            }
                        },
                    },
                    {
                        props: { variant: 'main' },
                        style: {
                            "&": {
                                color: "#FAFAFA",
                                backgroundColor: '#444',
                                border: "1px solid #444",
                                textTransform: "none",
                                fontFamily: "Proxima Nova",
                                fontSize: '12px',
                                fontWeight: '700!important',
                                height: '40px',
                                textTransform: 'none',
                            },
                            "&:hover": {
                                color: "#444",
                                backgroundColor: '#FAFAFA',
                                textTransform: "none",
                                fontFamily: "Proxima Nova",
                                fontSize: '12px',
                                fontWeight: '700!important',
                                height: '40px',
                                textTransform: 'none',
                            }
                        },
                    },
                    {
                        props: { variant: 'inverted-main' },
                        style: {
                            "&": {
                                color: "#444",
                                backgroundColor: '#FAFAFA',
                                border: "1px solid #444",
                                textTransform: "none",
                                fontFamily: "Proxima Nova",
                                fontSize: '12px',
                                fontWeight: '700!important',
                                height: '40px'
                            },
                            "&:hover": {
                                color: "#FAFAFA",
                                backgroundColor: '#444',
                                textTransform: "none",
                                fontFamily: "Proxima Nova",
                                fontSize: '12px',
                                fontWeight: '700!important',
                                height: '40px'
                            }
                        },
                    },
                    {
                        props: { variant: 'orange-nosize' },
                        style: {
                            "&":{
                                color: "#FAFAFA",
                                backgroundColor: '#E16C43',
                                border: "1px solid #E16C43",
                                textTransform: "none",
                                height: '40px',
                                borderRadius:'4px',
                                fontSize: '12px',
                                fontWeight: '700!important',
                                fontFamily: 'Proxima Nova'
                            },
                            "&:hover": {
                                color: "#E16C43",
                                backgroundColor: '#FAFAFA',
                                border: "1px solid #E16C43",
                                textTransform: "none",
                                height: '40px',
                                borderRadius:'4px',
                                fontSize: '12px',
                                fontWeight: '700!important',
                                fontFamily: 'Proxima Nova'
                            }
                        },
                    },
                    {
                        props: { variant: 'orange' },
                        style: {
                            "&":{
                                color: "#FAFAFA",
                                backgroundColor: '#E16C43',
                                border: "1px solid #E16C43",
                                textTransform: "none",
                                height: '40px',
                                borderRadius:'4px',
                                fontSize: '12px',
                                fontWeight: '700!important',
                                width: '125px!important',
                                fontFamily: 'Proxima Nova'
                            },
                            "&:hover": {
                                color: "#E16C43",
                                backgroundColor: '#FAFAFA',
                                border: "1px solid #E16C43",
                                textTransform: "none",
                                height: '40px',
                                borderRadius:'4px',
                                fontSize: '12px',
                                fontWeight: '700!important',
                                width: '125px!important',
                                fontFamily: 'Proxima Nova'
                            }
                        },
                    },
                    {
                        props: { variant: 'orange-nosize' },
                        style: {
                            "&":{
                                color: "#FAFAFA",
                                backgroundColor: '#E16C43',
                                border: "1px solid #E16C43",
                                textTransform: "none",
                                height: '40px',
                                borderRadius:'4px',
                                fontSize: '12px',
                                fontWeight: '700!important',
                                fontFamily: 'Proxima Nova'
                            },
                            "&:hover": {
                                color: "#E16C43",
                                backgroundColor: '#FAFAFA',
                                border: "1px solid #E16C43",
                                textTransform: "none",
                                height: '40px',
                                borderRadius:'4px',
                                fontSize: '12px',
                                fontWeight: '700!important',
                                fontFamily: 'Proxima Nova'
                            }
                        },
                    },
                    {
                        props: { variant: 'orange-secondary' },
                        style: {
                            "&":{
                                color: "#E16C43",
                                backgroundColor: '#FAFAFA',
                                border: "1px solid",
                                textTransform: "none",
                                width: '125px!important',
                                height: '40px',
                                borderRadius:'4px',
                                fontSize: '12px',
                                fontWeight: '700!important'
                            },
                            "&:hover": {
                                color: "#FAFAFA",
                                backgroundColor: '#E16C43',
                                border: "1px solid #E16C43",
                                textTransform: "none",
                                width: '125px!important',
                                height: '40px',
                                borderRadius:'4px',
                                fontSize: '12px',
                                fontWeight: '700!important'
                            }
                        },
                    },
                    {
                        props: { variant: 'orange-secondary-nosize' },
                        style: {
                            "&":{
                                color: "#E16C43",
                                backgroundColor: '#FAFAFA',
                                border: "1px solid",
                                textTransform: "none",
                                height: '40px',
                                borderRadius:'4px',
                                fontSize: '12px',
                                fontWeight: '700!important'
                            },
                            "&:hover": {
                                color: "#FAFAFA",
                                backgroundColor: '#E16C43',
                                border: "1px solid #E16C43",
                                textTransform: "none",
                                height: '40px',
                                borderRadius:'4px',
                                fontSize: '12px',
                                fontWeight: '700!important'
                            }
                        },
                    },
                    {
                        props: { variant: 'orange-contact-supplier'},
                        style: {
                            "&":{
                                color: "#E16C43",
                                backgroundColor: '#FAFAFA',
                                border: "1px solid",
                                textTransform: "none",
                                width: '100%',
                                height: '40px',
                                borderRadius:'4px',
                                fontSize: '12px',
                                fontWeight: '700!important'
                            },
                            "&:hover": {
                                color: "#FAFAFA",
                                backgroundColor: '#E16C43',
                                border: "1px solid #E16C43",
                                textTransform: "none",
                                width: '100%',
                                height: '40px',
                                borderRadius:'4px',
                                fontSize: '12px',
                                fontWeight: '700!important'
                            }
                        },
                    },
                    {
                        props: { variant: 'keywordOrange' },
                        style: {
                            "&":{
                                border: "1px solid #E16C43",
                                borderRadius: "4px",
                                fontWeight: "700",
                                alignItems: "center",
                                textAlign: "center",
                                color: "#E16C43",
                                padding: "10px",
                                minWidth: "125px",
                            },
                            "&:hover": {
                                border: "1px solid #E16C43",
                                borderRadius: "4px",
                                fontWeight: "700",
                                alignItems: "center",
                                textAlign: "center",
                                color: "#E16C43",
                                padding: "10px",
                                backgroundColor: "rgba(255, 162, 126, 0.5)",
                                minWidth: "125px",
                            }
                        },
                    },
                    {
                        props: { variant: 'keywordOrangeSelected' },
                        style: {
                            "&":{
                                border: "1px solid #E16C43",
                                borderRadius: "4px",
                                fontWeight: "700",
                                alignItems: "center",
                                textAlign: "center",
                                color: "#E16C43",
                                padding: "10px",
                                backgroundColor: "rgba(255, 162, 126, 0.5)",
                                minWidth: "125px",
                            },
                            "&:hover": {
                                border: "1px solid #E16C43",
                                borderRadius: "4px",
                                fontWeight: "700",
                                alignItems: "center",
                                textAlign: "center",
                                color: "#E16C43",
                                padding: "10px",
                                minWidth: "125px",
                            }
                        },
                    },
                    {
                        props: { variant: 'keywordGrey' },
                        style: {
                            "&":{
                                border: "1px solid #B6B6B6",
                                borderRadius: "4px",
                                fontWeight: "700",
                                alignItems: "center",
                                textAlign: "center",
                                color: "white",
                                padding: "10px",
                                backgroundColor: "#B6B6B6",
                                minWidth: "125px",
                            }
                        },
                    },
                    {
                        props: { variant: 'enrichmentsBlue' },
                        style: {
                            "&":{
                                border: "1px solid #FFFFFF",
                                borderRadius: "10px",
                                fontWeight: "700",
                                alignItems: "center",
                                textAlign: "center",
                                color: "white",
                                padding: "10px",
                                backgroundColor: "#444",
                                minWidth: "200px",
                            },
                            "&:hover": {
                                border: "1px solid #FFFFFF",
                                borderRadius: "10px",
                                fontWeight: "700",
                                alignItems: "center",
                                textAlign: "center",
                                color: "white",
                                padding: "10px",
                                backgroundColor: "#444",
                                minWidth: "200px",  
                            }
                        },
                    },
                    {
                        props: { variant: 'enrichmentsGreen' },
                        style: {
                            "&":{
                                border: "1px solid #FFFFFF",
                                borderRadius: "10px",
                                fontWeight: "700",
                                alignItems: "center",
                                textAlign: "center",
                                color: "white",
                                padding: "10px",
                                backgroundColor: "#94D96E",
                                minWidth: "200px",
                            },
                            "&:hover":{
                                border: "1px solid #FFFFFF",
                                borderRadius: "10px",
                                fontWeight: "700",
                                alignItems: "center",
                                textAlign: "center",
                                color: "white",
                                padding: "10px",
                                backgroundColor: "#94D96E",
                                minWidth: "200px",
                            }
                        },
                    },
                    {
                        props: { variant: 'enrichmentsOrange' },
                        style: {
                            "&":{
                                border: "1px solid #FFFFFF",
                                borderRadius: "10px",
                                fontWeight: "700",
                                alignItems: "center",
                                textAlign: "center",
                                color: "white",
                                padding: "10px",
                                backgroundColor: "#FFA27E",
                                minWidth: "200px",
                            },
                            "&:hover":{
                                border: "1px solid #FFFFFF",
                                borderRadius: "10px",
                                fontWeight: "700",
                                alignItems: "center",
                                textAlign: "center",
                                color: "white",
                                padding: "10px",
                                backgroundColor: "#FFA27E",
                                minWidth: "200px",
                            }
                        },
                    },
                    {
                        props: { variant: 'registered' },
                        style: {
                            "&":{
                                color: "#FFFFFF",
                                backgroundColor: '#85C163',
                                border: "1px solid #85C163",
                                textTransform: "capitalize",
                                height: '40px',
                                borderRadius:'4px',
                                fontSize: '12px',
                                fontWeight: '700',
                                fontFamily: 'Proxima Nova',
                                width: '100px'
                            },
                            "&:hover": {
                                color: "#FFFFFF",
                                backgroundColor: '#85C163',
                                border: "1px solid #85C163",
                                textTransform: "capitalize",
                                height: '40px',
                                borderRadius:'4px',
                                fontSize: '12px',
                                fontWeight: '700',
                                fontFamily: 'Proxima Nova',
                                width: '100px'
                            }
                        },
                    },
                    {
                        props: { variant: 'discontinued' },
                        style: {
                            "&":{
                                color: "#FFFFFF",
                                backgroundColor: '#E30505',
                                border: "1px solid #E30505",
                                textTransform: "capitalize",
                                height: '40px',
                                borderRadius:'4px',
                                fontSize: '12px',
                                fontWeight: '700',
                                fontFamily: 'Proxima Nova',
                                width: '100px'
                            },
                            "&:hover": {
                                color: "#FFFFFF",
                                backgroundColor: '#E30505',
                                border: "1px solid #E30505",
                                textTransform: "capitalize",
                                height: '40px',
                                borderRadius:'4px',
                                fontSize: '12px',
                                fontWeight: '700',
                                fontFamily: 'Proxima Nova',
                                width: '100px'
                            }
                        },
                    },
                    {
                        props: { variant: 'inprogress' },
                        style: {
                            "&":{
                                color: "#FFFFFF",
                                backgroundColor: '#B6B6B6',
                                border: "1px solid #B6B6B6",
                                textTransform: "capitalize",
                                height: '40px',
                                borderRadius:'4px',
                                fontSize: '12px',
                                fontWeight: '700',
                                fontFamily: 'Proxima Nova',
                                width: '100px'
                            },
                            "&:hover": {
                                color: "#FFFFFF",
                                backgroundColor: '#B6B6B6',
                                border: "1px solid #B6B6B6",
                                textTransform: "capitalize",
                                height: '40px',
                                borderRadius:'4px',
                                fontSize: '12px',
                                fontWeight: '700',
                                fontFamily: 'Proxima Nova',
                                width: '100px'
                            }
                        },
                    },
                    {
                        props: { variant: 'nostatus' },
                        style: {
                            "&": {
                                color: "#B6B6B6",
                                backgroundColor: 'rgba(182, 182, 182, 0.1)',
                                border: "1px solid #B6B6B6",
                                textTransform: "capitalize",
                                fontFamily: "Proxima Nova",
                                fontSize: '12px',
                                fontWeight: '700',
                                height: '40px',
                                width: '100px'
                            },
                            "&:hover": {
                                color: "#B6B6B6",
                                backgroundColor: 'rgba(182, 182, 182, 0.1)',
                                border: "1px solid #B6B6B6",
                                textTransform: "capitalize",
                                fontFamily: "Proxima Nova",
                                fontSize: '12px',
                                fontWeight: '700',
                                height: '40px',
                                width: '100px'
                            }
                        },
                    },
                    {
                        props: { variant: 'editProfile' },
                        style: {
                            "&": {
                                color: "#FAFAFA",
                                backgroundColor: '#3078B0',
                                border: "1px solid #FFFFFF",
                                textTransform: "none",
                                fontFamily: "Proxima Nova",
                                fontSize: '12px',
                                fontWeight: '700!important',
                                height: '40px'
                            },
                            "&:hover": {
                                color: "#3078B0",
                                backgroundColor: '#FAFAFA',
                                textTransform: "none",
                                fontFamily: "Proxima Nova",
                                fontSize: '12px',
                                fontWeight: '700!important',
                                height: '40px'
                            }
                        },
                    },
                    {
                        props: { variant: 'qcAccept' },
                        style: {
                            "&": {
                                color: "#FFFFFF",
                                backgroundColor: '#85C163',
                                border: "1px solid #85C163",
                                borderRadius: '4px',
                                textTransform: "none",
                                fontFamily: "Proxima Nova"
                            },
                            "&:hover": {
                                color: "#85C163",
                                backgroundColor: '#FAFAFA',
                                textTransform: "none",
                                fontFamily: "Proxima Nova"
                            }
                        },
                    },
                    {
                        props: { variant: 'qcBlue' },
                        style: {
                            "&": {
                                color: "#FFFFFF",
                                backgroundColor: '#3284C6',
                                border: "1px solid #3284C6",
                                borderRadius: '4px',
                                fontFamily: "Proxima Nova",
                                fontSize: '16px',
                                fontWeight: '400',
                                lineHeight: '19px',
                                textTransform: 'none',
                                padding: '10px 22px'
                            },
                            "&:hover": {
                                color: "#FFFFFF",
                                backgroundColor: '#3284C6',
                                border: "1px solid #3284C6",
                                borderRadius: '4px',
                                fontFamily: "Proxima Nova",
                                fontSize: '16px',
                                fontWeight: '400',
                                lineHeight: '19px',
                                textTransform: 'none',
                                padding: '10px 22px'
                            }
                        },
                    },
                    {
                        props: { variant: 'ghostBlueCompany'},
                        style: {
                            "&": {
                                color: "#3284C6",
                                backgroundColor: '#FFFFFF',
                                border: "1px solid #3284C6",
                                borderRadius: '4px',
                                fontFamily: "Proxima Nova",
                                fontSize: '12px',
                                fontWeight: '700',
                                lineHeight: '15px',
                                textTransform: 'none',
                                padding: '6px 16px',
                                height: '40px'
                            },
                            "&:hover": {
                                color: "#444",
                                backgroundColor: 'rgba(30, 177, 224, 0.2)',
                                border: "1px solid #444",
                                borderRadius: '4px',
                                fontFamily: "Proxima Nova",
                                fontSize: '12px',
                                fontWeight: '700',
                                lineHeight: '15px',
                                textTransform: 'none',
                                padding: '6px 16px',
                                height: '40px'
                            }
                        },
                    },
                ],
            },
            MuiTextField: {
                defaultProps: {
                  variant: "outlined",
                },
                styleOverrides: {
                    root: {                
                        "& label": {
                            // set some styles for the label if need it
                            color: "#444",
                            fontFamily: 'Proxima Nova',
                        },
                        "& input": {
                            // set some styles for the label if need it
                            color: "#444",
                            fontFamily: 'Proxima Nova',
                        },
                        "& legend": {
                            // set some styles for the legend if need it
                            color: "#444"
                        },
                    }
                }
            }, 
            MuiInputBase: {
                defaultProps: {
                    variant: "outlined",
                },
                styleOverrides: {
                    root: {                
                        "& label": {
                            // set some styles for the label if need it
                            color: "#444",
                            fontFamily: 'Proxima Nova',
                        },
                        "& input": {
                            // set some styles for the label if need it
                            color: "#444",
                            fontFamily: 'Proxima Nova',
                            fontSize: '12px'
                        },
                        "& legend": {
                            // set some styles for the legend if need it
                            color: "#444",
                            fontFamily: 'Proxima Nova',
                            fontSize: '12px'
                        }
                    }
                }
            },
            MuiChip: {
                defaultProps: {
                    variant: "multipleChips"
                },
                styleOverrides: {
                    label: {
                        paddingRight: '30px',
                        paddingTop: '11px', 
                        paddingBottom: '11px',
                        paddingLeft: '20px'
                    }
                }
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        fontFamily: 'Proxima Nova'
                    },
                    'no-data-found': {
                        color: '#000000',
                        background: 'rgba(227, 5, 5, 0.21)',
                        padding: '5px 10px',
                        fontSize: '12px',
                        lineHeight: '16px',
                        fontWeight: '400'
                    }
                },
            },
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        border: 'none'
                    },
                    columnHeaderTitleContainer: {
                        fontFamily: 'Proxima Nova',
                        color: "#3078B0",
                        fontWeight: "700",
                        fontSize: "16px"
                    },
                    columnHeaderTitle: {
                        fontFamily: 'Proxima Nova',
                        color: "#3078B0",
                        fontWeight: "700",
                        fontSize: "16px"
                    },
                    cellContent: {
                        fontFamily: 'Proxima Nova',
                        color: "#4D4D4D",
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "14.62px"
                    }
                }
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        marginLeft: '0',
                        marginRight: '0'
                    }
                }
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        "&": {
                            fontFamily: 'Proxima Nova'
                        },
                        "&.Mui-selected": {
                            color: "#FFFFFF",
                            backgroundColor: 'rgb(50, 132, 198)',
                            fontFamily: 'Proxima Nova',
                            
                        },
                    }
                }
            },
            MuiAlert: {
                styleOverrides: {
                    tsSuccess: {
                        fontSize: '19px',
                        fontFamily: 'Proxima Nova',
                        padding: '10px 30px',
                        width: '100%', 
                        backgroundColor: '#94D96E', 
                        color: '#ffffff'
                    },
                    tsError: {
                        fontSize: '19px',
                        fontFamily: 'Proxima Nova',
                        padding: '10px 30px',
                        width: '100%', 
                        backgroundColor: '#E30505!important', 
                        color: '#ffffff'
                    }
                }
            }
        }
    });

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    return  <div className="main">
                <AbilityContext.Provider value={ability}>
                    <ThemeProvider theme={theme}>
                        <Grid 
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            style={{height: '100%'}}
                        >
                            <Grid item xs={2} height={"100%"}>
                                <Menu />
                            </Grid>
                            <Grid height={'100%'} direction="column" item xs={10} container alignItems="left">
                                {children}
                            </Grid>
                            <Snackbar open={openAlert.open} autoHideDuration={6000} onClose={hadleAlertClose}>
                                <Alert onClose={hadleAlertClose} severity={openAlert.severity} sx={{ width: '100%' }}>
                                    {openAlert.message}
                                </Alert>
                            </Snackbar>
                        </Grid>
                    </ThemeProvider>
                </AbilityContext.Provider>
            </div>;
}