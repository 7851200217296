import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem, Grid, Button, Box, List, ListItem, ListItemButton, ListItemText, ListItemIcon, Typography } from '@mui/material';
import toolsProductDetailApi from "../../tools/toolsProductDetailApi";
import { ContactSupplierDialog } from "../Dialog/ContactSupplierDialog";
import { AlertResponse } from "../Alert/AlertResponse";
import LinkIcon from '@mui/icons-material/Link';
import { Can } from "@casl/react";
import { useSelector } from 'react-redux';

export default function ProductSyncData({sync, withdrawn, filter, setFilter, setMessageId}) {
    const [syncCompany, setSyncCompany] = useState(0);
    const {contactSupplier} = toolsProductDetailApi();
    const [contactSupplierModalOpen, setContactSupplierModalOpen] = useState(false);
    const [contactSupplierResponse, setContactSupplierResponse] = useState(false);
    const [contactSupplierResponseMessage, setContactSupplierResponseMessage] = useState("");
    const userAbility = useSelector((state) => state.userAbility.value);

    useEffect(() => {
        let updFilter = filter;
        updFilter["dataRecipient"] = sync[syncCompany].GLN;
    }, [syncCompany])

    useEffect(() => {
        if(Array.isArray(sync)) {
            sync.map((list, index) => {
                list.className = '';
                list.date = list.lastSyncDate.trim();

                if(list.GLN === '6001008000003') {
                    list.url = 'https://www.sparportal.co.za/';
                }

                withdrawn.map((withdrawn, indexw) => {
                    if(list.GLN === withdrawn.GLN) {
                        list.className = 'red';
                        list.CompanyName = list.CompanyName + ' ' +'(Withdrawn)';
                        list.date = withdrawn.lastSyncDate.trim();
                    }
                });
            });
        }
    }, [sync, withdrawn])

    const handleChange = (company) => {
        if(company.GLN === '6001008000003') {
            window.open('https://www.sparportal.co.za/', '_blank');
        } else {
            setFilter({
                cu_gtin_code: filter.cu_gtin_code,
                ipGLN: filter.ipGLN,
                dataRecipient: company.GLN,
                marketCode: filter.marketCode,
                token: filter.token
            });
            setMessageId(company.messageID !== undefined?company.messageID:'')
        }
    };

    const handleContactSupplier = (message) => {
        setContactSupplierModalOpen(true)

        
    }

    const handleCloseContactSupplierModalSuccess = ((message) => {
        let data = {
            gtin: filter.cu_gtin_code,
            message: message,
            targetId: filter.ipGLN,
            token: filter.token
        }
        contactSupplier(data).then(result => {
            if(!result.error) {
                if(result.data != undefined) {
                    setContactSupplierResponseMessage(result.data)
                    setContactSupplierResponse(true);
                }
            }
        });
        setContactSupplierModalOpen(false);
    })

    const handleCloseContactSupplierModalFail = (() => {
        setContactSupplierModalOpen(false);
    })

    const handleCloseContactSupplierResponse = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }

        setContactSupplierResponse(false);
    }

    let publishedToTitle = {textAlign: 'center'};
    let publishedToList = {height: '200px', overflowY: 'scroll'};
    let publishedToItem = { backgroundColor: "#F2FCFF", borderRadius: "10px", width:"100%", fontSize: "1rem", marginBottom: "5px"}
    let publishedToPrimary = {fontSize: '12px'}
    let publishedToPrimaryWithdrawn = {fontSize: '12px', color: '#E30505'}
    let publishedToSecondary = {fontSize: '10px'}

    return  <Grid container justifyContent="center" alignItems="center" paddingTop="15px" paddingLeft="25px" paddingRight="25px" paddingBottom="15px" rowSpacing={2} direction="column">
                <Grid item style={{width: '100%', paddingTop: '0'}}>
                    <Box style={publishedToTitle}><Typography variant="blueHierarchy">Published to {sync.length} Data Recipient</Typography></Box>
                    <List style={publishedToList}>
                        {sync.map((company, key) => (
                            <ListItem disablePadding style={publishedToItem} onClick={() => handleChange(company)}>
                                <ListItemButton>
                                    <ListItemText primaryTypographyProps={{ style: company.className === 'red'?publishedToPrimaryWithdrawn:publishedToPrimary }} secondaryTypographyProps={{ style: publishedToSecondary }} primary={company.CompanyName} secondary={company.lastSyncDate}/>
                                    {company.url?
                                        <ListItemIcon>
                                            <LinkIcon />
                                        </ListItemIcon>
                                    : ''
                                    }
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>

                </Grid>
                <Grid item width="100%">
                    <Can I="contactSupplier" ability={userAbility}>
                        <Button variant="orange-contact-supplier" onClick={handleContactSupplier}>Contact Supplier</Button>
                    </Can>
                </Grid>
                <AlertResponse
                    open={contactSupplierResponse}
                    autoHideDuration="3600"
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    onCloseSnackbar={handleCloseContactSupplierResponse}
                    onCloseAlert={handleCloseContactSupplierResponse} 
                    severity="success"
                    variant="tsSuccess"
                    message={contactSupplierResponseMessage}
                ></AlertResponse>
                <ContactSupplierDialog 
                    open={contactSupplierModalOpen} 
                    onCloseSuccess={handleCloseContactSupplierModalSuccess}
                    onCloseFail={handleCloseContactSupplierModalFail}
                />
            </Grid>
}