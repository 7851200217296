export const options = {
    "1" : { //"Supplier Admin"
        "User" : {
            "user_type" : [
                {label: 'Supplier Admin', value: '1'},
                {label: 'Supplier User', value: '5'},
            ]
        }
    },
    "2" : { //"Data Recipient Admin"
        "User" : {
            "user_type" : [
                {label: 'Data Recipient Admin', value: '2'},
                {label: 'Data Recipient User', value: '6'},
            ]
        }
    },
    "3" : { //"GS1 Admin"
        "User" : {
            "user_type" : [
                {label: 'Supplier Admin', value: '1'},
                {label: 'Data Recipient Admin', value: '2'},
                {label: 'GS1 Admin', value: '3'},
                {label: 'Data Recipient [Public]', value: '4'},
                {label: 'Supplier User', value: '5'},
                {label: 'Data Recipient User', value: '6'},
                {label: 'GS1 User', value: '7'}
            ]
        }
    },
    "4" : { //"Data Recipient [Public]"
        "User" : {
            "user_type" : []
        }
    },
    "5" : { //"Supplier User"
        "User" : {
            "user_type" : [
                {label: 'Supplier User', value: '5'},
            ]
        }
    },
    "6" : { //"Data Recipient User"
        "User" : {
            "user_type" : [
                {label: 'Data Recipient User', value: '6'},
            ]
        }
    },
    "7" : { //"GS1 User"
        "User" : {
            "user_type" : [
                {label: 'GS1 User', value: '7'},
            ]
        }
    },
}