import React from "react";
import { Grid, Typography } from '@mui/material';
import ShowUser from "./ShowUser";
import GroupIcon from '@mui/icons-material/Group';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function ShowUsers({removeUser, users, title, filterKey, filterValue, addUser, primary, setUserView}) {
    let data = users.filter(user => user[filterKey] === filterValue);
    
    return  <Grid item container sx={{background: title === 'Company Users'?'#FFFFFF': '', padding: '30px 70px 30px 25px'}}>
                    <Grid item container xs={12} paddingBottom="24px"><Typography variant="companyDetailsTitle" fontWeight={"700"}>{title}</Typography></Grid>
                    {data.length > 0 ?
                        <Grid item container xs={12} rowSpacing={1} alignContent="start" sx={{height: title == 'Company Users'?'275px':'110px', overflowX: 'hidden', overflowY: 'scroll', '&::-webkit-scrollbar': {width: '10px'}, '&::-webkit-scrollbar-track': {background: "#FBFBFB", }, '&::-webkit-scrollbar-thumb': {backgroundColor: '#1EB1E0', }, '&::-webkit-scrollbar-thumb:hover': {background: '#1EB1E0'}}}>
                            {data.map((user, index) => ( 
                                <ShowUser removeUser={removeUser} user={user} primary={primary} setUserView={setUserView} index={Number(index)+1}/>
                            ))}
                        </Grid>
                    :
                        <Grid item container xs={12} sx={{background: title === 'Company Users'?'rgba(189, 189, 189, 0.1)':'', borderRadius: title === 'Company Users'?'10px':'', padding: title === 'Company Users'?'68px 40px':'', marginTop: '10px', marginBottom: '10px'}}>
                            <Grid item container xs={1} alignContent="center" justifyContent="start" ><ErrorOutlineIcon color="orange" fontSize="large"/></Grid>
                            <Grid item container xs={11} alignContent="start" justifyContent="start" direction="column">
                                <Typography variant="companyNoDC" fontWeight="700">Your company</Typography>
                                <Typography variant="companyNoDC">{title === 'Company Users'?' has not added any users yet.':' has not added any Data Controller yet.'}</Typography>
                            </Grid>
                        </Grid>
                    }
                
                {addUser !== undefined ? addUser : ''}
            </Grid>
    
}