import React from "react";
import { Grid } from '@mui/material';
import ProductBox from './ProductBox';

export default function ProductGrid({products}) {
    let boxSize = "200px";   
    let gridStyle = {overflow: 'auto'}
    if(products !== undefined) {        
        return <Grid container direction="row" height={"100%"} style={gridStyle}>
                    {products.map((elem) => { 
                        return <ProductBox size={boxSize}  product={elem}/>
                    })}
                </Grid>  
    } else {
        return '';
    }
}