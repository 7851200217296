let columnOrderByTypes = {
    publishedGTIN : "ts_process_cin_hierarchy.publishedGTIN",
    GTIN : "ts_tm_item.GTIN",
    gtinName : "ts_tm_item.gtinName",
    Variant : "ts_tm_item.Variant",
    languageSpecificBrandName : "ts_tm_item.languageSpecificBrandName",
    InformationProviderGLN : "ts_tm_item.InformationProviderGLN",
    CompanyName : "ts_company_new.CompanyName",
    lastSyncDate: "ts_tm_item.lastSyncDate",
    recipientCompanyNameAlt: "recipientCompanyName",
    publicationDateAlt: "publicationDate",
    GTINAlt: 'GTIN',
    gtinNameAlt: 'gtinName',
    languageSpecificBrandNameAlt: 'languageSpecificBrandName',
    SubCategoryAlt: 'SubCategory',
    gpcCategoryNameAlt: 'gpcCategoryName',
    InformationProviderGLNAlt: 'InformationProviderGLN',
    CompanyNameAlt: 'CompanyName',
    TargetMarketCountryCodeAlt: 'TargetMarketCountryCode',
    RecipientInformationProviderGLNAlt: 'RecipientInformationProviderGLN',
    status_nameAlt: 'status_name',
    lastSyncDateAlt: 'lastSyncDate',

}

export default columnOrderByTypes;