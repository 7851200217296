import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField, Select, MenuItem, FormControl, InputLabel, OutlinedInput, InputAdornment, Tooltip, IconButton } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';

export default function UserPassword({value, setUserPartial, keyName}) {
	const [showPassword, setShowPassword] = useState(false);
    
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const generatePassword = () => {
        const pass = Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2);
        setUserPartial(keyName, pass)
        //console.log(user)
    };
    
	return	<FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                    value={value}
                    onChange={(e) => {setUserPartial(keyName, e.target.value)}}
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    startAdornment={
                        <InputAdornment position="start">
                            <Tooltip title="Generate Password">
                                <IconButton
                                    aria-label="random password"
                                    onClick={generatePassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="start"
                                >
                                    <EnhancedEncryptionIcon />
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                    }
                    endAdornment={
                        <InputAdornment position="end">
                            <Tooltip title="Show/Hide Password">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOffIcon /> : <Visibility />}
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                    }
                    label="Password"
                />
            </FormControl>
}