import React, { useState, useEffect } from "react";
import { Box, Grid } from '@mui/material';
import Header from "../Header/Header";
import EnrichmentWizard from "./EnrichmentWizard"

export default function EnrichmentsUpload() {
    const [type, setType] = useState(undefined)

    let mainDataStyle = {overflow: "auto"}
    let gridStyle = { minWidth: "400px", maxWidth: "1600px", width: "100%", height: "100%", overflow: "hidden"}

    return  <Box name="Enrichments" height="100%">
                <Header name="Enrichments"/>
                <Grid container xs={12} direction="column" height="90%" alignContent={"center"} >
                    <Grid container item style={gridStyle}>
                        <EnrichmentWizard setType={setType} type={type} />
                    </Grid>
                </Grid>
            </Box>;
}