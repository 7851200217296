import React, { useState, useEffect } from "react";
import Drawer from '@mui/material/Drawer';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Box from '@mui/material/Box';
import { Grid, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Paper from '@mui/material/Paper';
import { RejectReasonDialog } from '../Dialog/RejectReasonDialog';
import useToken from '../App/useToken';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import QcDetailDifferencesTable from './QcDetailDifferencesTable.js';
import config from "../../config/config";

export default function QcProductDetails({view, openModal, setOpenModal, modalContentProducts, modalContentAttrLabel, modalContentProductRevisions, modalContentParentProducts, modalContentRejectionMessage, handleActionRow, modalContentRejectionCodes, rejectPublication, getQcRevisionById, getManualQcDataResponse, handleRowClick, loading, setLoading, row}) {
    const [show, setShow] = useState('all');
    const [openRejectReason, setOpenRejectReason] = useState(false);
    const { token } = useToken();
    const [productRevisions, setProductRevisions] = useState({});
    const [showDifferences, setShowDifferences] = useState(false);

    const handleViewChange = (event, nextView) => {
        if (nextView !== null) {
            setShow(nextView);
        }
    };

    const rejectPublicationModal = () => {
        setOpenRejectReason(true)
    };

    const handleReasonModalCloseSuccess = (value, text) => {
        setOpenRejectReason(false);
        setLoading(true);

        let productData = [];
        productData.push(typeof modalContentProducts[0] !== undefined?modalContentProducts[0]:modalContentProducts);
        
        let reasonData = [];
        if(text !== undefined && text !== '') {
            reasonData.push({reason: 'M01', reasontext: text});
        } else {
            let rejectionCodeSelected = modalContentRejectionCodes[0].filter(option => {return option.Code === value});
            reasonData.push({reason: rejectionCodeSelected[0].Code, reasontext: rejectionCodeSelected[0].Value});
        }
        
        let rejectionRequest = {
            data: productData,
            reason: reasonData,
            token: token
        }

        rejectPublication(rejectionRequest).then(response => {
            setOpenRejectReason(false);
            setOpenModal(false);
            getManualQcDataResponse();
            setLoading(false);
        })
        
    };

    const handleReasonModalCloseFail = (value) => {
        setOpenRejectReason(false);
    };

    const handleChangeRevision = (value) => {
        let data = {select_date: value,
            toggle: "false", 
            aID: value, 
            token: token
        }
        getQcRevisionById(data).then(response => {
            console.log(response)
            if(response !== undefined) {
                if(response.code == 1 && response.response.productrevisions !== undefined) {
                    setProductRevisions(response.response.productrevisions)
                }
            }
        })
    };

    let closeModalDetails = {marginLeft: '24px',padding: '16px',opacity: '0.7',cursor: 'pointer'};
    let whithRejectionCode = {backgroundColor: '#F4D5D7', border: '2px solid #F4D5D7', cursor: 'pointer', padding: '5px', borderRadius: '10px', marginBottom: '10px', width: 'calc(100% - 20px)'};
    let noRejectionCode = {backgroundColor: '#ABE9CC', border: '2px solid #64DEA1', cursor: 'pointer', padding: '5px', borderRadius: '10px', marginBottom: '10px', width: 'calc(100% - 20px)'};
    let gtinHierarchy = {color: 'rgba(32, 48, 64, 0.5)',fontSize: '12px', lineHeight: '12px'};
    let rejectionMessage = {color: '#4D4D4D', fontSize: '12px', fontWeight: '400', lineHeight: '16px'};
    let detailsLabel = {color: '#4D4D4D', fontSize: '12px', paddingBottom: '12px', lineHeight: '16px'};
    let hierarchyProductName = {fontSize: '11px', fontFamily: 'Proxima Nova', color: '#000'}; 
    let toggleStyle = {textTransform: "none", padding: '10px', fontSize: '16px', fontWeight: '400', selected:{background: "#1EB1E0"}};
    let boxBorders = {borderLeft: '1px solid #E4E4E4', borderBottom: '1px solid #E4E4E4', borderTop: '1px solid #E4E4E4'};
    let headerStyle = {padding: '37px 48px', background: '#FBFBFB', boxShadow: '6px 6px 10px rgba(0, 0, 0, 0.08)'};
    
    return <Drawer
                anchor={'right'}
                open={openModal}
                PaperProps={{
                    sx: { width: "90%" },
                }}
                >
                {modalContentProducts !== undefined && modalContentProducts.length > 0 && !loading?
                <Grid item container>
                    <Grid item container xs={12} style={headerStyle}>
                        <Grid item xs={0.5} container alignContent="center" justifyContent="start" onClick={() => {setOpenModal(false);setProductRevisions({});setShowDifferences(false)}} sx={{color: '#3284C6', cursor: 'pointer'}}>
                            <ArrowBackIosNewIcon/>
                        </Grid>
                        <Grid item xs={7} container alignContent="center">
                            {Object.entries(modalContentProducts).map(([key, value]) => 
                            <Grid item>
                                <Typography component="p" style={{color: '#000000',fontSize: '16px', marginBottom: '6px', fontWeight: '400'}}>{value.GTIN}</Typography>
                                <Typography component="p" style={{color: '#3078B0', fontSize: '24px', lineHeight: '24px', fontWeight: '400'}}>{value.gtinName}</Typography>
                            </Grid>
                            )}
                        </Grid>
                        <Grid item xs={4.5} container alignContent="center" justifyContent="end">
                            {view === 'auto' ?
                                <Button variant="qcBlue" size="small" onClick={() => {setLoading(true);handleActionRow(modalContentProducts[0])}} >Move to ManualQC</Button>
                            :
                                <Box sx={{ display: 'flex' }}>
                                    <Button variant="qcBlue" size="small" style={{marginLeft: '20px', marginRight: '20px'}} onClick={() => {setLoading(true);handleActionRow(row)}}>Accept Publication</Button>
                                    <Button variant="qcBlue" size="small" onClick={() => rejectPublicationModal()}>Reject Publication</Button>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                    <Grid item container sx={{paddingTop: '49px', paddingLeft: '13px', paddingRight: '13px'}}>
                        <Grid item container xs={12}>
                            <Grid item container xs={4}><Typography component="p" variant="qcProductDetailTitle">Product Hierarchy</Typography></Grid>
                            <Grid item container xs={4}><Typography component="p" variant="qcProductDetailTitleBlue">Reason for rejection</Typography></Grid>
                            <Grid item container xs={4}><Typography component="p" variant="qcProductDetailTitle">Product Details</Typography></Grid>
                        </Grid>
                        <Grid item container xs={12}>
                            <Grid item container xs={4} direction="column" sx={{background: '#FBFBFB', padding: '18px 16px'}}>
                                {Object.entries(modalContentParentProducts).map(([key, value]) =>
                                    Object.entries(value).map(([subkey, subvalue]) => 
                                    <Grid item container 
                                            style={subvalue[0].RejectionCode !== "" && subvalue[0].RejectionCode !== null && subvalue[0].RejectionCode !== undefined?whithRejectionCode:noRejectionCode} 
                                            marginLeft={Number(subvalue[0].key) * Number(10) + 'px'}
                                            onClick={() => {setLoading(true);handleRowClick(subvalue[0]);setProductRevisions({})}}
                                        >
                                        <Grid item xs={12}><Typography component="p" style={gtinHierarchy}>{subvalue[0].GTIN}</Typography></Grid>
                                        <Grid item container xs={12}>
                                            <Grid item container xs={9} alignContent="center">
                                                <Typography component="p" style={hierarchyProductName}>
                                                    {subvalue[0].gtinName} {value[subvalue[0].GTIN] !== undefined && value[subvalue[0].GTIN].length > 0?
                                                        (Number(value[subvalue[0].GTIN][0].totalQuantityOfNextLowerTradeItem)*Number(subvalue[0].totalQuantityOfNextLowerTradeItem))
                                                        :
                                                        subvalue[0].totalQuantityOfNextLowerTradeItem
                                                    }
                                                </Typography>
                                            </Grid>
                                            <Grid item container xs={2} justifyContent="center" alignContent="center">
                                                {subvalue[0].totalQuantityOfNextLowerTradeItem !== undefined && subvalue[0].totalQuantityOfNextLowerTradeItem !== null?
                                                    <Box sx={{background: '#ffffff', borderRadius: '4px', padding: '5px 8px', width: '35%', textAlign: 'center'}}>
                                                        <Typography style={hierarchyProductName}>{'x'+subvalue[0].totalQuantityOfNextLowerTradeItem}</Typography>
                                                    </Box>
                                                    :''
                                                }
                                            </Grid>
                                            <Grid item container xs={1} justifyContent="center" alignContent="center">
                                                {subvalue[0].RejectionCode !== "" && subvalue[0].RejectionCode !== null && subvalue[0].RejectionCode !== undefined?<ErrorOutlineOutlinedIcon color="error"/>:<CheckCircleOutlineOutlinedIcon color="success"/>}
                                            </Grid> 
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid item container xs={4} style={boxBorders} direction="column">
                                {modalContentRejectionMessage.length > 0?
                                    Object.entries(modalContentRejectionMessage).map(([key, value]) => 
                                    <Grid item sx={{padding: '8px 16px'}}>
                                        <Typography component="p" style={rejectionMessage}>{value}</Typography>
                                    </Grid>
                                )
                                :
                                    <Grid item sx={{padding: '18px 16px', minHeight: '25%'}}><Typography component="p" variant="no-data-found">No rejection reason found</Typography></Grid>
                                }

                                <Grid item container><Typography component="p" variant="qcProductDetailTitle">Product image</Typography></Grid>
                                
                                {Object.entries(modalContentProducts).map(([key, value]) => 
                                    <Grid item sx={{padding: '18px 16px'}}>
                                        <Box >{value.imageUrl !== ''?<img src={config.mediaPath +'thumbnail?url=' + encodeURIComponent(value.imageUrl)} width="100px" height="100px"/>:<Typography component="p" variant="no-data-found">No image(s) found</Typography>}</Box>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item container xs={4} style={boxBorders} sx={{borderRight: '1px solid #E4E4E4', padding: '30px'}}> 
                                {Object.entries(modalContentProducts).map(([key, value]) => 
                                    <Grid item paddingBottom="46px">
                                        <Grid item container><Grid item xs={6} style={detailsLabel}>GTIN</Grid> <Grid item xs={6} style={detailsLabel}>{value.GTIN}</Grid></Grid>
                                        <Grid item container><Grid item xs={6} style={detailsLabel}>IP GLN </Grid> <Grid item xs={6} style={detailsLabel}>{value.InformationProviderGLN}</Grid></Grid>
                                        <Grid item container><Grid item xs={6} style={detailsLabel}>Target Market </Grid> <Grid item xs={6} style={detailsLabel}>{value.targetMarket}</Grid></Grid>
                                        <Grid item container><Grid item xs={6} style={detailsLabel}>Target Market Name</Grid> <Grid item xs={6} style={detailsLabel}>{value.targetMarketName}</Grid></Grid>
                                        <Grid item container><Grid item xs={6} style={detailsLabel}>Product Type </Grid> <Grid item xs={6} style={detailsLabel}>{value.productType}</Grid></Grid>
                                        <Grid item container><Grid item xs={6} style={detailsLabel}>GTIN Name </Grid> <Grid item xs={6} style={detailsLabel}>{value.gtinName}</Grid></Grid>
                                        <Grid item container><Grid item xs={6} style={detailsLabel}>Brand Name </Grid> <Grid item xs={6} style={detailsLabel}>{value.brandname}</Grid></Grid>
                                    </Grid>
                                )}
                                <Grid paddingBottom="14px">
                                    <Typography>Choose Current data to data published on:</Typography>
                                </Grid>
                                <FormControl fullWidth>
                                    <InputLabel id="company-type-select-label">Choose Date:</InputLabel>
                                    <Select
                                        style={{width: '95%'}}
                                        id={"company-type-basic"}
                                        labelId="company-type-select-label"
                                        fullWidth
                                        label="Choose Date:"
                                        onChange={(e) => {handleChangeRevision(e.target.value)}}
                                    >
                                        {modalContentProductRevisions.map((ops) => (
                                            ops.map((op) => (
                                                <MenuItem value={op.aID}>{op.creationDateTime}</MenuItem>
                                            ))
                                            
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} padding="28px 50px">
                            <Grid item container xs={6} alignContent="center">
                                {Object.entries(modalContentProducts).map(([key, value]) => 
                                    <Typography component="p" style={{fontWeight: '700'}}>Selected product item data published to retailers {value.InformationProviderName}</Typography>
                                )}
                            </Grid>
                            <Grid item xs={6} container justifyContent="end" alignContent="center">
                                <ToggleButtonGroup
                                    width={"100%"}
                                    value={show}
                                    orientation="horizontal"
                                    exclusive
                                    onChange={handleViewChange}
                                >
                                    <ToggleButton style={toggleStyle} value="all" aria-label="all" onClick={() => setShowDifferences(false)}>
                                        Show All
                                    </ToggleButton>
                                    <ToggleButton style={toggleStyle} value="differences" aria-label="differences" onClick={() => setShowDifferences(true)}>
                                        Show Differences
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} paddingLeft="50px" paddingRight="50px" paddingBottom="50px">
                            <QcDetailDifferencesTable 
                                modalContentAttrLabel={modalContentAttrLabel} 
                                showDifferences={showDifferences}
                                modalContentProducts={modalContentProducts}
                                productRevisions={productRevisions}
                                />   
                        </Grid>
                    </Grid>

                </Grid>
                : <Grid item container xs={12} justifyContent="center" alignContent="center"><CircularProgress /></Grid>}
                {view === 'manual' ?
                <RejectReasonDialog 
                    open={openRejectReason} 
                    onCloseSuccess={handleReasonModalCloseSuccess} 
                    onCloseFail={handleReasonModalCloseFail} 
                    rejectionOptions={modalContentRejectionCodes}
                    modalContentProducts={modalContentProducts}
                />:''}
            </Drawer>;
}