import React, { useEffect, useState } from "react";
import { Grid, Fab, Chip, Typography, List } from '@mui/material';
import ProductHierarchyElementSimple from "../ProductHierarchyElement/ProductHierarchyElementSimple";

export default function ProductHierarchy({data, hierarchyElementClick}) {
    return  <List width={"100%"}>
                {data.map((elem) => (
                    <ProductHierarchyElementSimple item={elem} handleClick={hierarchyElementClick}/>
                ))}
            </List>
}