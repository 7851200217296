import React, { useState, useEffect } from "react";
import useToken from '../App/useToken';
import { Grid, Typography, TextField, Button, Autocomplete, FormControlLabel, Switch} from '@mui/material';
import useReportFilter from '../App/useReportFilter';
import entities from "./Columns";
import Header from "../Header/Header";
import ReportsFilters from "./ReportsFilter.js"
import { DataGridPro } from '@mui/x-data-grid-pro';

export default function Reports({service}) {
    const { filter, setFilterKeyValue, setFilterSearchKeyValue, setQueryType, setOrderBy, setCallToken, getSearchValueFilter, setPageNumber} = useReportFilter();
    const { token } = useToken();
    const [name, setName] = useState("");
    const [columns, setColumns] = useState([]);
    const [path, setPath] = useState("");
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [localPage, setLocalPage] = useState(0);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        entities.map((elem) => {
            if(elem.service === service) {
                setName(elem.name);
                setColumns(elem.columns);
                setPath(elem.path);
                setRows([]);
            }
        })
    }, [service])

    useEffect(() => {
        setCallToken(token);
    }, [])

    // This set the Page field and throws the Product Filter useEffect
    const onPageChange = ((newPage) => {
        setPageNumber(newPage);
        setLocalPage(newPage);
        setLoading(true);
    });
    

    let bodyWrapperStyle = {width:"100%", height:"80%"};
    let tableStyle = {width:"100%", height:"70%"};

    return  <Grid height="100%">
                <Header name={name}/>
                <Grid container direction="column" style={bodyWrapperStyle}>
                    <ReportsFilters 
                        setQueryType={setQueryType}
                        setOrderBy={setOrderBy}
                        fields={columns} 
                        filter={filter} 
                        onChangeFilter={setFilterSearchKeyValue} 
                        getValue={getSearchValueFilter} 
                        setPageNumber={setPageNumber}
                        page={filter.pageNumber}
                        setRows={setRows}
                        setRowCount={setRowCount}
                        path={path}
                        setLocalPage={setLocalPage}
                        setLoading={setLoading}
                    />
                    <Grid container style={tableStyle}>
                        {rows.length > 0 
                            ?
                            <div style={{ height: '100%', width: '100%' }}>
                                <DataGridPro
                                    pagination
                                    rowCount={rowCount}
                                    paginationMode="server"
                                    onPageChange={onPageChange}
                                    rows={rows}
                                    getRowId={(row) => rows.indexOf(row)}
                                    columns={columns}
                                    page={localPage}
                                    pageSize={filter.pageSize}
                                    rowsPerPageOptions={[filter.pageSize]}
                                    loading={loading}
                                />
                            </div>
                            :
                                ''
                        }
                    </Grid>
                </Grid>
            </Grid>;
}
