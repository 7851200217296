import React, { useState, useEffect, useRef } from "react";
import useToken from '../../App/useToken';
import { Grid, Typography, TextField, FormLabel, Button, Autocomplete, FormControlLabel, Switch, Link, Box} from '@mui/material';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ToggleButton from '@mui/material/ToggleButton';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SearchIcon from '@mui/icons-material/Search';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import toolsProductBrowserApi from "../../../tools/toolsProductBrowserApi";
import Multiselect from '../../Multiselect/Multiselect';
import { SimpleDialog } from '../../Dialog/SimpleDialog';
import { PRExportCSVDialog } from '../../Dialog/PRExportCSVDialog';
import { useQuery } from '@tanstack/react-query';
import "./style.css";
import useUser from '../../App/useUser';
import toolsReportsApi from "../../../tools/toolsReportsApi";
import SaveAsIcon from '@mui/icons-material/SaveAs';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import FilterListIcon from '@mui/icons-material/FilterList';
import { PrFiltersDialog } from '../../Dialog/PrFiltersDialog';

export default function Filters({setPublishedTo, setPublicationDateEnd, setPublicationDateStart, setSyncDateEnd, setSyncDateStart,setLocalPage, setProducts, setProdCount, filter, filterCount, prodCount, selectedRows, setView, view, setGtin, setBrand, setCategory, setImages, setName, setProviderGLN, setSubCategory, setCallToken, setPage, page, setLoading, setSku, setOrderBy, setStatus, clearFilters}) {
    const { getPublishedGlns, getAvailableGlns, getDetailsFilter, searchProducts, exportMulitPdf, getCategoriesByGln } = toolsProductBrowserApi();
    const { searchReports, searchReportsCount, prodExportCsv} = toolsReportsApi();
    const { token } = useToken();
    const [fileName, setFileName] = useState("0");

    const [exportFilter, setExportFilter] = useState(
        {
            "token": token,
            "search": {
                "GTIN": "",
                "name": "",
                "publishedTo": "",
                "dateRangeSyncDate": [
                    "",
                    ""
                ],
                "dateRangePublicationDate": [
                    "",
                    ""
                ]
            },
            "count": "true",
            "type": "BROWSER"
        }
    )
    
    const [syncDate, setSyncDate] = React.useState([
        undefined,
        undefined,
    ]);
    const [publishDate, setPublishDate] = React.useState([
        undefined,
        undefined,
    ]);

    const [availablePublishedGlns, setAvailablePublishedGlns] = useState([]);
    const [availableGlns, setAvailableGlns] = useState([]);
    const [availableBrands, setAvailableBrands] = useState([]);
    const [availableCategories, setAvailableCategories] = useState([]);
    const [availableSubCategories, setAvailableSubCategories] = useState([]);
    const [selectableSubCategories, setSelectableSubCategories] = useState([]);
    const [availableStatus, setAvailableStatus] = useState([]);
    const [subcatAutocompleteValues, setSubcatAutocompleteValues] = useState([]);
    const [catAutocompleteValues, setCatAutocompleteValues] = useState([]);
    const [statusAutocompleteValues, setStatusAutocompleteValues] = useState([]);
    const [brandAutocompleteValues, setBrandAutocompleteValues] = useState([]);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [fileNameModalOpen, setFileNameModalOpen] = useState(false);
    const [errorModalTitle, setErrorModalTitle] = useState(undefined);
    const [errorModalComponent, setErrorModalComponent] = useState(undefined);
    const [errorModalActions, setErrorModalActions] = useState(undefined);

    const [exportTotal, setExportTotal] = useState(undefined);
    const { user } = useUser();

    useEffect(() => {
        setCallToken(token);
    }, [])

    // API CALLS
    const { data: availableGlnsResponse} = useQuery(['glns'], () => getAvailableGlns({"token": token}), {
    }); 
     
    const { data: availablePublishedGlnsResponse} = useQuery(['publishedGlns'], () => getPublishedGlns({"token": token}), {
    }); 

    const { data: detailsFilterResponse } = useQuery(['details'], () => getDetailsFilter({"token": token}),{ 
    });

    const orderByOptions = [
        {
            label: 'Last Sync - Ascending',
            value: 'lastSyncDate/asc'
        },
        {
            label: 'Last Sync - Descending',
            value: 'lastSyncDate/undefined'
        }
    ]

    useEffect(() => {
        if(exportTotal !== undefined) {

        }
    }, [])

    // STATE CHANGE SUBSCRIPTIONS
    useEffect(() => {
        if(filter.token == "") {
            setCallToken(token);
        }
    }, [token])
    useEffect(() => {
        if(availablePublishedGlnsResponse !== undefined && !availablePublishedGlnsResponse.error) {
            if(availablePublishedGlnsResponse.data != undefined){
                let availablePubGlnsB = []
                let availablePubGlnsDR = []

                availablePublishedGlnsResponse.data.sort(function(a, b) {
                    if (a.CompanyName !== null && b.CompanyName !== null) {
                        var textA = a.CompanyName.toUpperCase();
                        var textB = b.CompanyName.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    } else {
                        return 0;
                    }                       
                }).map((elem, i) => {
                    if(user.user_type != "2" && user.user_type != "6") {
                        availablePubGlnsB.push({ label: elem.CompanyName + ' - ' + elem.InformationProviderGLN, id: i});
                    } else if(user.InformationProviderGLN === elem.InformationProviderGLN){
                        availablePubGlnsDR.push({ label: elem.CompanyName + ' - ' + elem.InformationProviderGLN, id: i});
                    }
                })
                setAvailablePublishedGlns(availablePubGlnsB);
                if(user.user_type === "2" || user.user_type === "6") {
                    setPublishedTo('', availablePubGlnsDR[0])
                }
            }
        } 
    }, [availablePublishedGlnsResponse])
    useEffect(() => {
        if( detailsFilterResponse != undefined && !detailsFilterResponse.error){
            if(detailsFilterResponse.response != undefined){
                if(detailsFilterResponse.response.brands != undefined && Array.isArray(detailsFilterResponse.response.brands) && availableBrands.length === 0){
                    let availableBrandsB = [];
                    detailsFilterResponse.response.brands.map((elem, i) => {
                        availableBrandsB.push(elem.label)
                    })
                    let availableBrandsF = availableBrandsB.filter((item,index)=>{
                        return availableBrandsB.indexOf(item) === index;
                    })
                    let availableBrandsMulti = []
                    availableBrandsF.map((item,index)=>{
                        availableBrandsMulti.push({label: item, value: item, key: item})
                    })
                    setAvailableBrands(availableBrandsMulti);
                }
                if(detailsFilterResponse.response.main_category != undefined && Array.isArray(detailsFilterResponse.response.main_category) && availableCategories.length === 0) {
                    let availableCat = [];
                    detailsFilterResponse.response.main_category.map((elem, i) => {
                        availableCat.push({label: elem.label, value: elem.value, key: elem.value})
                    })
                    setAvailableCategories(availableCat);
                }
                if(detailsFilterResponse.response.sub_category != undefined && Array.isArray(detailsFilterResponse.response.sub_category) && selectableSubCategories.length === 0) {
                    let availableSubCat = [];
                    detailsFilterResponse.response.sub_category.map((elem, i) => {
                        availableSubCat.push({label: elem.label, value: elem.value, key: elem.value, category: elem.category})       
                    })
                    setAvailableSubCategories(availableSubCat);
                    setSelectableSubCategories(availableSubCat);
                }

                if(detailsFilterResponse.response.status != undefined && Array.isArray(detailsFilterResponse.response.status) && availableStatus.length === 0) {
                    let availableSta = [];
                    detailsFilterResponse.response.status.map((elem, i) => {
                        availableSta.push({label: elem.label, value: elem.value, key: elem.value})       
                    });
                    
                    availableSta.sort(function (a, b) {
                        if (a.label < b.label) {
                            return -1;
                        }
                        if (a.label > b.label) {
                            return 1;
                        }
                        return 0;
                    });

                    setAvailableStatus(availableSta);
                }
            }

            //Set selected autocomplete values
            //Subcategories
            let selectedSubCategories = [];
            selectedSubCategories = availableSubCategories.filter(subcat => filter.searchTerms.sub_category.includes(subcat.value));
            setSubcatAutocompleteValues(selectedSubCategories);
            
            //Categories
            let selectedCategories = [];
            selectedCategories = availableCategories.filter(cat => filter.searchTerms.category.includes(cat.value));
            setCatAutocompleteValues(selectedCategories);

            //Status
            let selectedStatus = [];
            selectedStatus = availableStatus.filter(status => filter.searchTerms.status.includes(status.value));
            setStatusAutocompleteValues(selectedStatus);

            //Brands
            let selectedBrands = [];
            selectedBrands = availableBrands.filter(brand => filter.searchTerms.brand.includes(brand.value));
            setBrandAutocompleteValues(selectedBrands);
        }

    }, [detailsFilterResponse]);

    useEffect(() => {
        if( availableGlnsResponse != undefined && !availableGlnsResponse.error && Array.isArray(availableGlnsResponse.data)){
            let availableGlnsB = []
            availableGlnsResponse.data.sort(function(a, b) {
                if (a.CompanyName !== null && b.CompanyName !== null) {
                    var textA = a.CompanyName.toUpperCase();
                    var textB = b.CompanyName.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                } else {
                    return 0;
                }                       
            }).map((elem, i) => {
                availableGlnsB.push({ label: elem.CompanyName + ' - ' + elem.InformationProviderGLN, id: i});
            })
            setAvailableGlns(availableGlnsB);
        }
    }, [availableGlnsResponse])

    useEffect(() => {
        if(page !== "") {
            searchReports(filter, 'product-report').then(result => {
                setLoading(false);
                if(result.code === 1) {
                    if(result.response != undefined && Array.isArray(result.response)) {
                        setProducts(result.response);
                    }
                }
            });
            if(page === "0"){
                searchReportsCount(filterCount, 'product-report').then(result => {
                    if(result.code === 1) {
                        if(result.response != undefined) {
                            setProdCount(parseInt(result.response, 10));
                        }
                    }
                });
            }
            setPage("");
            
        }
        if(filter.token == "") {
            setCallToken(token);
        }
    }, [page]);


    useEffect(()=> {
        if(errorModalComponent !== undefined && errorModalTitle !== undefined){
            setErrorModalOpen(true);
        }
        
    }, [errorModalComponent, errorModalTitle])

    // HANDLERS   
    const exportCsv = () => {
        setFileNameModalOpen(false);
        setSearchCount(undefined);

        let data = {...exportFilter};
        data["fileName"] = fileName;

        prodExportCsv(data).then(result => {
            if(!result.error) {
                setErrorModalActions(undefined);
                setErrorModalTitle("Export data started");
                setErrorModalComponent(<Typography>Your data will be processed in the background and we will send you an email with the download link when it's ready. Please note that the process can take a long time if the product list is large.</Typography>);
                
            } else {
                setErrorModalTitle("Export Error");
                setErrorModalComponent(<Typography>An error occurred during the export</Typography>);
            }
        });
    };

    useEffect(()=>{
        console.log(fileName)
    }, [fileName]);
    
    const [searchCount, setSearchCount] = useState(undefined);
    
    useEffect(()=> {
        if(searchCount !== undefined) {
            setFileNameModalOpen(true);
        }
    }, [searchCount])

    const searchExportTotal = () => {
        searchProducts(exportFilter).then(result => {
            if(!result.error) {
                setSearchCount(result.data.productCount)
                
            } else {
                setErrorModalTitle("Export Error");
                setErrorModalComponent(<Typography>An error occurred during the export</Typography>);
            }
        });
    }


    const loadSubcategory = ((category) => {
        let selectable = [];
        let selectedCategoriesValues = [];
        
        category.map((cat) => {
            selectedCategoriesValues.push(cat.value)
        })

        if(selectedCategoriesValues !== undefined && selectedCategoriesValues.length > 0) {
            availableSubCategories.map((subcat, index) => {
                if(selectedCategoriesValues.indexOf(subcat.category) > -1) {
                    selectable.push(subcat);
                }
            })
        }

        setSelectableSubCategories(selectable);
    })
    
    const handleSearch =  (() => {
        setLoading(true);
        setPage("0");
        setLocalPage(0);
    })

    const handleCloseErrorModal = (() => {
        setErrorModalOpen(false);
        setErrorModalTitle(undefined);
        setErrorModalComponent(undefined);
    })

    const setExportFilterSearchKeyValue = (key, value) => {
        let copyOfObject = {...exportFilter};
        if(value === null || value === undefined) {
            delete copyOfObject["search"][key];
        } else {
            copyOfObject["search"][key] = value;
        }        
        setExportFilter(copyOfObject);
    };
    
    const getGlnFromRaw = (rawVal) => {
        let value = ''
        
        if(typeof rawVal !== undefined && rawVal !== null) {
            value = (rawVal.label.replace(/ /g, '')).split('-')
        }

        return value.slice(-1);
    }

    function orderByComboBox(props) {
        const currentOption = orderByOptions.find(
            (orderBy) => orderBy.value === filter.searchTerms.OrderBy
        );

        if(currentOption !== undefined) {
            props.inputProps.value = currentOption.label;
        }
        
        return <TextField {...props} label="Order By" />;
    }

    function processIpgln(element) {
        let label = (element.label.replace(/ /g, '')).split('-')

        if(label.slice(-1) === filter.searchTerms.providerGLN) {
            return element
        }
    }

    function ipGlnComboBox(props) {
            const currentOption = availableGlns.find(
                processIpgln
            );

            if(currentOption !== undefined) {
                props.inputProps.value = currentOption.label;
            } else if(user.user_type === "1" && availableGlns.length == 0) {
                props.inputProps.value = user.InformationProviderGLN
            }
        
        
        return <TextField {...props} label="IP Name" />;
    }

    function processIpPubGln(element) {
        let label = (element.label.replace(/ /g, '')).split('-')

        if(label.slice(-1) === filter.searchTerms.published_to) {
            return element
        }
    }

    function ipPubGlnComboBox(props) {
            const currentOption = availablePublishedGlns.find(
                processIpPubGln
            );

            if(currentOption !== undefined) {
                props.inputProps.value = currentOption.label;
            } else if(user.user_type === "2" || user.user_type === "6") {
                props.inputProps.value = user.InformationProviderGLN
            }
        
        
        return <TextField {...props} label="Published to" />;
    }


    const setCategoriesGLN = (e, v) => {
        let value = ''
        if(typeof v !== undefined && v !== null) {
            value = (v.label.replace(/ /g, '')).split('-')
        }
        
        if(Array.isArray(value)) {
            let data = {
                "selectedGLN": value[1],
                "token": token
            }
            getCategoriesByGln(data).then(response => {

                if(response.categories != undefined && Array.isArray(response.categories) && response.categories.length > 0) {
                    let availableCat = [];
                    response.categories.map((elem, i) => {
                        availableCat.push({label: elem.label, value: elem.value, key: elem.value})
                    })
                    setAvailableCategories(availableCat);
                }

                if(response.subcategories != undefined && Array.isArray(response.subcategories) && response.subcategories.length > 0) {
                    let availableSubCat = [];
                    response.subcategories.map((elem, i) => {
                        availableSubCat.push({label: elem.label, value: elem.value, key: elem.value, category: elem.category})       
                    })
                    setAvailableSubCategories(availableSubCat);
                    setSelectableSubCategories(availableSubCat);
                }
            })
        }
        
    }

    const hasSelectedFilters = () => {
        return  filter.searchTerms.name !== undefined && filter.searchTerms.name !== '' ||
                filter.searchTerms.providerGLN !== null ||
                filter.searchTerms.published_to !== null ||
                filter.searchTerms.category.length > 0 ||
                filter.searchTerms.sub_category.length > 0 ||
                filter.searchTerms.brand.length > 0 ||
                filter.searchTerms.status.length > 0 ||
                filter.searchTerms.date_sync_start !== undefined && filter.searchTerms.date_sync_start !== '' ||
                filter.searchTerms.date_sync_end !== undefined && filter.searchTerms.date_sync_end !== '' ||
                filter.searchTerms.date_publication_start !== undefined && filter.searchTerms.date_publication_start !== '' ||
                filter.searchTerms.date_publication_end !== undefined && filter.searchTerms.date_publication_end !== '' && filter.searchTerms.date_publication_end !== null;
    };

    useEffect(() => {
        setCallToken(token);
        hasSelectedFilters();
    }, [])

    useEffect(() => {
        hasSelectedFilters();
    }, [filter])

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const fixedFilters = () => {
        return  <Grid container item  xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={gridCellStyle}>
                        <TextField 
                            label="Product Name"
                            id="name-basic"
                            variant="outlined" 
                            style={inputText}
                            value={filter.searchTerms.name}
                            onChange={(e) => {setName(e.target.value); setExportFilterSearchKeyValue("name", e.target.value);}}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={gridCellStyle}>
                        {availableGlns != undefined && Array.isArray(availableGlns) ?
                            <Autocomplete
                                disablePortal
                                style={inputText}
                                id="combo-box-demo"
                                options={availableGlns}
                                sx={{ width: 300 }}
                                onChange={(e, v) => {setProviderGLN(e, v); setCategoriesGLN(e, v); setExportFilterSearchKeyValue("providerGLN", getGlnFromRaw(v))}}
                                value={filter.searchTerms.providerGLN}
                                renderInput={ipGlnComboBox}
                                defaultValue={user.user_type === "1" && availableGlns.length == 0?user.InformationProviderGLN:''}
                            />
                            :
                            "Loading GLNs"
                        }
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={gridCellStyle}>
                        {availablePublishedGlns != undefined && Array.isArray(availablePublishedGlns) ?
                            <Autocomplete
                                disablePortal
                                style={inputText}
                                id="combo-box-demo"
                                options={availablePublishedGlns}
                                sx={{ width: 300 }}
                                onChange={(e, v) => {setPublishedTo(e, v); setExportFilterSearchKeyValue("publishedTo", getGlnFromRaw(v))}}
                                renderInput={ipPubGlnComboBox}
                                value={filter.searchTerms.published_to}
                                defaultValue={user.user_type === "2" || user.user_type === "6"?user.InformationProviderGLN:''}
                            />
                            :
                            "Loading GLNs"
                        }
                    </Grid>
                </Grid>;
    };

    // STYLES
    let filtersStyle = {width:"100%", padding: "41px 44px 41px 44px", fontFamily: "Proxima Nova", background: '#FFFFFF',boxShadow: '6px 6px 10px rgba(30, 177, 224, 0.1)'};
    let gridCellStyle = {padding:"5px 5px"};
    let inputText = {fontWeight: "100", width:"100%", fontFamily: "Proxima Nova"};    
    let inputStyle = { 
        width: "100%", 
        fontFamily: "Proxima Nova", 
        height: "50.24px",
        "&:root": {
            fontWeight: "100",
            heigth: "1rem",
            fontSize: "1rem",
            lineHeight: "1rem",
        },
    };
    let toggleStyle = {textTransform: "none", selected:{background: "primary"}};

    return  <Grid container direction="row" justifyContent="space-between" alignItems="center" style={filtersStyle} sx={{ height: { xs: "15%", md: "15%", lg: "15%", xl: "15%" }, }}>
                <Grid item xs={1.5}>
                    <Typography component="span">Products </Typography><Typography variant="important">{prodCount}</Typography>
                </Grid>
                <Grid item style={{paddingLeft: '20px'}} xs={2}>
                    <TextField 
                            label="Product Name"
                            id="name-basic"
                            variant="outlined" 
                            style={inputText}
                            value={filter.searchTerms.name}
                            onChange={(e) => {setName(e.target.value); setExportFilterSearchKeyValue("name", e.target.value);}}
                        />
                </Grid>
                <Grid item style={{paddingLeft: '20px'}} xs={2}>
                    {availableGlns != undefined && Array.isArray(availableGlns) ?
                        <Autocomplete
                            disablePortal
                            style={inputText}
                            id="combo-box-demo"
                            options={availableGlns}
                            sx={{ width: 300 }}
                            onChange={(e, v) => {setProviderGLN(e, v); setCategoriesGLN(e, v); setExportFilterSearchKeyValue("providerGLN", getGlnFromRaw(v))}}
                            value={filter.searchTerms.providerGLN}
                            renderInput={ipGlnComboBox}
                            defaultValue={user.user_type === "1" && availableGlns.length == 0?user.InformationProviderGLN:''}
                        />
                        :
                        "Loading GLNs"
                    }
                </Grid>
                <Grid item style={{paddingLeft: '20px'}} xs={2}>
                    {availablePublishedGlns != undefined && Array.isArray(availablePublishedGlns) ?
                        <Autocomplete
                            disablePortal
                            style={inputText}
                            id="combo-box-demo"
                            options={availablePublishedGlns}
                            sx={{ width: 300 }}
                            onChange={(e, v) => {setPublishedTo(e, v); setExportFilterSearchKeyValue("publishedTo", getGlnFromRaw(v))}}
                            renderInput={ipPubGlnComboBox}
                            value={filter.searchTerms.published_to}
                            defaultValue={user.user_type === "2" || user.user_type === "6"?user.InformationProviderGLN:''}
                        />
                        :
                        "Loading GLNs"
                    }
                </Grid>
                <Grid item style={{paddingLeft: '20px', position: 'relative'}} xs={1.5}>
                    {hasSelectedFilters()?<Box style={{position: 'absolute',
                            width: '14px',
                            height: '14px',
                            right: '-8px',
                            top: '-8px',
                            background: '#E16C43',
                            border: '1px solid #E16C43', zIndex: '9', borderRadius: '15px'}}></Box>:''}
                    <Button variant="inverted-main" style={inputText} startIcon={<FilterListIcon />} onClick={handleClickOpen}>All Filters</Button>
                    
                </Grid>
                <Grid item style={{paddingLeft: '20px'}} xs={1.5}>
                    <Button variant="orange-secondary-nosize" style={inputText} startIcon={<SaveAsIcon/>} onClick={() => searchExportTotal()}>Export CSV</Button>
                </Grid>
                <Grid item style={{paddingLeft: '20px'}} xs={1.5} position="relative">
                    <Button variant="orange-nosize" style={inputText} onClick={handleSearch} startIcon={<SearchIcon/>} > Search </Button>
                </Grid>
                <PrFiltersDialog 
                    fixedFilters={fixedFilters()}
                    availableCategories={availableCategories}
                    setCatAutocompleteValues={setCatAutocompleteValues}
                    loadSubcategory={loadSubcategory}
                    catAutocompleteValues={catAutocompleteValues}
                    selectableSubCategories={selectableSubCategories}
                    setSubcatAutocompleteValues={setSubcatAutocompleteValues}
                    subcatAutocompleteValues={subcatAutocompleteValues}
                    availableBrands={availableBrands}
                    setBrandAutocompleteValues={setBrandAutocompleteValues}
                    brandAutocompleteValues={brandAutocompleteValues}
                    availableStatus={availableStatus}
                    setStatusAutocompleteValues={setStatusAutocompleteValues}
                    statusAutocompleteValues={statusAutocompleteValues}
                    open={open}
                    setOpen={setOpen}
                    handleSearch={handleSearch}
                    setCallToken={setCallToken}
                    token={token}
                    setExportFilterSearchKeyValue={setExportFilterSearchKeyValue}
                    setCategory={setCategory}
                    setSubCategory={setSubCategory}
                    setStatus={setStatus}
                    setBrand={setBrand}
                    setSyncDateStart={setSyncDateStart}
                    setSyncDateEnd={setSyncDateEnd}
                    setPublicationDateStart={setPublicationDateStart}
                    setPublicationDateEnd={setPublicationDateEnd}
                    filter={filter}
                    setImages={setImages}
                    setCallToken={setCallToken}
                    clearFilters={clearFilters}
                    syncDate={syncDate}
                    setSyncDate={setSyncDate}
                    publishDate={publishDate}
                    setPublishDate={setPublishDate}
                    setProdCount={setProdCount}
                    setProducts={setProducts}
                    setLocalPage={setLocalPage}
                />
                <SimpleDialog 
                    open={errorModalOpen} 
                    onClose={handleCloseErrorModal} 
                    title={errorModalTitle} 
                    component={errorModalComponent} 
                    actions={errorModalActions}
                />

                <PRExportCSVDialog 
                    open={fileNameModalOpen} 
                    onClose={handleCloseErrorModal}
                    fileName={fileName}
                    setFileName={setFileName}
                    filter={filter}
                    searchCount={searchCount}
                    setErrorModalOpen={setFileNameModalOpen}
                    exportCsv={exportCsv}
                />
            </Grid>
}