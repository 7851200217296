import React, { useState, useEffect } from "react";
import { Box, Grid, Button, Typography, Modal, TextField,Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions  } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import Header from "../../../Header/Header";
import {useParams} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import useToken from "../../../App/useToken";
import useUser from "../../../App/useUser";
import toolsManagementApi from "../../../../tools/toolsManagementApi";
import CircularProgress from '@mui/material/CircularProgress';
import CompanyInfo from "./CompanyInfo";
import CompanyUsers from "./CompanyUsers";
import CompanyInfoEdit from "./CompanyInfoEdit";
import BusinessIcon from '@mui/icons-material/Business';
import HomeIcon from '@mui/icons-material/Home';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Company } from "../../../../entities/Company";
import { Can } from "@casl/react";
import useToolsNotifications from "../../../../tools/ToolsNotifications";
import UserDetail from "../User/UserDetail";
import ComanyUsersQuickEdit from "./CompanyUsersQuickEdit";
import AddIcon from '@mui/icons-material/Add';

const ENTITY_NAME = "Company"

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      width: 400,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        paddingTop: 20,
        paddingBottom: 20,
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

export default function CompanyDetail() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const { token } = useToken();
    const { user } = useUser();
    const {id} = useParams();
    const { getCompanyDetails, getDataControllers, updateCompany, removeDataController, enableCompany, disableCompany} = toolsManagementApi();
    const [companyBU, setCompanyBU] = useState(undefined)
    const [company, setCompany] = useState(undefined);
    const [companyPermission, setCompanyPermission] = useState(new Company(undefined, undefined, undefined, undefined))
    const [dataControllers, setDataControllers] = useState([]);
    const [editMode, setEditMode] = useState(false)
    const [updated, setUpdated] = useState(true);
    const [updateImg, setUpdateImg] = useState(false);
    const userAbility = useSelector((state) => state.userAbility.value);
    const {error,  success} = useToolsNotifications();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogText, setDialogText] = useState(undefined);
    const [dialogAcceptBtnText, setDialogAcceptBtnText] = useState(undefined);
    const [newCompanyStatus , setNewCompanyStatus] = useState(undefined);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalComponent, setModalComponent] = useState(undefined);
    const [userView, setUserView] = useState(undefined);
    

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const showTrusted = (value) => {
        if(value || value === 1) {
            return "Yes";
        } else {
            return "No";
        }
    }

    let subtitleStyle = {
        fontFamily: 'Figtree',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '26px',
        alignItems: 'center',
        color: '#4A4F53',
        width: 'auto'
    }

    const definitionDefault = {
        detail: {label: "Company Detail", description: undefined, icon: <BusinessIcon color="primary" fontSize="large"></BusinessIcon>, data: {
            CompanyGLN : { label: "company GLN" , type: "text",  display: undefined, noEdit: false, onChange: undefined, options: undefined, textTransform: false},	
            CompanyName : { label: "company name" , type: "text",  display: undefined, noEdit: false, onChange: undefined, options: undefined},	
            enrichmentPermissions: {label: "enrichment Permissions", display : showTrusted, type : "switch", noEdit: false, onChange: undefined, options : [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
            ]},
            CompanyType : { label: "company type" , type: "select",  display: undefined, noEdit: false, onChange: undefined, options: [
                { label: 'Supplier/Manufacturer', value: '1' },
                { label: 'Data Recipient', value: '7' },
                { label: 'Data Controller', value: '8' },
                { label: 'Third Party', value: '9' },
            ]},	
            companyTrusted : { label: "trusted company" , type: "select",  display: showTrusted, noEdit: false, onChange: undefined, options: [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
            ]},
            accountNumber: { label: "account number" , type: "text",  display: undefined, noEdit: false, onChange: undefined, options: undefined},	
            BatchUserId: { label: "batch user ID" , type: "text",  display: undefined, noEdit: false, onChange: undefined, options: undefined, textTransform: false},	
            WebServiceGLN: { label: "web service GLN" , type: "text",  display: undefined, noEdit: false, onChange: undefined, options: undefined, textTransform: false},
        }},
    
        address: {label: "Physical Address", description: undefined, icon: <HomeIcon color="primary" fontSize="large"></HomeIcon>, data: {
            AddressLine1: { label: "ADDRESS 1", type: "text",  display: undefined, noEdit: false, onChange: undefined, options: undefined},	
            AddressLine2: { label: "ADDRESS 2", type: "text",  display: undefined, noEdit: false, onChange: undefined, options: undefined},	
            CityName: { label: "CITY NAME", type: "text",  display: undefined, noEdit: false, onChange: undefined, options: undefined},	
            PostalCode: { label: "POSTAL CODE", type: "text",  display: undefined, noEdit: false, onChange: undefined, options: undefined},
        }},
    
        contactData: {label: "Contact Information", 
                        description: "Based on primary user information", 
                        icon: <LocalPhoneIcon color="primary" fontSize="large"></LocalPhoneIcon>, 
        data: {
            ContactName: { label: "CONTACT NAME", type: "text",  display: undefined, noEdit: true, onChange: undefined, options: undefined},	
            ContactTel: { label: "CONTACT TELEPHONE", type: "text",  display: undefined, noEdit: true, onChange: undefined, options: undefined},	
            ContactEmail: { label: "CONTACT EMAIL", type: "text",  display: undefined, noEdit: true, onChange: undefined, options: undefined},
            primaryUser: { label: "Primary Users", type: "select",  display: undefined, hide: true, noEdit: false, onChange: undefined, options: undefined},
        }},
        compliance: {label: "BEE Compliance", description: undefined, icon: <AssuredWorkloadIcon color="primary" fontSize="large"></AssuredWorkloadIcon>, data: {
            IsCompliant: { label: "BEE STATUS COMPLIANT", type: "select",  display: undefined, noEdit: false, onChange: undefined, options: [
                { label: 'Yes', value: 1 },
                { label: 'No', value: 0 },
            ]},	
            CompliantPercentage: { label: "% COMPLIANT", type: "text",  display: undefined, noEdit: false, onChange: undefined, options: undefined},
        }}
    }

    const [definition, setDefinition] = useState(definitionDefault);

    useEffect(() => {
        if(id !== undefined && updated === true) {
            getCompanyDetails({company_id: id, token: token}).then(result => {
                if(result.code === 1) {
                    if(result.response != undefined) {
                        setCompany(result.response)
                        setCompanyBU(result.response)

                        let copyDefinition = {...definition};
                        let processedUserList = [];

                        result.response.userlist.map(user => {
                            processedUserList.push({label: user["first_name"] + ' ' + user["last_name"], value: user["id"]})
                        })
                        copyDefinition["contactData"]["data"]["primaryUser"]["options"] = processedUserList
                        setDefinition(copyDefinition)
                    }
                }
            });
            setUpdated(false);
        }
    }, [id, updated])

    useEffect(() => {
        if(company !== undefined) {
            setCompanyPermission(new Company(company.company_id, company.CompanyGLN, company.status === 1 || company.status === 2, company.enrichmentPermissions))
            getDataControllers({token: token}).then(result => {
                if(!result.error) {
                    if(result.data != undefined) {
                        setDataControllers(processDataControllers(result.data))
                    }
                }
            });
        }
    }, [company])

    const processDataControllers = (datacontrollers) => {
        let ids =   company.dataControllers.map((dc) => {
                        return dc.id
                    })
        let processed = datacontrollers.filter(item => !ids.includes(item.id));

        return processed;
    }

    const setCompanyPartial = (key, value) => {
        let companyCopy = {...company}
        companyCopy[key] = value;

        setCompany(companyCopy)
    }

    const switchEditMode = () => {
        setCompany(companyBU)
        setEditMode(!editMode)
    }

    const addUser = () => {
        let targetUrl = "/user/new/" + company.company_id;
        navigate(targetUrl);
    }

    const removeUserCompany = (user_id) => {

    }

    const removeDataControllerCompany = (user_id) => {
        removeDataController(company.company_id, user_id).then(result => {
            if(result.error === false) {
                setUpdated(true);
            }
        });
    }

    const saveData = () => {
        let companySend = {...company}
        companySend["token"] = token;
        companySend["user_type"] = user.user_type;
        
        updateCompany(companySend).then(result => {
            if(result.code === 1) {
                if(result.response != undefined) {
                    setCompanyBU(company);
                    success("Company updated successfully")
                }
            } else {
                error("Error updating the company")
            }
        });

        setEditMode(!editMode);
    }

    const handleStateChange = (enable) => {
        const mainText = enable ? "Company will be enabled." : "User will be disabled.";
        const btnText = enable ? "Yes, enable the company" : "Yes, disable the company";

        setDialogText(mainText);
        setDialogAcceptBtnText(btnText);
        setNewCompanyStatus(enable);
    }

    const AddUserComponent = ({company, userAbility}) => {
        let component = <Grid item container columnSpacing={1} justifyContent="end" paddingTop="30px">
                            <Can I="addUser" this={company} ability={userAbility}>
                                <Grid item>
                                    <Button variant="orange-nosize" onClick={addUser} startIcon={<AddIcon />}><Typography>Add User</Typography></Button>
                                </Grid>
                            </Can>
                            <Can I="editUsers" this={company} ability={userAbility}>
                                <Grid item>
                                    <Button variant="inverted-main" onClick={quickEdit}><Typography>Quick Edit</Typography></Button>
                                </Grid>
                            </Can>
                        </Grid>
                            
        return component;                
    }

    useEffect(() => {
        if(dialogText !== undefined && dialogAcceptBtnText !== undefined && newCompanyStatus !== undefined) {
            setDialogOpen(true);
        }
    }, [dialogText, dialogAcceptBtnText, newCompanyStatus])

    const handleDialogClose = (option) => {
        if(option) {
            const payload = {"company_id" : company.company_id, "token" : token};
            const targetUrl = "/editcompany/"+company.company_id;
            if(newCompanyStatus) {
                enableCompany(payload).then(result => {
                    if(result.code === 1) {
                        navigate(targetUrl);
                        setUpdated(true);
                        setAnchorEl(undefined);
                    } else {

                    }
                })
            } else {
                disableCompany(payload).then(result => {
                    if(result.code === 1) {
                        navigate(targetUrl);
                        setUpdated(true);
                        setAnchorEl(undefined);
                    } else {

                    }
                })
            }
        } 

        setNewCompanyStatus(undefined);
        setDialogAcceptBtnText(undefined);
        setDialogText(undefined);
        setDialogOpen(false);
    }

    useEffect(() => {
        console.log(userView)
        if(userView !== undefined) {
            setModalComponent(<UserDetail userId={userView} />)
        }
    }, [userView])

    useEffect(() => {
        if(modalComponent !== undefined) {
            setModalOpen(true);
        }
    }, [modalComponent])

    const handleModalClose = () => {
        setModalOpen(false);
        setModalComponent(undefined);
        setUserView(undefined);
        setUpdated(true);
    }

    const quickEdit = () => {
        if(companyBU.userlist !== undefined && companyBU.userlist !== null) {
            setModalComponent(<ComanyUsersQuickEdit userList={company.userlist} />)
        }
    }
    
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '80%', 
        width: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    let mainDataStyle = {overflowY: "scroll"};

    return  <Box name = "Company"  height="100%">
                <Header name="Company" />
                {company !== undefined && userAbility !== undefined ? 
                    <Grid item container>
                        <Grid item container xs={12} sx={{background: '#FBFBFB', padding: '30px 70px  30px 25px'}}>
                            <Grid item container xs={8} alignItems="center">
                                <Grid item>
                                    <Typography variant="companyName">{companyBU["CompanyName"]} {company.status === 3 ? '(Disabled)':''}</Typography>
                                </Grid>
                                <Grid item paddingLeft="40px">
                                    <Grid item xs={12} sx={{color: '#4D4D4D', fontSize: '14px', lineHeight: '18px', fontWeight: '500', fontFamily: 'DM Sans'}}> GLN</Grid>
                                    <Grid item xs={12} sx={{color: '#3284C6', fontSize: '12px', lineHeight: '16px', fontWeight: '400', fontFamily: 'DM Sans'}}>{companyBU["CompanyGLN"]}</Grid>  
                                </Grid>
                            </Grid>
                            <Grid item container xs={4} alignContent="center" justifyContent="end">
                                {editMode ? 
                                        <Grid item paddingRight="20px"><Button variant="main" onClick={saveData}>Save</Button></Grid>
                                    :
                                        ""
                                }
                                <Can I="update" this={companyPermission} ability={userAbility}>
                                    <Grid item paddingRight="20px"> 
                                        <Button variant="main" onClick={switchEditMode}>{editMode ? "Cancel" : "Edit Company"}</Button>
                                    </Grid>
                                </Can>
                                {!editMode ? 
                                    <Can I="changeState" this="Company" ability={userAbility}>
                                        {company.status === 3 ?
                                            <Button variant="ghostBlueCompany" startIcon={<CheckCircleIcon color="ghostBlue"/>} onClick={() => handleStateChange(true)}> Enable Company</Button>
                                            :
                                            <Button variant="ghostBlueCompany" startIcon={<CancelIcon color="ghostBlue"/>} onClick={() => handleStateChange(false)}> Disable Company</Button>
                                        }
                                    </Can> 
                                    :
                                    ""
                                }
                            </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                            <Grid item container xs={5} sx={{background: '#F3F3F3', padding: '30px 30px 30px 25px'}}>
                                <Grid item container sx={{height: '55%',overflowY: 'scroll', '&::-webkit-scrollbar': {width: '10px', height: '30px'}, '&::-webkit-scrollbar-track': {background: "#ffffff", }, '&::-webkit-scrollbar-thumb': {backgroundColor: '#1EB1E0', }, '&::-webkit-scrollbar-thumb:hover': {background: '#1EB1E0'}}}> {definition !== undefined ?
                                    !editMode ?
                                        <CompanyInfo definition={definition} company={company} />
                                    :
                                        <CompanyInfoEdit definition={definition} company={company} setCompanyPartial={setCompanyPartial} companyPermission={companyPermission} userAbility={userAbility}/>
                                :
                                    ""
                                }
                                </Grid>
                            </Grid>
                            <Grid item container xs={7} sx={{background: '#F2FCFF'}}>
                                {definition !== undefined ?
                                    <CompanyUsers addUserComponent={<AddUserComponent company={companyPermission} userAbility={userAbility} />} quickEdit={quickEdit} setUpdated={setUpdated} companyId={company.company_id} users={company.userlist} dataControllers={company.dataControllers} possibleDataControllers={dataControllers} primary={company.primaryUser} addUser={addUser}  removeUserCompany={removeUserCompany} removeDataControllerCompany={removeDataControllerCompany} setUserView={setUserView}/>
                                :
                                    ""
                                }
                            </Grid>
                        </Grid>
                        <Dialog
                            open={dialogOpen}
                            onClose={() => handleDialogClose(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Are you sure?"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {dialogText}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleDialogClose(true)}>{dialogAcceptBtnText}</Button>
                                <Button onClick={() => handleDialogClose(false)}>No</Button>
                            </DialogActions>
                        </Dialog>
                        <Modal
                            open={modalOpen}
                            onClose={handleModalClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={modalStyle}>
                                {modalComponent}
                            </Box>
                        </Modal>
                    </Grid>
                :
                    <CircularProgress />
                }
            </Box>;
}