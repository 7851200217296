import React from "react";

const loadCompanyName = ((row)=>{
    /* let imgStyle = {width: "50%",};
    if(row.product_image_name != undefined) {
        let imgSrc = row.product_image_name;
        if(!imgSrc.startsWith('http')) {
          imgSrc = config.API_URL + '/public/uploaded_files/productdetailimage/' + imgSrc;
        }
        return <Box component="img" style={imgStyle} src={imgSrc}></Box>
    } else {
        return <Box component="img" style={imgStyle} src={ImgProdDefault}></Box>
    } */
    return ''
})


const columns = [
    {hide: true, type: 'text', field: 'id', headerName: 'id', flex: 0.3, minWidth: 50},
    {type: 'text', field: 'CompanyName', headerName: 'Company Name', flex: 0.3, minWidth: 50},// renderCell: (params) => loadCompanyName(params.row)},
    {hide: true, type: 'text', field: 'CompanyGLN', headerName: 'CompanyGLN', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'CompanyType', headerName: 'CompanyType', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'ParentCompany', headerName: 'ParentCompany', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'ContactName', headerName: 'ContactName', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'ContactEmail', headerName: 'ContactEmail', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'ContactTel', headerName: 'ContactTel', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'ContactFax', headerName: 'ContactFax', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'BatchUserId', headerName: 'BatchUserId', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'WebServiceGLN', headerName: 'WebServiceGLN', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'WebServiceKey', headerName: 'WebServiceKey', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'created_by', headerName: 'created_by', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'updated_by', headerName: 'updated_by', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'created_at', headerName: 'created_at', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'updated_at', headerName: 'updated_at', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'IsPhysicalEntity', headerName: 'IsPhysicalEntity', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'CompanyLogo', headerName: 'CompanyLogo', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'companyTrusted', headerName: 'companyTrusted', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'primaryUser', headerName: 'primaryUser', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'accountNumber', headerName: 'accountNumber', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'ID', headerName: 'ID', flex: 0.3, minWidth: 50},
    {type: 'text', field: 'PrefixTypeNUM', headerName: 'PrefixTypeNum', flex: 0.3, minWidth: 50},
    {type: 'text', field: 'searchtype', headerName: 'Digits', flex: 0.2, minWidth: 20},
    {type: 'text', field: 'Name', headerName: 'Prefix', flex: 0.3, minWidth: 50},
    {type: 'text', field: 'PrefixStart', headerName: 'Start', flex: 0.3, minWidth: 50},
    {type: 'text', field: 'PrefixStop', headerName: 'Stop', flex: 0.3, minWidth: 50},
    {type: 'text', field: 'PrefixQty', headerName: 'PrefixQty', flex: 0.3, minWidth: 50},
    {type: 'text', field: 'DateCreated', headerName: 'Creation Date', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'StatusNUM', headerName: 'StatusNUM', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'DateStatusChanged', headerName: 'DateStatusChanged', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'CreatedByUserID', headerName: 'CreatedByUserID', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'StatusChangedByUserID', headerName: 'StatusChangedByUserID', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'DateTransfered', headerName: 'DateTransfered', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'BrandOwnerID', headerName: 'BrandOwnerID', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'BrandOwnerGLN', headerName: 'BrandOwnerGLN', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'StartDate', headerName: 'StartDate', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'EndDate', headerName: 'EndDate', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'last_transferred_to', headerName: 'last_transferred_to', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'last_transferred_at', headerName: 'last_transferred_at', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'remarks', headerName: 'remarks', flex: 0.3, minWidth: 50},
    {hide: true, type: 'text', field: 'last_transferred_by', headerName: 'last_transferred_by', flex: 0.3, minWidth: 50},
    {type: 'text', field: 'statusName', headerName: 'Status', flex: 0.3, minWidth: 50 },
]

export default columns;