import React, { useEffect, useState } from "react";
import { Tabs, Tab, Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export default function Ecommerce({data}) {
    const displayKeywords = ((keywords) => {
        if(keywords !== undefined && keywords !== null)  {
            let splitted = keywords.split(',');
            return splitted.map((key) => (
                <Grid item><Typography variant="keyword">{key}</Typography></Grid>
            ))
        }
        return <Grid item><Typography>No keywords found</Typography></Grid>
    })

    let index = {
        "longProductDescription" : {"label" : "Product Description"}, 
        "consumerUsageInstructions" : {"label" : "Usage Instructions"},
        "consumerStorageInstructions" : {"label" : "Storage Instructions"},
        "safetyWarnings" : {"label" : "Safety Warnings"},
        "tradeitemKeywords" : {"label" : "Search Items", "display" : displayKeywords},
    };

    let benefits = {
        "tradeitemFeatureBenefit1" : "Feature Benefit 1",
        "tradeitemFeatureBenefit2" : "Feature Benefit 2",
        "tradeitemFeatureBenefit3" : "Feature Benefit 3",
        "tradeitemFeatureBenefit4" : "Feature Benefit 4",
        "tradeitemFeatureBenefit5" : "Feature Benefit 5",
        "tradeitemFeatureBenefit6" : "Feature Benefit 6",
    }
    console.log(data)
    return  <Grid container item direction="column" rowSpacing={4}> 
                {Object.keys(index).map((key) => (
                    data[key] !== undefined && data[key] !== "" ?
                        <Grid item container direction="column" rowSpacing={2}>
                            <Grid item><Typography variant="PDTitle">{index[key]["label"]}</Typography></Grid>
                            {index[key]["display"] !== undefined ?
                                <Grid item container direction="row" spacing={4}>{index[key]["display"](data[key])}</Grid>
                            :
                                <Grid item><Typography component="span" variant="PDValue">{data[key]}</Typography></Grid>
                            }
                        </Grid>
                    :
                        ''
                ))}
                {data["tradeitemFeatureBenefit1"] !== undefined && data["tradeitemFeatureBenefit1"] !== "" ?
                        <Grid item container direction="column" rowSpacing={2}>
                            <Grid item><Typography variant="PDTitle">Marketing Data</Typography></Grid>
                                {Object.keys(benefits).map((key) => (
                                    data[key] !== undefined && data[key] !== "" ?
                                        <Grid item container justifyContent="space-between" direction="row" display="block">
                                            <Grid item><Typography component="span" variant="PDLabel">{benefits[key]}</Typography></Grid>
                                            <Grid item><Typography component="span" variant="PDValue">{data[key]}</Typography></Grid>
                                        </Grid>
                                    :
                                        ''
                                ))}                            
                        </Grid>
                    :
                        ''
                }
                
            </Grid>
}