const typeLables =  
{
    "PL": "Pallet",
    "CS" : "Case",
    "SHR": "Shrink",
    "DS" : "Display",
    "EA" : "Each",
    "CA" : "Case",
    "PK" : "Pack",
};

export default typeLables;