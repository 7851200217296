import React, { useState, useEffect } from "react";
import { Grid, Select, MenuItem, TextField, FormControl, InputLabel, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'; 

export function TransferDialog({ open, onCloseSuccess, onCloseFail, suppliers }) {
    const [supplier, setSupplier] = useState(undefined)
    const [startDate, setStartDate] = useState(undefined)
    const [endDate, setEndDate] = useState(undefined)
    
    const handleCloseSuccess = () => {
      onCloseSuccess(supplier, startDate, endDate);
    };

    const handleCloseFail = () => {
      onCloseFail(supplier, startDate, endDate);
  };

    const DateValue = ((string) => {
      if(string === undefined){
          return null;
      } else {
          return dayjs(string, "DD/MM/YYYY")
      }   
    });

    return (
      <Dialog open={open} onClose={handleCloseFail} fullWidth={true} maxWidth={"sm"} >
        <DialogTitle>Transfer Prefix</DialogTitle>
        <DialogContent>
            <Box
                noValidate
                component="form"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    m: 'auto',
                    width: 'fit-content',
                }}
            >
              <Grid container direction="column" rowSpacing={2}>
                <Grid item>
                  <FormControl fullWidth>
                      <InputLabel id="brand-owner-select-label">Select Supplier</InputLabel>
                      <Select
                          id="brand-owner-basic"
                          labelId="brand-owner-select-label"
                          label="Brand Owner"
                          value={supplier}
                          onChange={(e) => {setSupplier(e.target.value)}}
                      >
                          {suppliers.map((supplier) => (
                              <MenuItem value={supplier.id}>{supplier.CompanyName}</MenuItem>
                          ))}
                      </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                          label="Start Date"
                          value={DateValue(startDate)}
                          onChange={(newValue) => {
                              setStartDate(newValue !== null ? newValue.format("DD/MM/YYYY") : "");
                          }}
                          inputFormat="DD/MM/YYYY"
                          renderInput={(params) => <TextField {...params} />}
                      />
                  </LocalizationProvider>
                </Grid>
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                          label="End Date"
                          value={DateValue(endDate)}
                          onChange={(newValue) => {
                              setEndDate(newValue !== null ? newValue.format("DD/MM/YYYY") : "");
                          }}
                          inputFormat="DD/MM/YYYY"
                          renderInput={(params) => <TextField {...params} />}
                      />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="orange" onClick={handleCloseFail}>Cancel</Button>
          <Button variant="orange" onClick={handleCloseSuccess}>Submit</Button>
        </DialogActions>
      </Dialog>
    );
  }