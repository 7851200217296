import React from "react";
import useUser from '../App/useUser';
import { Grid, Box, Typography} from "@mui/material";
import profileImg from "../../img/defaultProfile.jpg";
import ImgProdDefault from "../../img/defaultProduct.png"

export default function ProductBox({product, size}) {
    let panelStyle = {alignItems:"left",color:"black", paddingLeft:"15px"};
    let nameStyle = {fontWeight: "400", fontSize:"18px", lineHeight:"22px" };
    let companyStyle = {fontWeight: "700", fontSize:"14px", lineHeight:"17px" };
    
    return  <Grid container width={size} direction="row">
                <Grid container item xs={12} justifyContent="center">
                    {product.product_image_name == undefined || product.product_image_name !== "" ?
                        <Box component="img" className="pictureBox" src={product.product_image_name}></Box> 
                    :
                        <Box component="img" className="pictureBox" src={ImgProdDefault}></Box> 
                    }
                </Grid>
                <Grid item container xs={12} direction="column" style={panelStyle}>
                    <Typography style={nameStyle} variant="subtitle1" component="subtitle1">
                        {product.GTIN}
                    </Typography>
                    <Typography style={companyStyle} variant="subtitle1" component="subtitle1">
                        {product.gtinName}
                    </Typography>
                    <Typography style={nameStyle} variant="subtitle1" component="subtitle1">
                        {product.Variant}
                    </Typography>
                    <Typography style={companyStyle} variant="subtitle1" component="subtitle1">
                        {product.FunctionalName}, {product.languageSpecificBrandName}
                    </Typography>
                    <Typography style={companyStyle} variant="subtitle1" component="subtitle1">
                        {product.CompanyName} {product.InformationProviderGLN}
                    </Typography>
                </Grid>
            </Grid>;
}