import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import useToken from '../App/useToken';
import toolsWorkListApi from "../../tools/toolsWorkListApi";
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import QcProductTable from "./QcProductTable";
import QcTotalProducts from "./QcTotalProducts";
import QcFilters from "./QcFilters";
import QcProductDetails from "./QcProductDetails";
import QcSelectSuppliersHeader from "./QcSelectSuppliersHeader";
import CircularProgress from '@mui/material/CircularProgress';
import useToolsNotifications from "../../tools/ToolsNotifications";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

export default function ManualQc() {
    function getManualQcDataResponse() {
        setLoading(true)
        getManualQcData({"token": token}).then(result => {
            if( result != undefined && !result.error && typeof result.response === 'object') {
                setManualQcData(result.response);
                //Summarize products by Supplier
                const products = result.response.qcproducts;
                const countedSuppliers = products.reduce((allProducts, product) => {
                  const currCount = allProducts[product.InformationProviderName] ?? 0;
                  return {
                    ...allProducts,
                    [product.InformationProviderName]: currCount + 1,
                  };
                }, {});
                setManualQcFilter(countedSuppliers);
                setSuppliersCount(Object.keys(countedSuppliers).length);
            }
            setLoading(false)
        });
    }

    //Get auto qc data only for the first render
    useEffect(() => {
        getManualQcDataResponse()
    }, []);
    
    const { token } = useToken();
    const [manualQcData, setManualQcData] = useState([]);
    const [manualQcFilter, setManualQcFilter] = useState([]);
    const { getManualQcData, acceptPublication, getManualQcById, getRejectionCodes, rejectPublication, getManualQcRevisionById } = toolsWorkListApi();
    const [selectedRowsCount, setSelectedRowsCount] = useState([0]);
    const [selectedRowsData, setSelectedRowsData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [supplierFilter, setSupplierFilter] = useState([]);
    const [filterModel, setFilterModel] = useState([]);
    const [filterModelCount, setFilterModelCount] = useState(0);
    const [suppliersCount, setSuppliersCount] = useState([0]);
    const [openModal, setOpenModal] = useState(false);
    const [modalContentAttrLabel, setModalContentAttrLabel] = useState([]);
    const [modalContentProducts, setModalContentProducts] = useState([]);
    const [modalContentParentProducts, setModalContentParentProducts] = useState([]);
    const [modalContentRejectionMessage, setModalContentRejectionMessage] = useState([]);
    const [modalContentProductRevisions, setModalContentProductRevisions] = useState([]);
    const [modalContentRejectionCodes, setModalContentRejectionCodes] = useState([]);
    const [modalContentRemainingProducts, setModalContentRemainingProducts] = useState([]);
    const [loadingEntry, setLoadingEntry] = useState('');
    const [entryStatusOk, setEntryStatusOk] = useState([]);
    const [entryStatusFail, setEntryStatusFail] = useState([]);
    const [view] = useState('manual');
    const [loading, setLoading] = useState(false);
    const {error} = useToolsNotifications();
    const [openAlert, setOpenAlert] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    const columns = [
        { field: 'GTIN', headerName: 'GTIN', minWidth: 150, sort: false},
        { field: 'gtinName', headerName: 'Description', minWidth: 380, 
            renderCell: (params) => 
                <Box className={'MuiDataGrid-cellContent'}>
                    <>{loadingEntry === params.id ? <CircularProgress size={'12px'} sx={{marginRight: '5px'}}/> : ''}
                    {entryStatusOk.includes(params.id) ? <CheckCircleIcon htmlColor={'rgb(33, 178, 226)'} fontSize={'16px'} sx={{marginRight: '5px'}}/> : ''}
                    {entryStatusFail.includes(params.id) ? <CancelIcon htmlColor={'red'} fontSize={'16px'} sx={{marginRight: '5px'}}/> : ''}
                    {params.value}
                    </>
                </Box> 
        },
        { field: 'InformationProviderName', headerName: 'Supplier', minWidth: 240},
        { field: 'InformationProviderGLN', hide: true },
        { field: 'publicationDate', headerName: 'Date', minWidth: 150 },
        { field: 'status', headerName: 'GDSN Status', minWidth: 150, renderCell: (params) => loadStatus(params.row)},
        { field: 'accept', headerName: 'Accept', minWidth: 100, renderCell: (params) => loadAcceptButton(params.row)}
    ];

    const handleAccept = async () => {
        // Loop through all the selected entries and try to accept each one
        let ok = entryStatusOk
        let fail = entryStatusFail

        for(var i in selectedRowsData) {
            let entryId = selectedRowsData[i].publishedGTIN + '-' + selectedRowsData[i].GTIN + '-' + selectedRowsData[i].InformationProviderGLN + '-' + selectedRowsData[i].targetMarket
            setLoadingEntry(entryId)

            try {
                let resp = await acceptPublication({
                    "token": token, 
                    "data": [selectedRowsData[i]]
                });
                
                if(resp.code && resp.code == 1) {
                    setSelectedRowsCount(selectedRowsData.length - 1)
                    ok = [...ok, entryId]
                    setEntryStatusOk(ok)
                } else {
                    fail = [...fail, entryId]
                    setEntryStatusFail(fail)
                }
            } catch (e) {
                setEntryStatusFail(fail)
            }
        }
        onRowsSelectionHandler([])
        setLoadingEntry('')
    };

    const loadStatus = ((row)=> {
        if(row.GTIN != undefined) {
            if (row.status === 'REGISTERED') {
                return <Button variant="registered" size="small">{row.status.toLowerCase()}</Button>
            } else if(row.status === 'DISCONTINUED'){
                return <Button variant="discontinued" size="small">{row.status.toLowerCase()}</Button>
            } else if(row.status === 'IN_PROGRESS') {
                return <Button variant="inprogress" size="small">{row.status.toLowerCase()}</Button>
            } else if(row.status === '') {
                return <Button variant="nostatus" size="small">No status</Button>
            }
        }
    });

    const loadAcceptButton = ((row)=> {
      if(row.GTIN != undefined) {
        return <Button variant="qcAccept" sx={{fontSize: '14px', fontWeight: '500'}} size="small"  onClick={() => {handleAcceptRow(row)}}>Accept</Button>
      }
    });

    const onRowsSelectionHandler = (ids) => {
        let selectedRows = 0;
        selectedRows = ids.map((id) => manualQcData.qcproducts.find((row) => row.publishedGTIN + '-' + row.GTIN + '-' + row.InformationProviderGLN + '-' + row.targetMarket === id));
        setSelectedRowsData(selectedRows);
        setSelectedRowsCount(selectedRows.length);
    };

    const handleAcceptRow = ((row) => {
        let ok = entryStatusOk
        let fail = entryStatusFail
        let entryId = row.publishedGTIN + '-' + row.GTIN + '-' + row.InformationProviderGLN + '-' + row.targetMarket
        setLoadingEntry(entryId)

        acceptPublication({
            "token": token, 
            "data": [row]
        }).then(result => {
            setLoading(false)
            // Result ok
            if(!result.error) {
                setOpenModal(false)
                //getManualQcDataResponse()
                ok = [...ok, entryId]
                setEntryStatusOk(ok)
            } else {
                // Error
                fail = [...fail, entryId]
                setEntryStatusFail(fail)
            }

            setLoadingEntry('')
        }).catch((e) => {
            fail = [...fail, entryId]
            setEntryStatusFail(fail)
            error("An unexpected error happened");

            setLoadingEntry('')
            setLoading(false)
        });
    });

    const handleRowClick: GridEventListener<'rowClick'> = (row) => {
        setOpenModal(true);
        
        getManualQcById({
            "token": token,
            "GTIN": row.GTIN,
            "GUID": row.GUID,
            "aID": row.aID,
            "toogle": false
        }).then(result => {
            setLoading(false);
            if(result.status === "success" && typeof result.response !== undefined) {
                setModalContentAttrLabel([result.response.attribute_label])
                if(row.GTIN === row.publishedGTIN) {
                    setModalContentParentProducts([result.response.parentproducts])   
                }
                setModalContentProductRevisions([result.response.productrevisions.sort(function(a,b){return new Date(b.creationDateTime).getTime() - new Date(a.creationDateTime).getTime()})])
                setModalContentProducts([result.response.products])
                setModalContentRejectionCodes([result.response.rejectionCodes])
                setModalContentRejectionMessage(result.response.rejectionmessage)
                setModalContentRemainingProducts([result.response.remainingproducts])
                setSelectedRow(row)
            }
        });
    };

    const handleOnCellClick = (params) => {
        if(typeof params !== undefined && params.field !== 'accept') {
            if(params.field !== '__check__') {
                if(entryStatusOk.includes(params.id)) {
                    setOpenAlert(true)
                } else {
                    setLoading(true)
                    handleRowClick(params.row)
                }
            }
        } else {
            if(params.field !== 'accept') {
                setLoading(true);
            }
        }
    };

    const changeSupplierFilter = (filter) => {
        if(!Array.isArray(filter)) {
            setFilterModel(filter.items)
        } else{
            setFilterModel(filter)
        }
        
    }

    let totalProductsCount = { backgroundColor: '#21B2E2', padding: '5px 10px', width: '20px', color: '#fff', borderRadius: '3px', fontFamily: 'Proxima Nova', fontWeight: 700, fontSize: '14px', marginLeft: '10px'};
    let totalProductText = { fontFamily: 'Proxima Nova', fontSize: '14px', paddingLeft: '52px'}; 

    return  <Grid height="100%">
                <Header name="ManualQC" />
                <Dialog open={openAlert} fullWidth={true} maxWidth={"sm"} >
                    <DialogTitle>Notice</DialogTitle>
                    <DialogContent>
                        <Grid container direction="column">
                            <Grid item>
                                This product has been processed and the details are no longer available to view in QC
                            </Grid>
                        </Grid>
                        
                    </DialogContent>
                    <DialogActions>
                        <Grid item container direction="row" columnSpacing={1} justifyContent="end">
                            <Grid item><Button variant='main' onClick={() => setOpenAlert(false)}>Ok</Button></Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
                <Grid container sx={{height: '90%'}}>
                    <Grid container item xs={12} sx={{background: '#FBFBFB', height: '15%'}}>
                        <QcSelectSuppliersHeader></QcSelectSuppliersHeader>
                        <Grid item container xs={5}  alignContent="center" >
                            <QcTotalProducts
                                view={view}
                                totalProducts={rowCount}
                                />
                            <Grid item style={totalProductText} >Suppliers <Box component="span" style={totalProductsCount}>{suppliersCount}</Box></Grid>
                        </Grid>
                        <Grid item container xs={5} justifyContent="end" alignContent="center" paddingRight="52px">
                                <Button variant="qcAccept" sx={{fontSize: '16px', fontWeight: '400'}} size="small" onClick={()=>{handleAccept()}}>Accept {selectedRowsCount} items</Button>
                            
                        </Grid>
                    </Grid>
                    <Grid item container sx={{height: '85%', overflowY: 'scroll', paddingBottom: '30px'}}>
                        <Grid item xs={2}>
                             <QcFilters
                                qcFilters={manualQcFilter}
                                setSupplierFilter={changeSupplierFilter}
                            />
                            
                        </Grid>
                        {!loading?
                            <QcProductTable
                                view={view}
                                rows={manualQcData.qcproducts !== undefined ? manualQcData.qcproducts:[]}
                                columns={columns}
                                supplierFilter={filterModel}
                                setFilterModel={changeSupplierFilter}
                                onRowsSelectionHandler={onRowsSelectionHandler}
                                setQcData={setManualQcData}
                                setQcFilter={setManualQcFilter}
                                handleOnCellClick={handleOnCellClick}
                                handleRowClick={handleRowClick}
                                handleActionRow={handleAcceptRow}
                                setRowCount={setRowCount}
                                />
                        : <Grid container item justifyContent="center" alignContent="center" sx={{height: '85%'}}><CircularProgress /></Grid>}
                    </Grid>
                    <QcProductDetails
                        view={view}
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                        modalContentProducts={modalContentProducts}
                        modalContentAttrLabel={modalContentAttrLabel}
                        modalContentParentProducts={modalContentParentProducts}
                        modalContentRejectionMessage={modalContentRejectionMessage}
                        modalContentProductRevisions={modalContentProductRevisions}
                        handleActionRow={handleAcceptRow}
                        modalContentRejectionCodes={modalContentRejectionCodes}
                        rejectPublication={rejectPublication}
                        getQcRevisionById={getManualQcRevisionById}
                        getManualQcDataResponse={getManualQcDataResponse}
                        handleRowClick={handleRowClick}
                        loading={loading}
                        setLoading={setLoading}
                        row={selectedRow}
                    />
                </Grid>
                
            </Grid>;
}