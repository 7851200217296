import { useState } from "react";

export default function useCompanyManagementFilter() {
    const [filter, setFilter] = useState(
        {
            pageNumber: undefined,
            pageSize: 25,
            searchTerms: {
                InformationProviderGLN: "",
                email: "",
                name: "",
                status: [],
            },
            token: "",
        }
    );

    const getSearchValueFilter = (key) => {
        return filter["searchTerms"][key];
    }

    const setFilterKeyValue = (key, value) => {
        let copyOfObject = { ...filter };
        copyOfObject[key] = value;
        setFilter(copyOfObject);
    };

    const setFilterSearchKeyValue = (key, value) => {
        let copyOfObject = { ...filter }
        copyOfObject["searchTerms"][key] = value;
        setFilter(copyOfObject);
    };

    const setPageNumber = (value) => {
        setFilterKeyValue("pageNumber", value)
    };

    const setCallToken = (value) => {
        setFilterKeyValue("token", value)
    };

    const setAllValue = (value) => {
        setFilterSearchKeyValue("InformationProviderGLN", value)
        setFilterSearchKeyValue("email", value)
        setFilterSearchKeyValue("name", value)
    }

    const setStatus = (value) => {
        setFilterSearchKeyValue("status", value);
    }

    return {
        filter,
        setCallToken,
        getSearchValueFilter,
        setPageNumber,
        setAllValue,
        setFilterSearchKeyValue,
        setStatus,
    };
}
