import React, { useEffect, useState } from "react";
import { Tabs, Tab, Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export default function Allergens({data}) { 
    return  data !== undefined && data.length > 0 ?
                <Grid container item direction="column" rowSpacing={4}> 
                    <Grid item container direction="row" rowSpacing={2}>
                        <Grid item width={"50%"}><Typography variant="PDTitle">Level Of Containment</Typography></Grid>
                        <Grid item width={"50%"}><Typography variant="PDTitle">Allergen</Typography></Grid>
                    </Grid>
                    {data.map((allergen) => (
                        allergen !== undefined ?
                            <Grid item container direction="row" rowSpacing={2}>
                                <Grid item width={"50%"}><Typography variant="PDLabel">{allergen["LevelOfContainment"]}</Typography></Grid>
                                <Grid item width={"50%"}><Typography variant="PDValue">{allergen["allergenDescription"]}</Typography></Grid>
                            </Grid>
                        :
                            ''
                    ))}
                </Grid>
            :
            ''
}