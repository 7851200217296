import React, { useState, useEffect } from "react";
import { Box, Grid, Stepper, Step, StepLabel } from '@mui/material';
import StepOne from './WizardSteps/StepOne';
import StepTwo from './WizardSteps/StepTwo';
import StepThree from './WizardSteps/StepThree';
import StepFour from './WizardSteps/StepFour';


export default function EnrichmentWizard({setType, type}) { 
    const [activeStep, setActiveStep] = React.useState(0);

    useEffect(() => {
        console.log(activeStep)
    }, [activeStep])

    const handleNext = () => {
        if(activeStep+1 < components.length) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handlePrev = () => {
        if(activeStep-1 >= 0) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleFirst = () => {
        setActiveStep(0);
    };

    const components = [
        {label: "Select Enrichment" , component: <StepOne  setType={setType} type={type} handleNext={handleNext} handlePrev={handlePrev}/>}, 
        {label: "Preview File" , component: <StepTwo  setType={setType} type={type} handleNext={handleNext} handlePrev={handlePrev}/>},
        //{label: "Confirm File" , component: <StepThree  setType={setType} type={type} handleNext={handleNext} handlePrev={handlePrev}/>},
        {label: "File uploaded successfully" , component: <StepFour  setType={setType} type={type} handleFirst={handleFirst}/>},
    ]

    return  <Box sx={{ width: '100%',  height: '100%', overflowY: 'auto', paddingBottom: '30px' }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {components.map((obj, index) => (
                        <Step key={index} hidden={true}>
                            <StepLabel>{obj.label}</StepLabel>
                        </Step>                        
                    ))}
                </Stepper>
                <React.Fragment>
                    {components[activeStep].component}
                </React.Fragment>
            </Box>
}
