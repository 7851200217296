import React, { useEffect, useState } from "react";
import { Grid, Box } from '@mui/material';
import ProductTable from "../../ProductTable/ProductTable";
import Header from "../../Header/Header";
import Filters from "./Filters";
import ProductGrid from "../../ProductGrid/ProductGrid";
import { ConstructionOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { setProductBrowserLocalPage } from '../../../features/productBrowserSlice';
import {useParams} from "react-router-dom";
import { Can } from "@casl/react";
import usePRFilter from "../../App/usePRFilter";

export default function ProductReport() {
    const userAbility = useSelector((state) => state.userAbility.value);
    const {status} = useParams();
    const { filter, filterCount, setPublishedTo ,setPageNumber, setGtin, setBrand, setCategory, setImages, setName, setProviderGLN, setSubCategory, setCallToken, setSku, setOrderBy, setStatus, setPublicationDateEnd, setPublicationDateStart, setSyncDateEnd,setSyncDateStart, clearFilters} = usePRFilter();
    const [view, setView] = useState('list');
    const [autoSearch, setAutoSearch] = useState(false);
    const [products, setProducts] = useState(undefined);
    const [prodCount, setProdCount] = useState(0);
    const [localPage, setLocalPage] = useState(0);

    const [loading, setLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        console.log(products)
        console.log(prodCount)
    }, [products, prodCount])

    // This set the Page field and throws the Product Filter useEffect
    const onPageChange = ((newPage) => {
        setPageNumber(newPage);
        setLocalPage(newPage);
        setLoading(true);
    });

    const handleSearch =  (() => {
        setLoading(true);
        setPageNumber("0");
        setLocalPage(0);
    })

    useEffect(() => {
        console.log(filter)
    }, [filter]);


    useEffect(()=> {
        if(status !== undefined) {
            if(status !== 0 && status !== "0") {
                setStatus([parseInt(status)])
            } else {
                setStatus([])
            }
            setAutoSearch(true)
        }
    }, [])

    useEffect(()=> {
        if(autoSearch) {
            handleSearch()
        }
    }, [autoSearch])

    const checkImages = (image_id) => {
        return image_id !== null && image_id !== undefined && image_id !== "" ? "Yes" : "No";
    }

    const loadProductName = ((row) => {
        return <Box className="MuiDataGrid-cellContent" style={{color: "#3078B0", fontWeight: "700"}}>{row.gtinName}</Box>;
    })
    
    let bodyWrapperStyle = {width:"100%", height: "90%"};
    let tableStyle = {width:"100%", padding: "41px 44px 41px 44px"};

    const columns = [
        {
            field: 'GTIN',
            headerName: 'GTIN',
            minWidth: 130,
            align: 'left',
        },
        {
            field: 'gtinName',
            headerName: 'Product Name',
            minWidth: 250,
            align: 'left',
            renderCell: (params) => loadProductName(params.row)
        },
        {
            field: 'languageSpecificBrandName',
            headerName: 'Brand',
            minWidth: 170,
            align: 'left',
        },
        {
            field: 'SubCategory',
            headerName: 'GPC Code',
            minWidth: 170,
            align: 'left',
        },
        {
            field: 'gpcCategoryName',
            headerName: 'GPC Code Name',
            minWidth: 170,
            align: 'left',
        },
        {
            field: 'InformationProviderGLN',
            headerName: 'IP GLN',
            minWidth: 170,
            align: 'left',
        },
        {
            field: 'CompanyName',
            headerName: 'IP Name',
            minWidth: 170,
            align: 'left',
        },
        {
            field: 'TargetMarketCountryCode',
            headerName: 'Target Market',
            minWidth: 50,
            align: 'left',
            hide: true,
        },
        {
            field: 'RecipientInformationProviderGLN',
            headerName: 'Data Recipient GLN',
            minWidth: 150,
            align: 'left',
        },
        {
            field: 'recipientCompanyName',
            headerName: 'Data Recipient Name',
            minWidth: 120,
            align: 'left'
        },
        {
            field: 'status_name',
            headerName: 'Registration Status',
            minWidth: 150,
            align: 'left',
        },
        {
            field: 'publicationDate',
            headerName: 'Publication Date',
            minWidth: 150,
            align: 'left',
        },
        {
            field: 'lastSyncDate',
            headerName: 'Last Sync',
            minWidth: 120,
            align: 'left',
        },
        { 
            field: 'image_id', 
            headerName: 'With Images', 
            minWidth: 100, 
            align: 'center',
            padding: 'none',
            renderCell: (params) => checkImages(params.row.image_id),
            sortable: false
        }
    ];

    return  <Grid height="100%">
                <Header name="Product Reports"/>
                {userAbility !== undefined ?
                    <Can I="view" this="ProductBrowser" ability={userAbility}>
                        <Grid container direction="column" style={bodyWrapperStyle} >
                            <Filters
                                filter={filter}
                                filterCount={filterCount}
                                prodCount={prodCount}
                                selectedRows={selectedRows}
                                setView={setView} 
                                view={view}
                                setGtin={setGtin}
                                setBrand={setBrand}
                                setCategory={setCategory}
                                setImages={setImages}
                                setName={setName}
                                setProviderGLN={setProviderGLN}
                                setSubCategory={setSubCategory}
                                setCallToken={setCallToken}
                                setPage={setPageNumber}
                                page={filter.pageNumber}
                                setLoading={setLoading}
                                setSku={setSku}
                                setOrderBy={setOrderBy}
                                setStatus={setStatus}
                                setProducts={setProducts}
                                setProdCount={setProdCount}
                                setLocalPage={setLocalPage}
                                setPublicationDateEnd={setPublicationDateEnd}
                                setPublicationDateStart={setPublicationDateStart}
                                setSyncDateEnd={setSyncDateEnd}
                                setSyncDateStart={setSyncDateStart}
                                setPublishedTo={setPublishedTo}
                                clearFilters={clearFilters}
                            />
                            <Grid container style={tableStyle} sx={{ height: { xs: "85%", md: "85%", lg: "85%", xl: "85%" }, }}>
                                {
                                    view === 'list' ?
                                        <ProductTable 
                                            altColumns={columns}
                                            totalRows={prodCount} 
                                            page={localPage} 
                                            rows={products !== undefined ? products : []}
                                            onPageChange={onPageChange}
                                            loading={loading}
                                            setSelectedRows={setSelectedRows}
                                            selectOption={false}
                                            rowId={(r) => r.RecipientInformationProviderGLN + '-' + r.GTIN + '-' + r.InformationProviderGLN + '-' + r.TargetMarketCountryCode}
                                            setOrderBy={setOrderBy}
                                            setLoading={setLoading}
                                            setPage={setPageNumber}
                                        />
                                    :
                                        <ProductGrid products={products} />
                                    
                                }
                            </Grid>
                        </Grid>
                    </Can>
                :
                    ''}
            </Grid>;
}