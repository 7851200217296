import React, {useLayoutEffect, useRef, useState} from "react";
import useUser from '../App/useUser';
import { Grid, Box, Typography, Button } from "@mui/material";
import ProfileImg from "../ProfileImg/ProfileImg";
import "./style.css";
import Drawer from '@mui/material/Drawer';
import userTypes from "../CompanyUserManagement/Detail/userTypes";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useNavigate } from "react-router-dom";
import useFilter from '../App/useFilter';
import useToken from '../App/useToken';
import config from "../../config/config"

export default function ProfileBox(headerBoxHeight) {
    const { user } = useUser();
    const [openModal, setOpenModal] = useState(false);
    const parentRef = useRef(null);
    const [width, setWidth] = useState(0);
    const navigate = useNavigate();
    const { clearFilters } = useFilter();
    const { token } = useToken();

    const userName = () => {
        if(user !== null) {
            if(user.first_name !== undefined && user.last_name !== undefined) {
                return <Typography onClick={()=>setOpenModal(!openModal)}> {user.first_name} {user.last_name}</Typography>
            } else {
                return <Typography onClick={()=>setOpenModal(!openModal)}> {user.first_name} </Typography>
            }
        }
        
    }

    const userInitials = () => {
        if(user !== null) {
            if(user.first_name !== undefined && user.last_name !== undefined) {
                return user.first_name[0] +' '+ user.last_name[0]
            } else {
                return user.first_name[0]
            }
        }
    }

    const labelUserType = (typeId) => {
        return userTypes[typeId];
    }

    useLayoutEffect(() => {
        setWidth(parentRef.current.parentElement.offsetWidth);
    }, []);

    const moveToUser = (() => {
        let targetUrl = "/edituser/" + user.id;
        navigate(targetUrl);
    })

    const moveToLogin = (() => {
        return fetch(config.API_URL + "/api/logout", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },

            body: JSON.stringify({token: token}),
        }).then(
            (data) => navigate("/")
        );
     
    })

    let panelStyle = {alignItems:"left",color:"white", paddingLeft:"15px"};
    let userInitialStyle = {width: '40px', height: '40px', backgroundColor: '#3284C6', color: '#ffffff', borderRadius: '50px'}
    
    return  <Grid container direction="row">
                <Grid container item xs={4} justifyContent="right">
                    {user != null ?
                    <ProfileImg imgUrl={user.user_image} openModal={openModal} setOpenModal={setOpenModal}/>
                    :''}
                </Grid>
                <Grid item container xs={8} direction="column" style={panelStyle}>
                    <Typography variant="profileName">
                        {user != null ?
                            userName()
                        :''}
                    </Typography>
                    <Typography variant="profileCompany" onClick={()=>setOpenModal(!openModal)}>
                        {user != null ?
                            user.companyName
                            :''}
                    </Typography>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item direction="row" xs={9}></Grid>
                    <Grid item direction="row" xs={3} ref={parentRef}>
                        <Drawer
                            anchor={'right'}
                            open={openModal}
                            hideBackdrop={true}
                            className="profileMenu"
                            ModalProps={{
                                BackdropProps: { sx: {width: width}, invisible: true}
                            }}
                            PaperProps={{
                              sx: {
                                width: {width},
                                marginTop: headerBoxHeight.headerBoxHeight,
                                backgroundColor: '#1EB1E0',
                                height: '380px'
                              },
                            }}
                          >
                            <Grid item container>
                                {user != null ?
                                <Grid item container xs={12} p={2} justifyContent="center" style={{borderBottom: '1px solid #ffffff'}}>
                                    <Grid item xs={2}><Box style={userInitialStyle} display="flex" alignItems="center" justifyContent="center"><Typography>{userInitials()}</Typography></Box></Grid>
                                    <Grid item xs={4}><Button variant="editProfile" onClick={moveToUser}>Edit User</Button></Grid>
                                    <Grid item xs={4}><Button variant="editProfile" onClick={() => {clearFilters() ;moveToLogin()}}>Logout ></Button></Grid>
                                </Grid>
                                :''}
                                <Grid item container xs={12} p={2} justifyContent="center">
                                    <Grid item container xs={12}>
                                        <Typography style={{borderBottom: '1.5px solid #ffffff', width: '120px', fontFamily:'Proxima Nova', fontSize: '18px', fontWeight: '700', color: '#ffffff'}}>Account Info  </Typography>
                                    </Grid>
                                    <Grid item container xs={6}>
                                        <Typography variant="profileLabel">gln</Typography>
                                    </Grid>
                                    <Grid item container xs={6}>
                                        <Typography variant="profileValue">{user.InformationProviderGLN}</Typography>
                                    </Grid>
                                    <Grid item container xs={6}>
                                        <Typography variant="profileLabel">company</Typography>
                                    </Grid>
                                    <Grid item container xs={6}>
                                        <Typography variant="profileValue">{user.companyName}</Typography>
                                    </Grid>
                                    <Grid item container xs={6}>
                                        <Typography variant="profileLabel">type</Typography>
                                    </Grid>
                                    <Grid item container xs={6}>
                                        <Typography variant="profileValue">{labelUserType(user.user_type)}</Typography>
                                    </Grid>
                                    <Grid item container xs={6}>
                                        <Typography variant="profileLabel">language</Typography>
                                    </Grid>
                                    <Grid item container xs={6}>
                                        <Typography variant="profileValue">{user.userLanguage}</Typography>
                                    </Grid>
                                    <Grid item container xs={6}>
                                        <Typography variant="profileLabel">data controller</Typography>
                                    </Grid>
                                    <Grid item container xs={6}>
                                        <Typography variant="profileValue">{!user.isDataController?'No':'Yes'}</Typography>
                                    </Grid>
                                    
                                </Grid>
                                <Grid item container xs={12} p={2} justifyContent="center">
                                    <Grid item container xs={12}>
                                        <Typography style={{borderBottom: '1.5px solid #ffffff', width: '120px', fontFamily:'Proxima Nova', fontSize: '18px', fontWeight: '700', color: '#ffffff'}}>Personal Info  </Typography>
                                    </Grid>
                                    <Grid item container xs={6}>
                                        <Typography variant="profileLabel">first name</Typography>
                                    </Grid>
                                    <Grid item container xs={6}>
                                        <Typography variant="profileValue">{user.first_name}</Typography>
                                    </Grid>
                                    <Grid item container xs={6}>
                                        <Typography variant="profileLabel">last name</Typography>
                                    </Grid>
                                    <Grid item container xs={6}>
                                        <Typography variant="profileValue">{user.last_name}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Drawer>
                    </Grid>
                </Grid>
            </Grid>;
}