import { useState } from "react";

export default function useToken() {
    const getToken = () => {
        const tokenString = localStorage.getItem("token");
        
        return tokenString;
    };
    const [token, setToken] = useState(getToken());
    const getTokenValid = () => {
        const tokenValid = localStorage.getItem("tokenValid");
        
        return tokenValid;
    };
    const [tokenValid, setTokenValid] = useState(getTokenValid());
    const saveTokenValid = (userTokenValid) => {
        localStorage.setItem("tokenValid", userTokenValid);
        setTokenValid(userTokenValid);
    };
    const saveToken = (userToken) => {
        localStorage.setItem("token", userToken);
        setToken(userToken);
        setTokenValid("true");
    };
    
    return {
        setToken: saveToken,
        setTokenValid: saveTokenValid,
        token,
        tokenValid,
    };
}
