import React, { useState, useEffect } from "react";
import { Grid, Box, Button, Typography, TextField } from '@mui/material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import AddCommentIcon from '@mui/icons-material/AddComment';
import toolsMessagesBoardApi from "../../tools/toolsMessagesBoardApi";
import useToken from '../App/useToken';
import useToolsNotifications from "../../tools/ToolsNotifications";

export default function MessageNew({refresh}) {
    const {createMsg} = toolsMessagesBoardApi()
    const { token } = useToken()
    const {error,  success} = useToolsNotifications();
    const [targetId, setTargetId] = useState(undefined);
    const [title, setTitle] = useState(undefined);
    const [message, setMessage] = useState(undefined);

    const cleanData = () => {
        setTitle("");
        setMessage("");
        refresh();
    }
 
    const createMessage = () => {
        if(message !== undefined & message !== '' & title !== undefined & title !== ''){
            let data = {
                "token": token,
                "title": title,
                "message": message,
                "messageType": 3,
                "targetType": 12,
                "targetId": -1,
                "active": 1
            }
            createMsg(data).then(result => {
                if(!result.error) {
                    success("Message created succesfully");
                    cleanData();
                } else {
                    error("Error creating the message");
                }
            })
        } else {
            error("Title or message cannot be empty");
        }
    }

    return  <Grid item container width={"100%"} direction={"column"} spacing={1}>
                <Grid item>
                    <Typography variant="productTitle">Publish New Message</Typography>
                </Grid>
                <Grid item>
                    <TextField 
                        label="Title"
                        id="prefix-basic" 
                        variant="outlined" 
                        sx={{fontWeight: "100"}}
                        value={title}
                        onChange={(e) => {setTitle(e.target.value)}}
                    />
                </Grid>
                <Grid item>
                    <CKEditor
                        editor={ Editor }
                        data={message}
                        onChange={ ( event, editor ) => {
                            setMessage(editor.getData());
                        } }
                    />
                </Grid>
                <Grid container item direction={"row"} spacing={1} sx={{marginTop: "5px"}}>
                    <Grid item>
                        <Button startIcon={<AddCommentIcon />} variant="orange" onClick={createMessage}>Create</Button>
                    </Grid>
                </Grid>
            </Grid>
}