import React, { useEffect, useState } from "react";
import useFilter from '../App/useFilter';
import { Grid } from '@mui/material';
import ProductTable from "../ProductTable/ProductTable";
import Header from "../Header/Header";
import ProductBrowserFilters from "./ProductBrowserFilters";
import ProductGrid from "../ProductGrid/ProductGrid";
import { ConstructionOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { setProductBrowserLocalPage } from '../../features/productBrowserSlice';
import {useParams} from "react-router-dom";
import { Can } from "@casl/react";

export default function ProductBrowser() {
    const userAbility = useSelector((state) => state.userAbility.value);
    const {status} = useParams();
    const navigate = useNavigate();
    const { filter, filterCount, setPageNumber, setGtin, setBrand, setCategory, setImages, setName, setProviderGLN, setSubCategory, setCallToken, setSku, setOrderBy, setStatus} = useFilter();
    const [view, setView] = useState('list');
    const [autoSearch, setAutoSearch] = useState(false);

    const products = useSelector((state) => state.productBrowser.productBrowserResults); //from redux store
    const prodCount = useSelector((state) => state.productBrowser.productBrowserCountResults); //from redux store
    const localPage = useSelector((state) => state.productBrowser.productBrowserLocalPage);

    const [loading, setLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    const dispatch = useDispatch();
    // This set the Page field and throws the Product Filter useEffect
    const onPageChange = ((newPage) => {
        setPageNumber(newPage)
        dispatch(setProductBrowserLocalPage(newPage));
        setLoading(true);
    });

    const showProduct = ((params) => {
        let targetUrl = "/product-detail/" + params.row.publishedGTIN + "/" + params.row.GTIN + "/" + params.row.InformationProviderGLN + "/" + params.row.TargetMarketCountryCode;
        navigate(targetUrl);
    })

    const handleSearch =  (() => {
        setLoading(true);
        setPageNumber("0");
        dispatch(setProductBrowserLocalPage(0));
    })

    useEffect(() => {
        console.log(filter)
    }, [filter]);

    useEffect(()=> {
        if(status !== undefined) {
            if(status !== 0 && status !== "0") {
                setStatus([{value: parseInt(status)}])
            } else {
                setStatus([])
            }
            setAutoSearch(true)
        }
    }, [])

    useEffect(()=> {
        if(autoSearch) {
            handleSearch()
        }
    }, [autoSearch])
    
    let bodyWrapperStyle = {width:"100%", height: "90%"};
    let tableStyle = {width:"100%", padding: "41px 44px 41px 44px"};

    return  <Grid height="100%">
                <Header name="Product Browser"/>
                {userAbility !== undefined ?
                    <Can I="view" this="ProductBrowser" ability={userAbility}>
                        <Grid container direction="column" style={bodyWrapperStyle} >
                            <ProductBrowserFilters
                                prodCount={prodCount}
                                selectedRows={selectedRows}
                                setView={setView} 
                                view={view}
                                setGtin={setGtin}
                                setBrand={setBrand}
                                setCategory={setCategory}
                                setImages={setImages}
                                setName={setName}
                                setProviderGLN={setProviderGLN}
                                setSubCategory={setSubCategory}
                                setCallToken={setCallToken}
                                setPage={setPageNumber}
                                page={filter.pageNumber}
                                setLoading={setLoading}
                                setSku={setSku}
                                setOrderBy={setOrderBy}
                                setStatus={setStatus}
                            />
                            <Grid container style={tableStyle} sx={{ height: { xs: "85%", md: "85%", lg: "85%", xl: "85%" }, }}>
                                {
                                    view === 'list' ?
                                        <ProductTable 
                                            handleOnRowClick={showProduct}
                                            totalRows={prodCount} 
                                            page={localPage} 
                                            rows={products}
                                            onPageChange={onPageChange}
                                            loading={loading}
                                            setSelectedRows={setSelectedRows}
                                            rowId={(r) => r.publishedGTIN + '-' + r.GTIN + '-' + r.InformationProviderGLN + '-' + r.TargetMarketCountryCode}
                                            setOrderBy={setOrderBy}
                                            setLoading={setLoading}
                                            setPage={setPageNumber}
                                        />
                                    :
                                        <ProductGrid products={products} />
                                    
                                }
                            </Grid>
                        </Grid>
                    </Can>
                :
                    ''}
            </Grid>;
}