import { useState } from "react";

export default function useManagementFilter() {
    const [filter, setFilter] = useState(
        {
            choose_prefix_filter_type: "0",
            page_number: 0,
            record_limit: 18,
            search_prefix: "",
            token: "",
        }
    );

    const setFilterKeyValue = (key, value) => {
        let copyOfObject = { ...filter };
        copyOfObject[key] = value;
        setFilter(copyOfObject);
    };

    const setPageNumber = (value) => {
        setFilterKeyValue("page_number", value)
    };

    const setCallToken = (value) => {
        setFilterKeyValue("token", value)
    };

    const setRecordLimit = (value) => {
        setFilterKeyValue("record_limit", value)
    };

    const setSearchPrefix = (value) => {
        setFilterKeyValue("search_prefix", value)
    };

    const setPrefixType = (value) => {
        setFilterKeyValue("choose_prefix_filter_type", value)
    };

    return {
        filter,
        setPageNumber,
        setCallToken,
        setRecordLimit,
        setSearchPrefix,
        setPrefixType,
    };
}
