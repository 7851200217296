import React, { useState } from "react";
import { Grid, Typography, Button, Tooltip } from '@mui/material';
import ProfileImg from "../../../../ProfileImg/ProfileImg";
import { useNavigate } from "react-router-dom";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import BackspaceIcon from '@mui/icons-material/Backspace';
import userTypes from "../../userTypes";
import CloseIcon from '@mui/icons-material/Close';
import { Can } from "@casl/react";
import { useSelector } from 'react-redux';
import { User } from "../../../../../entities/User";

export default function ShowUser({removeUser, user, primary, setUserView, index}) {
    const navigate = useNavigate();

    const labelUserType = (typeId) => {
        return userTypes[typeId];
    }

    const primaryTag = () => {
        let tag = ''

        if(primary !== undefined && primary === user.id){
            tag = <Typography variant="userActive" marginLeft="10px" padding="3px 4px">Primary</Typography>
        }

        return tag
    }

    const userState = (active) => {
        let component = <Typography variant="userDisabled">Disabled</Typography>
        
        if(active === 1) {
            component = <Typography variant="userActive">Active</Typography>
        }

        return component
    }

    const moveToUser = (() => {
        if(setUserView !== undefined) {
            setUserView(user.id);
        }else {
            let targetUrl = "/edituser/" + user.id;
            navigate(targetUrl);
        }
        
    })

    const handlerRemove = (() => {
        if(removeUser !== undefined) {
            removeUser(user.id)
        }
    })
    
    let mainStyle ={ 
        '&:hover':{
            '& .actions':{
                visibility:'visible',
                opacity: '1'
            }
          }
    }

    let panelStyle = {color:"black"};
    
    const userAbility = useSelector((state) => state.userAbility.value);
    const [userPermission, setUserPermission] = useState(new User(user.id.toString(), user.InformationProviderGLN, user.is_active === 1, user.enrichmentPermissions));

    return  <Grid item container sx={{marginRight: removeUser == undefined?'30px':'5px', background: (Number(index) % 2 == 0 && removeUser == undefined)?'#FBFBFB':'', '&:hover':{'& .actions':{visibility:'visible', opacity: '1'} }}}> 
                <Grid item container xs={1} alignContent="center">
                    <ProfileImg imgUrl={user.user_image} />
                </Grid>
                <Grid item container xs={3} alignContent="center">
                    <Grid xs={12}>
                        <Typography variant="companyUserName">
                            {user.first_name} {user.last_name}
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography variant="companyUserRol">
                            {labelUserType(user.user_type)} {primaryTag()}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={2} alignContent="center">
                    <Grid xs={12}>
                        <Typography variant="companyUserRol">
                            GLN
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography variant="companyUserName">
                            {user.InformationProviderGLN}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={1} alignContent="center">
                    {userState(user.is_active)}
                </Grid>
                <Grid item container xs={5} alignContent="center" spacing={1} justifyContent={removeUser !== undefined ?'right':'center'} className={"actions"} visibility={"hidden"} sx={{transition: "all .2s", opacity: '0'}}>
                    <Grid item>
                        {user.email !== undefined && user.email !== "" ?
                                <Button
                                    target="_top"
                                    rel="noopener noreferrer"
                                    href={`mailto:` + user.email}
                                    variant="emailButton"
                                    startIcon={<EmailOutlinedIcon style={{color:"#1EB1E0"}  } />}
                                    sx={{'&:hover':{
                                        background: 'rgba(30, 177, 224, 0.2)'
                                    }}}
                                >
                                        Send Email
                                </Button>
                            :
                            ''
                        }
                    </Grid>
                    {removeUser === undefined ?
                        <Can I="update" this={userPermission} ability={userAbility}>
                            <Grid item>
                                    <Button
                                        onClick={moveToUser}
                                        variant="emailButton"
                                        startIcon={<CreateOutlinedIcon style={{color:"#E16C43"}} />}
                                        sx={{'&:hover':{
                                                background: 'rgba(255, 162, 126, 0.1)'
                                            }}}
                                    >
                                            Edit User
                                        
                                    </Button>
                            </Grid>
                        </Can>
                    : ""}
                    {removeUser !== undefined ?
                        <Grid item>
                            <Button
                                onClick={handlerRemove}
                                variant="emailButton"
                                sx={{padding: '6px 8px'}}
                                startIcon={<CloseIcon style={{color:"#B6B6B6"}} />}
                            >
                                
                                    Delete
                                
                            </Button>
                        </Grid>
                    :
                        ""
                    }
                </Grid>
            </Grid>
    
}