import React, { useState, useEffect } from "react";
import useToken from '../App/useToken';
import { Grid, Modal, Fade, Backdrop, Box, Typography } from '@mui/material';
import columns from "./Columns";
import Header from "../Header/Header";
import usePrefixManagementFilter from "../App/usePrefixManagementFilter"
import { DataGridPro } from '@mui/x-data-grid-pro';
import toolsPrefixManagementApi from "../../tools/toolsPrefixManagementApi"
import PrefixManagementFilter from "./PrefixManagementFilter";
import PrefixDetail from "./PrefixDetail";

export default function PrefixManagement() {
    const { getCompanyList, getCompanySupplierList, getPrefixDetails } = toolsPrefixManagementApi();
    const { filter, setCallToken, setPageNumber, setPrefixType, setSearchPrefix } = usePrefixManagementFilter();
    const { token } = useToken();
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [localPage, setLocalPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modalType, setModalType] = useState(undefined);
    const [selectedPrefix, setSelectedPrefix] = useState(undefined);
    const [prefixDetails, setPrefixDetails] = useState(undefined);
    const [companies, setCompanies] = useState([]);
    const [updateList, setUpdateList] = useState(false);
    
    useEffect(() => {
        setCallToken(token);
        getCompanySupplierList({token: token}).then(result => {
            if(result.code == 1) {
                if(result.response != undefined) {
                    setCompanies(result.response);
                }
            }
        });
    }, [])

    /* useEffect(() => {
        console.log(rows);
    }, [rows])

    useEffect(() => {
        console.log(filter);
    }, [filter]) */

    useEffect(() => {
        if(selectedPrefix !== undefined) {
            getPrefixDetails({token: token, prefix_id: selectedPrefix}).then(result => {
                if(result.code == 1) {
                    if(result.response != undefined) {
                        setPrefixDetails(result.response);
                    }
                }
            })
            setSelectedPrefix(undefined);
        }
    }, [selectedPrefix])

    useEffect(() => {
        if(modalType !== undefined) {
            setOpenModal(true);
            if(modalType === 0) {
                setPrefixDetails(undefined)
            }
        }
    }, [modalType])

    const handleClose = (() => {
        setUpdateList(true);
        setOpenModal(false);
        setModalType(undefined);
    });

    // This set the Page field and throws the Product Filter useEffect
    const onPageChange = ((newPage) => {
        setPageNumber(newPage);
        setLocalPage(newPage);
        setLoading(true);
    });


    const showPrefix = ((params) => {
        setPrefixDetails(params.row);
        setModalType(1);
    })
    

    let bodyWrapperStyle = {width:"100%", height:"80%"};
    let tableStyle = {width:"100%", height:"70%", padding: '25px'};
    let modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        minWidth: "800px",
        bgcolor: 'background.paper',
        borderRadius: '20px',
        boxShadow: 24,
        p: 4,
      };

    return  <Grid height="100%">
                <Header name={"Prefix Management"}/>
                <Grid container direction="column" style={bodyWrapperStyle}>
                    <PrefixManagementFilter 
                        filter={filter} 
                        setPageNumber={setPageNumber}
                        page={filter.page_number}
                        setRows={setRows}
                        setRowCount={setRowCount}
                        rowCount={rowCount}
                        setLocalPage={setLocalPage}
                        setLoading={setLoading}
                        setPrefixType={setPrefixType}
                        setSearchPrefix={setSearchPrefix}
                        setOpenModal={setOpenModal}
                        setModalType={setModalType}
                        updateList={updateList}
                        setUpdateList={setUpdateList}
                    />
                    <Grid container style={tableStyle}>
                        {rows.length > 0 
                            ?
                            <div style={{ height: '100%', width: '100%' }}>
                                <DataGridPro
                                    onRowClick={showPrefix}
                                    pagination
                                    rowCount={rowCount}
                                    paginationMode="server"
                                    onPageChange={onPageChange}
                                    rows={rows}
                                    getRowId={(row) => rows.indexOf(row)}
                                    columns={columns}
                                    page={localPage}
                                    pageSize={filter.record_limit}
                                    rowsPerPageOptions={[filter.record_limit]}
                                    loading={loading}
                                    disableSelectionOnClick
                                />
                            </div>
                            :
                                ''
                        }
                    </Grid>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={openModal}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                        timeout: 500,
                        }}
                    >
                        <Fade in={openModal}>
                            <Box sx={modalStyle}>
                                <PrefixDetail details={prefixDetails} companies={companies} modalType={modalType} close={handleClose} />
                            </Box>
                        </Fade>
                    </Modal>
                </Grid>
            </Grid>;
}