import React from "react";
import { useState } from "react";
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText  from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Link as RouterLink } from "react-router-dom";
import { List, Grid, Box } from '@mui/material';
import tslogo from "../../img/tslogoblue.png";
import bottom from "../../img/menu-bottom.png";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import menuOptions from "./menuOptions.js";
import useUser from '../App/useUser';
import userTypes from "../CompanyUserManagement/Detail/userTypes";
import { Can } from "@casl/react";
import { useSelector } from 'react-redux';

export default function Menu() {
    const [open, setOpen] = useState({});
    const userAbility = useSelector((state) => state.userAbility.value);

    const handleClick = (key) => {
        setOpen((prevState => ({...prevState, [key]: !prevState[key]})))
    };

    const [selectedIndex, setSelectedIndex] = useState('Dashboard');

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
    };

    const { user, getUser } = useUser();

    const userHasPermission = (userGroups) => {
        if(user != undefined) {
            return userGroups.includes(userTypes[user.user_type]);
        }
    }

    const getUrlToNavigate = (key) => {
        if(key === 'companyManagement') {
            return "/editcompany/"+user.company_id
        } else if(key === "userManagement") {
            return "/edituser/" + user.id
        } else {
            return ""
        }
    }

    return  userAbility !== undefined ?
            <Grid container direction="column" wrap={"nowrap"} alignItems="center" alignContent="center" style={menuOptions.gridStyle}>
                <Grid container item justifyContent="center" alignItems="center" style={menuOptions.boxLogo} >
                    <Box component="img" style={menuOptions.imgStyle} src={tslogo}></Box>
                </Grid>
                <Grid container direction="column" style={menuOptions.menuWrapperStyle}>
                    <List style={menuOptions.menuStyle}>
                        {Object.entries(menuOptions.menuItems).map(([key, value]) => (
                            typeof value === 'object' && value.submenu && typeof value.submenuLinks === 'object' ?
                                <Can I="view" key={key} this={key} ability={userAbility}>
                                    <List style={menuOptions.menuStyle}>
                                        <ListItem key={key} className="list-item" style={menuOptions.itemStyle}>
                                            <ListItemButton onClick={() => handleClick(key)}>
                                                <ListItemIcon sx={{ color: "rgb(50, 132, 198)" }}> 
                                                    {value.icon}
                                                </ListItemIcon>
                                                <ListItemText style={menuOptions.textStyle} primary={value.label} className={'menuTextStyle'}/>
                                                {open[key] ? <ExpandLess color="disabled"  /> : <ExpandMore color="disabled"/>}
                                            </ListItemButton>
                                        </ListItem>
                                        <Collapse in={open[key]} timeout="auto" unmountOnExit>
                                            {Object.entries(menuOptions.menuItems[key]["submenuLinks"]).map(([reportKey, reportValue]) => (
                                                <Can I="view" key={reportKey} this={reportKey} ability={userAbility}>
                                                    {reportValue.isExternalLink ?
                                                        <ListItem key={reportKey} className={selectedIndex === reportKey?'list-item-active':'list-item'}  component="a" href={reportValue.link} style={menuOptions.itemStyle} target="_blank">
                                                            <ListItemButton className="list-button" sx={{ pl: 8 }} selected={selectedIndex === reportKey} onClick={(event) => handleListItemClick(event, reportKey)}>
                                                                <ListItemText style={menuOptions.textStyle} primary={reportValue.label}/>
                                                            </ListItemButton>
                                                        </ListItem>
                                                    :
                                                        <ListItem key={reportKey} className={selectedIndex === reportKey?'list-item-active':'list-item'}  component={RouterLink} to={reportValue.link} style={menuOptions.itemStyle} >
                                                            <ListItemButton className="list-button" sx={{ pl: 8 }} selected={selectedIndex === reportKey} onClick={(event) => handleListItemClick(event, reportKey)}>
                                                                <ListItemText style={menuOptions.textStyle} primary={reportValue.label}/>
                                                            </ListItemButton>
                                                        </ListItem>
                                                    }
                                                </Can>
                                            ))}
                                        </Collapse>
                                    </List>
                                </Can>
                            :
                                <Can I="view" key={key} this={key} ability={userAbility}>
                                    <ListItem key={key} className={selectedIndex === key?'list-item-active':'list-item'}  component={RouterLink} to={value.link !== ""?value.link:getUrlToNavigate(key)} style={menuOptions.itemStyle}>
                                        <ListItemButton className="list-button" selected={selectedIndex === key} onClick={(event) => handleListItemClick(event, key)}>
                                            <ListItemIcon sx={{ color: "rgb(50, 132, 198)" }}>
                                                {value.icon}
                                            </ListItemIcon>
                                            <ListItemText style={menuOptions.textStyle} primary={value.label} className={'menuTextStyle'}/>
                                        </ListItemButton>
                                    </ListItem>
                                </Can>
                            ))}
                    </List>
                </Grid>
                <Grid container item justifyContent="center" alignItems="center" style={menuOptions.boxBottom} >
                    
                </Grid>
            </Grid>
        :
            ''
}