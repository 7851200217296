import React, { useState, useEffect } from "react";
import { Box, Grid } from '@mui/material';
import TemplatesInfo from './Templates/TemplatesInfo.js'
import TemplateBase from './Templates/TemplateBase'

export default function EnrichmentTemplate({type}) {
    return  <TemplateBase 
                title={TemplatesInfo[type]["title"]} 
                text={TemplatesInfo[type]["text"]} 
                url={TemplatesInfo[type]["url"]}
            />;
}