import React, { useState, useEffect } from "react";
import ToolsApi from './ToolsApi';
import config from "../config/config"

export default function toolsProductDetailApi() {
    const { data, post, get } = ToolsApi();

    const getProductMenu = async () => {
        let url = config.API_URL + "/api/product-menu";
        
        let query = undefined;
        let body = undefined;
        return get(url, query, body);
    };
    const getDataDefinition = async () => {
        let url = config.API_URL + "/api/data-definition";
        
        let query = undefined;
        let body = undefined;
        return get(url, query, body);
    };
    const getEditProductList = async (body) => {
        let url = config.API_URL + "/api/editproductlist";
        
        let query = undefined;
        return post(url, query, body);
    };
    const getProductSyncList = async (body) => {
        let url = config.API_URL + "/api/productsynclist";
        
        let query = undefined;
        return post(url, query, body);
    };

    const exportCsv = async (body) => {
        let url = config.API_URL + "/api/exportexcelfile";
        
        let query = undefined;
        return post(url, query, body);
    }; 

    const exportPdf = async (body) => {
        let url = config.API_URL + "/api/exportpdffile";
        
        let query = undefined;
        return post(url, query, body);
    }; 

    const contactSupplier = async (body) => {
        let url = config.API_URL + "/api/contact-supplier";
        
        let query = undefined;
        return post(url, query, body);
    }; 
    const getPublicProductDetail = async (body) => {
        let url = config.API_URL + "/public-product-detail";
        
        let query = undefined;
        return post(url, query, body);
    };

    return {
        getProductMenu,
        getDataDefinition,
        getEditProductList,
        getProductSyncList,
        exportCsv,
        exportPdf,
        contactSupplier,
        getPublicProductDetail
    };
}