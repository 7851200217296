import React, { useEffect, useState } from "react";
import { Tabs, Tab, Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export default function Sustainability({data}) {
	return  data !== undefined  ?
                <Grid item container rowSpacing={2} paddingBottom="40px" >
                {data.map((packaging) => (
                        packaging !== undefined ?
                            <Grid item container direction="row" rowSpacing={2} >
                                <Grid item width={"50%"}><Typography variant="PDLabel">{packaging["name"].replace(/([A-Z])/g, " $1")}</Typography></Grid>
                                <Grid item width={"50%"}><Typography variant="PDValue">{packaging["value"]}</Typography></Grid>
                            </Grid>
                        :
                            ''
                    ))}
                </Grid>
            :
                ''
}