import React, { useState, useEffect } from "react";
import ToolsApi from './ToolsApi';
import config from "../config/config"

export default function toolsEnrichmentWizardApi() {
    const { get, post, postFile, postImages, deleteCall } = ToolsApi()

    const getTemplateFile = async (query) => {
        let url = config.API_URL + "/api/template"
        let body = undefined

        return get(url, query, body)
    }

    const importEnrichFile =  (query) => {
        let url = config.API_URL + "/api/excelimport"
        let body = undefined

        const formData = new FormData();
        formData.append('file_upload', query.file_upload[0], query.file_upload[0].name)
        formData.append('token', query.token)

        return postFile(url, query, formData)
    }

    const importDigitalAsset =  (body) => {
        let url = config.API_URL + "/api/assetimport"
        let query = undefined


        return postImages(url, query, body)
    }

    const removeAsset = async (item, token) => {
        let url = config.API_URL + "/api/image/" + item.pID + "/delete"
        let query = {
            token: token
        }
        let body = undefined
        return deleteCall(url, query, body);
    } 

    const setNewMainImage = async(query) => {
        console.log(query)
        let url = config.API_URL + "/api/product/" + query.gtin + "/" + query.InformationProviderGLN + "/" + query.TargetMarketCountryCode + "/image/" + query.pID
        let body = undefined

        return post(url, query, body)
    }

    return {
        getTemplateFile,
        importEnrichFile,
        importDigitalAsset,
        removeAsset,
        setNewMainImage
    }
}