import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField, Button, InputLabel, Select, MenuItem, FormControl} from '@mui/material';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import toolsReportsApi from "../../tools/toolsReportsApi";

export default function ReportsFilters({path, fields, filter, onChangeFilter, getValue, setQueryType, setOrderBy, setPageNumber, page, setRows, setRowCount, setLocalPage, setLoading}) {
    const { searchReports, searchReportsCount} = toolsReportsApi();
    const [searchAny, setSearchAny] = useState("");
    const [advancedFilters, setAdvancedFilters] = useState(false);
    const [fieldSort, setFieldSort] = useState(0);
    const [directionSort, setDirectionSort] = useState(0)
    const directionMap = ["desc", "asc"];

    useEffect(() => {
        onChangeOrderBy();
    }, [fieldSort, directionSort])

    useEffect(() => {
        onChangeOrderBy();
        onChangeSearchAny("");
        setAdvancedFilters(false);
    }, [fields])

    useEffect(() => {
        onChangeSearchAny("");
    }, [advancedFilters])

    useEffect(() => {
        if(page !== undefined) {
            searchReports(filter, path).then(result => {
                setLoading(false);
                if(result.code == 1) {
                    if(result.response != undefined && Array.isArray(result.response)) {
                        setRows(result.response)
                    }
                }
            });
            if(page === 0){
                searchReportsCount(filter, path).then(result => {
                    if(result.code == 1) {
                        if(result.response != undefined) {
                            setRowCount(result.response)
                        }
                    }
                });
            }
            setPageNumber(undefined);
        }
    }, [page])

    const onChangeOrderBy = (() => {
        if(fields.length > 0) {
            setOrderBy(fields[fieldSort].field + "/" + directionMap[directionSort]);
        }
    })

    const onChangeSearchAny = ((value) => {
        fields.map((elem) => {
            onChangeFilter(elem.field, value);
        });
        setSearchAny(value);
    });

    const onChangeAdvancedFilters = (() => {
        if(advancedFilters) {
            setQueryType("or");
        } else {
            setQueryType("and");
        }
        setAdvancedFilters(!advancedFilters);
    });

    const SearchReports = (() => {
        setLoading(true);
        setPageNumber(0);
        setLocalPage(0);
    });

    const DateValue = ((string) => {
        if(string === undefined){
            return null;
        } else {
            return dayjs(string, 'DD/MM/YYYY')
        }   
    });

    const RenderField = ((field) => {
        switch(field.type) {
            case 'text':
                return  <TextField 
                            label={field.headerName}
                            id={field.headerName + "-basic"}
                            variant="outlined" 
                            style={inputText}
                            value={getValue(field.field)}
                            onChange={(e) => {onChangeFilter(field.field, e.target.value)}}
                        />;
            case 'date':
                return  <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label={field.headerName}
                                value={DateValue(getValue(field.field))}
                                onChange={(newValue) => {
                                    onChangeFilter(field.field, newValue !== null ? newValue.format("DD/MM/YYYY") : "");
                                }}
                                inputFormat="DD/MM/YYYY"
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>;
            case 'select':
                return  <FormControl fullWidth>
                            <InputLabel id={field.headerName + "-select-label"}>{field.headerName}</InputLabel>
                            <Select
                                labelId={field.headerName + "-select-label"}
                                id={field.headerName + "-select"}
                                value={getValue(field.field)}
                                label={field.headerName}
                                onChange={(e) => {onChangeFilter(field.field, e.target.value)}}
                            >
                                <MenuItem value="">All</MenuItem>
                                {field.options.map((elem)=>(
                                    <MenuItem value={elem.value}>{elem.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>;
        }
    })


    // STYLES
    let filtersStyle = {height:"30%", padding: "25px", fontFamily: "Proxima Nova"};
    let gridCellStyle = {padding:"5px 5px", height: "50%"};
    let inputText = {fontWeight: "100", width:"100%"};    
    let inputStyle = { width: "100%" };

    return  <Grid container direction="row">
                <Grid container direction="row" style={filtersStyle} xs={9} spacing={3}>
                    <Grid item container direction="row" xs={12} spacing={3}>
                        {advancedFilters ?
                            fields.map((elem) => (
                                elem.hide != undefined && elem.hide === true ?
                                    ''
                                :
                                    <Grid item xs={2}>
                                        {RenderField(elem)}
                                    </Grid>
                            ))
                            :
                            <Grid item xs={12}>
                                <TextField 
                                    label="Search by any column"
                                    id={"all-basic"}
                                    variant="outlined" 
                                    style={inputText}
                                    value={searchAny}
                                    onChange={(e) => {onChangeSearchAny(e.target.value)}}
                                />
                            </Grid>
                        }
                    </Grid>
                    <Grid item container direction="row" spacing={3}>
                        <Grid item xs={2}>
                            <Button variant="orange" style={inputText} onClick={SearchReports}>
                                Search
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="orange-secondary" style={inputText} onClick={onChangeAdvancedFilters}>
                                {advancedFilters ? "Hide advanced filters" : "Advanced Filters"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction="row" xs={3} alignItems="flex-start">
                    <Grid container direction="row" xs={12} spacing={3} alignItems="flex-end">
                        <Grid item xs={6}>
                            <InputLabel id="demo-simple-select-label">Order By</InputLabel>
                            <Select
                                fullWidth
                                labelId="field-sort-select-label"
                                id="field-sort-select"
                                value={fieldSort}
                                onChange={(event) => setFieldSort(event.target.value)}
                            >
                                {fields.map((elem, index) => (
                                    <MenuItem value={index}>{elem.headerName}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                fullWidth
                                labelId="direction-sort-select-label"
                                id="direction-sort-select"
                                value={directionSort}
                                onChange={(event) => setDirectionSort(event.target.value)}
                            >
                                <MenuItem value={0}>Desc</MenuItem>
                                <MenuItem value={1}>Asc</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
}