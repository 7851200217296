import React, { useState, useEffect } from "react";
import useToken from '../../App/useToken';
import { Chip,Box, Grid, Button, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import TemplatesInfo from "../Templates/TemplatesInfo.js";
import WestIcon from '@mui/icons-material/West';
import UploadArea from "../../UploadArea/UploadArea.js";
import readXlsxFile from 'read-excel-file'
import toolsEnrichmentWizardApi from "../../../tools/toolsEnrichmentWizardApi";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { AlertResponse } from "../../Alert/AlertResponse";
import { useSelector } from 'react-redux';


export default function StepTwo({setType, type, handleNext, handlePrev}) {
    const userAbility = useSelector((state) => state.userAbility.value);
    const { token } = useToken()
    const [file, setFile] = useState([]);
    const [fileRows, setFileRows] = useState([]);
    const [fileRowsNormalized, setFileRowsNormalized] = useState([]);
    const [gtins, setGtins] = useState([]);
    const [selectedGtin, setSelectedGtin] = useState(undefined);
    const [selectedIndex, setSelectedIndex] = useState(undefined);
    const { importEnrichFile, importDigitalAsset } = toolsEnrichmentWizardApi()
    const [responseMessage, setResponseMessage] = useState('')
    const [responseSeverity, setResponseSeverity] = useState('success')
    const [responseSeverityVariant, setResponseSeverityVariant] = useState('')
    const [open, setOpen] = useState(false);
    const [images] = useState([]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }

        setOpen(false);
    }

    useEffect(() => {
        if(fileRows.length > 0){
            let normalized = TemplatesInfo[type].processRows(fileRows);
            setFileRowsNormalized(normalized);
            setGtins(Object.keys(normalized));
        }
    }, [fileRows])
    
    useEffect(() => {
        if( file !== undefined) {
            if(file.length > 0 && file[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                readXlsxFile(file[0]).then((rows) => {
                    setFileRows(rows);
                })
            } else if(file.length > 0 && file[0].type.includes("image/")) {
                setFileRows(file)
                Array.from(file).map((f, i) => (
                    uploadImage(f, i)
                ))
            }
        }
    }, [file])

    useEffect(() => {
        if(gtins.length > 0) {
            setSelectedIndex(0);
        }
    }, [gtins])

    useEffect(() => {
        setSelectedGtin(gtins[selectedIndex]);
    }, [selectedIndex])

    const showSelector = () => {
        //no mostrar mas de 5
        //agregar el total " de X gtins"
        let from = 0;
        let to = gtins.length > 5 ? 5 : gtins.length + 1 
        let components= gtins.slice(from, to).map((gtin) => (
                            <Button onClick={() => setSelectedGtin(gtin)}>{gtin}</Button>
                        ))
        
        return components;
    }

    const showPreview = () => {
        let data = type === 'digitalAssets'?fileRowsNormalized:fileRowsNormalized[selectedGtin];
        return TemplatesInfo[type].showPreview(data, userAbility, "Product");
    }

    const getBase64 = (file) => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            baseURL = reader.result;
            resolve(baseURL);
          };
        });
    };

    const uploadImage = async (file, index) => {
        const base64 = await getBase64(file);
        
        let data = {
            file: file,
            name: file.name,
            size: file.size,
            type: file.type,
            base64: base64
        }
        images[index] = data
    };

    const handleSave = () => {
        if(type !== 'digitalAssets') {
        //Save data excelimport
        importEnrichFile({
            "token": token,
            "file_upload": file
        }).then(result => {
            if( result.code === 1 && typeof result.status !== undefined) {                
                setResponseMessage(result.status)
                setResponseSeverity("success")
                setResponseSeverityVariant("tsSuccess")
                setOpen(true)
                handleNext()
            } else {
                setResponseMessage(result.response)
                setResponseSeverity("error")
                setResponseSeverityVariant("tsError")
                setOpen(true)
            }
        })
        } else {
            importDigitalAsset({
                "token": token,
                "files": images
            }).then(result => {
                if( result.code === 1 && typeof result.status !== undefined && result.response.message !== "") {
                    setResponseMessage(result.response.message.replace('<p>', '').replace('</p>', ''))
                    setResponseSeverity("error")
                    setResponseSeverityVariant("tsError")
                    setOpen(true)
                } else {
                    handleNext()
                }
            })
        } 
    }

    let gridStyle = { minWidth: "400px", maxWidth: "1600px", width: "80%", height: "100%", overflow: "hidden" }
    let stepGridStyle = {
        marginTop: "32px",
        padding: "50px 50px 15px 50px",
        boxShadow: "6px 6px 10px rgba(30, 177, 224, 0.1)",
    }

    return  <Grid container xs={12} item justifyContent="center" rowSpacing={4} direction="column">
                <Grid container item xs={12} direction="column" rowSpacing={2} style={stepGridStyle}>
                    <Grid item container direction="row" columnSpacing={2} style={{paddingTop: 0}}>
                        <Grid container item xs={6} direction="column" rowSpacing={2}>
                            <Grid item container direction="row" justifyContent="flex-start" alignItems="center">
                                <Grid item>
                                    <Chip label="2" color="primary" size="medium"
                                        sx={{width: "40px",
                                        height: "40px",
                                        borderRadius: "100px",fontFamily: 'Proxima Nova',
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        padding: "10px",
                                        fontSize: "28px",
                                        lineHeight: "34px",
                                        alignItems: "center",
                                        textAlign: "center"}}/>
                                </Grid>
                                <Grid item>
                                    <Grid item><Typography variant={"mainTitleEnrichments"}>Preview File</Typography></Grid>
                                </Grid>                    
                            </Grid>
                            <Grid item container direction="column" rowSpacing={1} style={{paddingLeft:"50px"}}>
                                <Grid item><Typography variant={"titleEnrichments"}>Enrich Data</Typography></Grid>
                                <Grid item><Typography variant={"subtitleEnrichments"}>{type !== 'digitalAssets'?'Upload data from XLS file':''}</Typography></Grid>
                                <Grid item>
                                    <Typography variant={"mainEnrichmentsText"}>
                                        {type !== 'digitalAssets'?
                                            "Select the completed enrichment template you would like to upload. Once selected, check the preview of the enriched section, which appears in the TrustedSource tab, before selecting your next step – to confirm the preview and upload the file or return to the previous step to adjust."
                                            :
                                            "Select the digital assets you would like to upload. Once selected, check the preview of the digital assets, which appears in the Digital Assets section of your product, before selecting your next step – to confirm the preview and upload the file or return to the previous step to adjust."
                                        }
                                    </Typography>
                                </Grid>
                            </Grid> 
                        </Grid>
                        <Grid container item xs={6} direction="column" rowSpacing={4} justifyContent="center" alignContent={"center"}>
                            <Grid item>
                                <UploadArea file={file} setFile={setFile} type={type}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} direction="row" columnSpacing={1} justifyContent="space-between" style={{paddingLeft:"50px"}}>
                        <Grid item><Button variant={"enrichmentsBlue"} onClick={handlePrev} startIcon={<WestIcon />} ><Typography variant={"enrichmentsButton"}>Previous Step</Typography></Button></Grid>
                        <Grid item><Button variant={ file !== undefined && file.length === 0 ? "keywordGrey" : "enrichmentsGreen"} disabled={file !== undefined && file.length === 0 ? true : false}  onClick={handleSave} ><Typography variant={"enrichmentsButton"}>Confirm and Save</Typography></Button></Grid>
                    </Grid> 
                </Grid>
                <Box container item height={"50%"} overflowY={"scroll"} width={"100%"}>
                    {gtins.length > 0 && type !== 'digitalAssets' ?
                        <Grid item container direction="column" style={stepGridStyle} height={"10%"}>
                            <Grid item><Typography variant={"subtitleEnrichments"}>GTIN 1 of {gtins.length} GTINs enriched</Typography></Grid>
                        </Grid>
                    :
                        ""
                    }
                    {selectedGtin !== undefined ? 
                        <Grid item container direction="column" style={stepGridStyle}>
                            {showPreview()}
                        </Grid>
                    :
                        ""
                    } 
                </Box>
                
                <AlertResponse
                    open={open}
                    autoHideDuration="3600"
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    onCloseSnackbar={handleClose}
                    onCloseAlert={handleClose} 
                    severity={responseSeverity}
                    variant={responseSeverityVariant}
                    message={responseMessage}
                ></AlertResponse>            
            </Grid>;
}