import React, { useEffect, useState } from "react";
import { Grid, Typography } from '@mui/material';

export default function DigitalAssets({data}) {

	return	<Grid container spacing={1} alignContent="center">

				{Array.from(data).map(f => (
					<Grid  item  xs={2}>
						<Grid style={{background:'#E4E4E4'}} p={1} width='100%' height='100%'><img src={URL.createObjectURL(f)} width='100%' height='100%' /></Grid>
					</Grid>
			    ))}
			</Grid>;
}