class ReportUtils {
    static processDate(date) {
        let date_string = '-';

        if(date !== '' && date !== null) {
            let new_date = new Date(date);
            let offset = (new Date().getTimezoneOffset()/60) * (-1);
            new_date.setHours(new_date.getHours() + offset);
            date_string = new_date.toLocaleString();
        }

        return (date_string);
    }
    
    static truncateString(str) {
        if(str !== null && str !== undefined){
            let n = 50;
            if (str.length > n) {
                return str.substring(0, n) + "...";
            } 
        }
        return str;
    }

    static processStatusQC(n) {
        let qc_value = '';
        let num = n;
        
        if(typeof n === 'string') {
            num = parseInt(n);
        }

        switch(num){
            case 1: qc_value = 'Created';
            break;
            case 2: qc_value = 'Auto QC Accepted';
            break;
            case 3: qc_value = 'Auto QC Rejected';
            break;
            case 4: qc_value = 'Manual QC Accepted';
            break;
            case 5: qc_value = 'Manual QC Rejected';
            break;
            case 8: qc_value = 'Removed';
            break;
            case 16: qc_value = 'Discontinued';
            break;
        }

        return (qc_value);
    }
}

export default ReportUtils;
