import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField, Select, MenuItem } from '@mui/material';
import UploadProfileImg from "../../../UploadProfileImg/UploadProfileImg";
import defaultImg from "../../../../img/defaultCompany.jpg"
import Switch from '@mui/material/Switch';
import { Can } from "@casl/react";

export default function CompanyInfoEdit({definition, company, setCompanyPartial, companyPermission, userAbility}) {
    const setCompanyImage = (value) => {
        setCompanyPartial("CompanyLogoFile", value)
    }

    const editData = (key, value, type, options = undefined, onChange = undefined) => {
        let input = undefined
        
        switch(type) {
            case "text":
                input =  <TextField 
                            id={key + "-basic"}
                            variant="outlined" 
                            value={value}
                            onChange={(e) => {setCompanyPartial(key, e.target.value)}}
                            style={{width: '90%'}}
                        />
                break;
            case "img":
                input = <UploadProfileImg value={company.value} setValue={setCompanyImage} defaultImage={defaultImg} />
                /* input = <div id="upload-box">
                            <ImageThumb image={company.CompanyLogo} />
                            <input type="file" onChange={(e) => {onChange !== undefined ? onChange(e.target.files[0]) : setCompanyPartial(key, e.target.files[0])}} />
                        </div> */
                break;
            case "select":
                if(options !== undefined) {
                    input = <Select
                                id={key + "-basic"}
                                value={value}
                                onChange={(e) => {onChange !== undefined ? onChange(e.target.value) : setCompanyPartial(key, e.target.value)}}
                                style={{width: '90%'}}
                            >
                                {options.map((op) => (
                                    <MenuItem value={op.value}>{op.label}</MenuItem>
                                ))}
                            </Select>
                }
                break;
            case "switch":
                input = <Switch checked={value} onChange={(event) => setCompanyPartial(key, event.target.checked)}/>
                
                break
        }

        return input
    }

    const renderData = (object, company) => {
        return  Object.keys(object).map((key) => (
                    !object[key]["noEdit"] ?
                        <Grid item container xs={12} direction="row" justifyContent="center" alignItems="center" columnSpacing={5}>
                            <Can I="update" this={companyPermission} field={key} ability={userAbility}>
                                <Grid item container xs={6}><Typography variant="PDNewLabel">{object[key]["textTransform"] === false?object[key]["label"]:object[key]["label"].toLowerCase()}</Typography></Grid>
                                <Grid item xs={6} ><Typography variant="PDNewValue">{editData(key, company[key], object[key]["type"], object[key]["options"], object[key]["onChange"])}</Typography></Grid>
                            </Can>
                        </Grid>
                    :
                        ""
                ))
    };

    let gridStyle = {
        background: "#FFFFFF",
        marginBottom: "10px",
        marginTop: "10px",
        marginRight: '30px',
        padding: "40px 35px",
        boxShadow: "6px 6px 10px rgba(189, 189, 189, 0.1)",
        borderRadius: "10px"
    }

    return  Object.keys(definition).map((def) => (
                <Grid container item justifyContent="flex-start" rowSpacing={1} style={gridStyle}>
                    <Grid item container xs={12} direction="row" justifyContent="center" alignItems="center" columnSpacing={2}>
                        <Grid item xs={6}><Typography variant="companyDetailsTitle" fontWeight={"700"}>{definition[def].label}</Typography></Grid>
                        <Grid item xs={6}></Grid>
                    </Grid>
                    {renderData(definition[def].data, company)}
                </Grid>
            ))
}

