const nutrientCodes = 
{
    'CHOAVL':   'Total Carbohydrate',
    'FIBTG' :   'Dietary Fiber',
    'FAT'   :   'Total Fat',
    'PRO-'  :   'Total Protein',
    'FASAT' :   'Saturated Fat',
    'SUGAR-':   'Total Sugars',
    'FATRN':    'Fatty acids, total trans',
    'CHOCSM':   'Carbohydrate, total; summation',
    'CHOL-':    'Cholesterol; unknown',
    'NA':       'Sodium',
    'NACL':     'Salt',
    'ENER-':    'Energy; unknown',
    'PROTMFP':  'Protein from meat, fish and poultry',
    'ENER-KJ':  'Energy (Kj)',
    'FATNLEA':  'Total fat (by NLEA definition)',
    'FAPUCIS':  'FA, polyunsat.cis',
    'ENERPA':   'Energy % from alc.',
    'FAMSCIS':  'FA, monounsat.cis',
    'SALTEQ':   'Salt Equivalent:',
    'AAAN':     'Amino acids, total aromatic; Per quantity of nitrogen',
    '10FDHF':   '10-formyldihydrofolate',
}



export default nutrientCodes;