import { useState } from "react";

export default function usePRFilter() {
    const [filter, setFilter] = useState({
        count: "false",
        pageNumber: "",
        pageSize: 25,
        searchTerms: {
            GTIN: undefined,
            QueryType: "and",
            brand: [],
            category: [],
            description: undefined,
            images: undefined,
            name: undefined,
            productType: "EA",
            providerGLN: null,
            status: [],
            sub_category: [],
            sku: undefined,
            OrderBy: undefined,
            date_publication_end : undefined,
            date_publication_start : undefined,
            date_sync_end : undefined,
            date_sync_start : undefined,
            published_to: null
        },
        type: "BROWSER",
        token: "",
    })

    const [filterCount, setFilterCount] = useState({
        searchTerms: {
            GTIN: undefined,
            QueryType: "and",
            brand: [],
            category: [],
            description: undefined,
            images: undefined,
            name: undefined,
            productType: "EA",
            providerGLN: null,
            status: [],
            sub_category: [],
            sku: undefined,
            OrderBy: undefined,
            wayLabel: undefined
        },
        type: "BROWSER",
        token: "",
    })


    const setFilterKeyValue = (key, value) => {
        let copyOfObject = JSON.parse(JSON.stringify(filter));
        copyOfObject[key] = value;
        setFilter(copyOfObject);
    };

    const setFilterCountKeyValue = (key, value) => {
        let copyOfObject = JSON.parse(JSON.stringify(filterCount));
        copyOfObject[key] = value;
        setFilterCount(copyOfObject);
    };

    const setFilterSearchKeyValue = (key, value) => {
        let copyOfObject = JSON.parse(JSON.stringify(filter));
        copyOfObject["searchTerms"][key] = value;
        setFilter(copyOfObject);
    };

    const setFilterCountSearchKeyValue = (key, value) => {
        let copyOfObject = JSON.parse(JSON.stringify(filterCount));
        copyOfObject["searchTerms"][key] = value;
        setFilterCount(copyOfObject);
    };

    const setCount = (value) => {
        setFilterKeyValue("count", value)
    };

    const setPageNumber = (value) => {
        setFilterKeyValue("pageNumber", value)
    };

    const setPageSize = (value) => {
        setFilterKeyValue("pageSize", value)
    };

    const setType = (value) => {
        setFilterKeyValue("type", value)
        setFilterCountKeyValue("type", value)
    };

    const setGtin = (value) => {
        setFilterSearchKeyValue("GTIN", value)
        setFilterCountSearchKeyValue("GTIN", value)
        
    };

    const setBrand = (value) => {
        setFilterSearchKeyValue("brand", value)
        setFilterCountSearchKeyValue("brand", value)
    };

    const setCategory = (value) => {
        setFilterSearchKeyValue("category", value)
        setFilterCountSearchKeyValue("category", value)
    };

    const setDescription = (value) => {
        setFilterSearchKeyValue("description", value)
        setFilterCountSearchKeyValue("description", value)
    };

    const setImages = (value) => {
        setFilterSearchKeyValue("images", value)
        setFilterCountSearchKeyValue("images", value)
    };

    const setName = (value) => {
        setFilterSearchKeyValue("name", value)
        setFilterCountSearchKeyValue("name", value)
    };

    const setProductType = (value) => {
        setFilterSearchKeyValue("productType", value)
        setFilterCountSearchKeyValue("productType", value)
    };

    const setProviderGLN = (event, values) => {
        let value = ''
        
        if(typeof values !== undefined && values !== null) {
            value = (values.label.replace(/ /g, '')).split('-')
        }

        setFilterSearchKeyValue("providerGLN", value.slice(-1))
        setFilterCountSearchKeyValue("providerGLN", value.slice(-1))
    };

    const setStatus = (value) => {
        setFilterSearchKeyValue("status", value)
        setFilterCountSearchKeyValue("status", value)
    };

    const setSubCategory = (value) => {
        setFilterSearchKeyValue("sub_category", value)
        setFilterCountSearchKeyValue("sub_category", value)
    };

    const setCallToken = (value) => {
        setFilterKeyValue("token", value)
        setFilterCountKeyValue("token", value)
    };

    const setSku = (value) => {
        setFilterSearchKeyValue("sku", value)
        setFilterCountSearchKeyValue("sku", value)
    };

    const setPublicationDateEnd = (value) => {
        setFilterSearchKeyValue("date_publication_end", value);
        setFilterCountSearchKeyValue("date_publication_end", value)
    };

    const setPublicationDateStart = (value) => {
        setFilterSearchKeyValue("date_publication_start", value);
        setFilterCountSearchKeyValue("date_publication_start", value)
    };

    const setSyncDateEnd = (value) => {
        setFilterSearchKeyValue("date_sync_end", value);
        setFilterCountSearchKeyValue("date_sync_end", value)
    };

    const setSyncDateStart = (value) => {
        setFilterSearchKeyValue("date_sync_start", value);
        setFilterCountSearchKeyValue("date_sync_start", value)
    };

    const setPublishedTo = (event, values) => {
        let value = ''
        
        if(typeof values !== undefined && values !== null) {
            value = (values.label.replace(/ /g, '')).split('-')
        }

        setFilterSearchKeyValue("published_to", value.slice(-1).toString());
        setFilterCountSearchKeyValue("published_to", value.slice(-1).toString());
    }

    const setOrderBy = (value) => {
        setFilterSearchKeyValue("OrderBy", value)
        setFilterCountSearchKeyValue("OrderBy", value)
    };

    const clearFilters = () => {
        let copyOfObject = JSON.parse(JSON.stringify(filter));
        copyOfObject["searchTerms"]['name'] = '';
        copyOfObject["searchTerms"]['providerGLN'] = null;
        copyOfObject["searchTerms"]['published_to'] = null;
        copyOfObject["searchTerms"]['category'] = [];
        copyOfObject["searchTerms"]['sub_category'] = [];
        copyOfObject["searchTerms"]['brand'] = [];
        copyOfObject["searchTerms"]['status'] = [];
        copyOfObject["searchTerms"]['date_sync_start'] = '';
        copyOfObject["searchTerms"]['date_sync_end'] = '';
        copyOfObject["searchTerms"]['date_publication_start'] = '';
        copyOfObject["searchTerms"]['date_publication_end'] = '';
        copyOfObject["searchTerms"]['images'] = undefined;
        setFilter(copyOfObject);

        let copyOfFilterCount = JSON.parse(JSON.stringify(filterCount));
        copyOfFilterCount["searchTerms"]['name'] = '';
        copyOfFilterCount["searchTerms"]['providerGLN'] = null;
        copyOfFilterCount["searchTerms"]['published_to'] = null;
        copyOfFilterCount["searchTerms"]['category'] = [];
        copyOfFilterCount["searchTerms"]['sub_category'] = [];
        copyOfFilterCount["searchTerms"]['brand'] = [];
        copyOfFilterCount["searchTerms"]['status'] = [];
        copyOfFilterCount["searchTerms"]['date_sync_start'] = '';
        copyOfFilterCount["searchTerms"]['date_sync_end'] = '';
        copyOfFilterCount["searchTerms"]['date_publication_start'] = '';
        copyOfFilterCount["searchTerms"]['date_publication_end'] = '';
        copyOfFilterCount["searchTerms"]['images'] = undefined;
        setFilterCount(copyOfFilterCount);

    };

    return {
        filter,
        filterCount,
        setCount,
        setPageNumber,
        setPageSize,
        setType,
        setGtin,
        setBrand,
        setCategory,
        setDescription,
        setImages,
        setName,
        setProductType,
        setProviderGLN,
        setStatus,
        setSubCategory,
        setCallToken,
        setSku,
        setOrderBy,
        clearFilters,
        setPublicationDateEnd,
        setPublicationDateStart,
        setSyncDateEnd,
        setSyncDateStart,
        setPublishedTo
    };
}
