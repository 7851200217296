import React from "react";
import DashPanel from "./DashPanel";
import BorderStyleIcon from '@mui/icons-material/BorderStyle';

export default function DiscontinuedDashPanel({panel}) {
    let panelStyle = {
        backgroundColor: "#002c6c", 
        color: "white",
        borderRadius: "10px",
        padding: "25px 10px 25px 25px",
        justifyContent: "center",
        fontWeight: "400",
        fontSize: "18px",
        lineHeight: "22px",
        marginRight: "10px"
    }
    return  <DashPanel panelStyle={panelStyle} panelData={panel} icon={<BorderStyleIcon />} status={"4"}/>;
}