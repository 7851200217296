import React, {useRef} from 'react';
import { Link, Grid, Button, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Input } from '@mui/material';

export default function UploadArea({file, setFile, type}) {
    const inputRef = useRef(null);

    let textStylePending = {
        fontFamily: 'Proxima Nova',
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "28px",
        lineHeight: "34px",
        alignItems: "center",
        textAlign: "center",
        color: "#B6B6B6",
    }

    let textStyleUploaded = {
        fontFamily: 'Proxima Nova',
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "15px",
        lineHeight: "20px",
        alignItems: "center",
        textAlign: "center",
        color: "#1EB1E0",
    }

    let iconStylePending = {
        width: "80px",
        height: "80px",
        color: '#E4E4E4',
    }

    let iconStyleUploaded = {
        width: "80px",
        height: "80px",
        color: '#1EB1E0',
    }

    const uploadFile = () => {
        inputRef.current.click();
    }

    const handleFileChange = event => {
        const fileObj = event.target.files;
        if (!fileObj) {
          return;
        }

        setFile(fileObj);
      };

    const showData = () => {
        return  <Grid container xs={12} direction="row" justifyContent={"center"} alignItems="center" columnSpacing={2}>
                    <Grid item>     
                        {file !== undefined && file.length > 0?
                            <Typography><InsertDriveFileOutlinedIcon style={iconStyleUploaded} /></Typography>
                        :
                            <Typography><UploadFileIcon style={iconStylePending} /></Typography>
                        }
                    </Grid> 
                    <Grid item>
                        {file !== undefined && file.length > 0?
                            file.length === 1?
                                <Typography style={textStyleUploaded}>{file[0].name}</Typography>  
                            :   
                                <Typography style={textStyleUploaded}>{file.length} images uploaded</Typography>
                        :
                            <Typography style={textStylePending} >Choose File</Typography>
                        }
                    </Grid>
                </Grid>
    }

    let cardStylePending = {borderStyle: "dashed", borderWidth: "4px", borderColor: "#B6B6B6",}
    let cardStyleUploaded = {borderStyle: "dashed", borderWidth: "4px", borderColor: "#1EB1E0",}

    return <Card style={file !== undefined && file.length === 0? cardStylePending : cardStyleUploaded} sx={{ width: "100%" , maxWidth: "400px", minWidth: "500px" }}>
                <Input
                    style={{display: 'none'}} 
                    type="file"
                    inputRef={inputRef}
                    inputProps={{ accept: type === 'digitalAssets'?'image/*':'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', multiple: type === 'digitalAssets'?true:false }} 
                    onChange={handleFileChange}
                />
                <CardActionArea
                    onClick={uploadFile}
                >
                    <CardContent>
                        {showData()}
                    </CardContent>
                </CardActionArea>
            </Card>
}