import React, { useState, useEffect, useRef } from "react";
import { Box, Grid, Button, Typography,Select,  Modal, TextField,Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions  } from '@mui/material';
import { DataGridPro, GridToolbar, GridToolbarContainer, GridToolbarExport, GridRowModes, gridClasses, useGridApiContext   } from '@mui/x-data-grid-pro';
import toolsManagementApi from "../../../../tools/toolsManagementApi";
import useToken from "../../../App/useToken";
import useToolsNotifications from "../../../../tools/ToolsNotifications";
import PropTypes from 'prop-types';
import { options } from "../../../../permission/selectOptions";
import useUser from "../../../App/useUser";





export default function ComanyUsersQuickEdit({userList}){
    const { searchManagement, searchManagementCount, updateUser, getUserDetails} = toolsManagementApi();
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState(undefined);
    const {token} = useToken();
    const {user} = useUser();
    const {error, success} = useToolsNotifications();

    // Confirmation dialog functions ******************************
        const noButtonRef = React.useRef(null);
        const [promiseArguments, setPromiseArguments] = React.useState(null);

        // Checks if the rows are different
        function computeMutation(newRow, oldRow) {
            if(JSON.stringify(newRow) !== JSON.stringify(oldRow)){
                return newRow.id;
            }
            return null;
        }

        const processRowUpdate = React.useCallback(
            (newRow, oldRow) =>
            new Promise((resolve, reject) => {
                console.log(newRow)
                const mutation = computeMutation(newRow, oldRow);
                if (mutation) {
                // Save the arguments to resolve or reject the promise later
                setPromiseArguments({ resolve, reject, newRow, oldRow });
                } else {
                resolve(oldRow); // Nothing was changed
                }
            }),
            [],
        );

        const handleNo = () => {
            const { oldRow, resolve } = promiseArguments;
            resolve(oldRow); // Resolve with the old row to not update the internal state
            setPromiseArguments(null);
        };

        const handleYes = async () => {
            const { newRow, oldRow, reject, resolve } = promiseArguments;
            try {
                // Make the HTTP request to save in the backend
                updateRow(newRow);
                resolve(newRow);
                setPromiseArguments(null);
            } catch (error) {
                error("An unexpected error happened");
                reject(oldRow);
                setPromiseArguments(null);
            }
        };

        const handleEntered = () => {
            // The `autoFocus` is not used because, if used, the same Enter that saves
            // the cell triggers "No". Instead, we manually focus the "No" button once
            // the dialog is fully open.
            // noButtonRef.current?.focus();
        };

        const renderConfirmDialog = () => {
            if (!promiseArguments) {
            return null;
            }
        
            const { newRow, oldRow } = promiseArguments;
            const mutation = computeMutation(newRow, oldRow);
        
            return (
            <Dialog
                maxWidth="s"
                TransitionProps={{ onEntered: handleEntered }}
                open={!!promiseArguments}
            >
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent dividers>
                {`Pressing 'Yes' will update the user with id: ${mutation}.`}
                </DialogContent>
                <DialogActions>
                <Button ref={noButtonRef} onClick={handleNo}>
                    No
                </Button>
                <Button onClick={handleYes}>Yes</Button>
                </DialogActions>
            </Dialog>
            );
        };
    //*************************************************/

    function SelectEditInputCell(props) {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();
        const tempOptions = options[user.user_type]["User"]["user_type"];
    
        const handleChange = async (event) => {
        await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
        apiRef.current.stopCellEditMode({ id, field });
        };
    
        return (
        <Select
            value={value}
            onChange={handleChange}
            size="small"
            sx={{ height: 1 }}
            native
            autoFocus
        >
            {tempOptions.map((op) => (
                <option value={op.value}>{op.label}</option>
            ))}
        </Select>
        );
    }
    
    SelectEditInputCell.propTypes = {
        /**
         * The column field of the cell that triggered the event.
         */
        field: PropTypes.string.isRequired,
        /**
         * The grid row id.
         */
        id: PropTypes.oneOfType([PropTypes.any, PropTypes.number, PropTypes.string]).isRequired,
        /**
         * The cell value.
         * If the column has `valueGetter`, use `params.row` to directly access the fields.
         */
        value: PropTypes.any,
    };
    
    const renderSelectEditInputCell = (params) => {
        return <SelectEditInputCell {...params} />;
    };




    const columns = [
        //{editable:true, field: 'user_image', headerName: 'Image', flex: 0.5, minWidth: 75, renderCell: (params) => {return <ProfileImg imgUrl={params.row.user_image} />}, renderEditCell: (params) =>  renderProfileEditInputCell(params)},//renderEditCell
        {type: 'text', field: 'id', headerName: 'ID', flex: 0.3, minWidth: 50, width: 50}, 
        {editable:true, type: 'text', field: 'email', headerName: 'Email', flex: 1, minWidth: 75, width: 75},
        {editable:true, type: 'text', field: 'user_name', headerName: 'User Name', flex: 1, minWidth: 75, width: 75},
        {editable:true, type: 'text', field: 'user_password', headerName: 'User Password', flex: 1, minWidth: 75, width: 75},
        {editable:true, type: 'text', field: 'first_name', headerName: 'First Name', flex: 1, minWidth: 75, width: 75},
        {editable:true, type: 'text', field: 'last_name', headerName: 'Last name', flex: 1, minWidth: 75, width: 75},
        {editable:true, type: 'text', field: 'user_telephone', headerName: 'Telephone', flex: 1, minWidth: 75, width: 75},
        {editable:true,type: 'text', field: 'user_type', headerName: 'Type', flex: 1, minWidth: 75, renderCell: (params) => userType(params.row.user_type), renderEditCell: renderSelectEditInputCell, width: 75}, //renderEditCell
        {type: 'text', field: 'CompanyName', headerName: 'Company Name', flex: 1, minWidth: 75, width: 75},
        {type: 'text', field: 'InformationProviderGLN', headerName: 'Information Provider GLN', flex: 1, minWidth: 75, width: 75},
        {type: 'text', field: 'is_active', headerName: 'Is Active', flex: 0.3, minWidth: 50, renderCell: (params) => userState(params.row.is_active), width: 50},
    ]

    useEffect(() => {
        setUsers(userList);        
    }, [userList])

    useEffect(() => {
        if(users !== undefined) {
            setLoading(false);
        }
    }, [users])

    const userState = ((active) => {
        let component = <Typography variant="userDisabled">Disabled</Typography>
        
        if(active == 1) {
            component = <Typography variant="userActive">Active</Typography>
        }
    
        return component
    });

    const userType = ((numType) => {
        const types = {
            '1': 'Supplier Admin',
            '2': 'Data Recipient Admin',
            '3': 'GS1 Admin',
            '4': 'Data Recipient [Public]',
            '5': 'Supplier User',
            '6': 'Data Recipient User',
            '7': 'GS1 User'
        }

        const style = {
            fontFamily: "Proxima Nova",
            color: "#4D4D4D",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "14.62px",
        }
        
        return <Typography sx={style}>{types[numType] !== undefined ? types[numType] : "Type not found"}</Typography>
    })

    const updateRow = (newValue) => {
        let userSend = {...newValue}
        userSend["token"] = token;
        updateUser(userSend).then(result => {
            if(result.code === 1) {
                success("User updated successfully");
            } else {
                error("User update error");
            }
        });
    }
    
    
    return  <div style={{ height: '100%', width: '100%' , overflow: "auto"}}>
                {renderConfirmDialog()}
                <DataGridPro
                    //onProcessRowUpdateError={(error) => console.log(error)} 
                    editMode="row"
                    experimentalFeatures={{ newEditingApi: true }}
                    processRowUpdate={processRowUpdate}
                    rows={users !== undefined ? users : []}
                    columns={columns}
                    loading={loading}
                    disableSelectionOnClick
                    getRowId={(row) => {return row.id}}
                />
            </div>
}