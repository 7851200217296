const productDataDisplayName =  
{
    agency: 'Agency',
    id: 'Id',
    isBaseUnit: 'Is Base Unit',
    brandOwnerGLN: 'Brand Owner GLN',
    grossWeight: 'Gross Weight',
    percentageOfAlcoholPerVolume: 'Percentage Of Alcohol Per Volume',
    rejected: 'Rejected',
    Category: 'Category',
    AgencyDescription: 'Agency Description', 
    dutyFeeTaxCategoryCode: 'Duty Fee Tax Category Code',
    totalQuantityOfNextLowerLevelTradeItem: 'Total Qty Of Next Lower Level Trade Item',
    quantityOfTradeItemsContainedInACompleteLayer: 'Qty Trade Items Contained In A Comp. Layer',
    gdsnRegistryStatus: 'GDSN registration status',
    globalClassificationCategoryCode: 'GPC Code',
    globalClassificationCategoryName: 'GPC Name',
    alternateItemIdentificationId: 'SKU',
    functionalname : 'Functional Name',
    functionalName : 'Functional Name',
    productDescription: 'Product Description',
    additionalDescription: 'Additional Description',
    productType : 'Product Type',
    isVariableWeightItem: 'Variable Weight Item',
    minimunTradeItemLifespanFromArrival: 'Shelf Life',
    irradiatedcode: 'Irradiated Code',
    geneticallyModifiedDeclarationCode: 'GMO Code',
    gs1TradeItemIdentificationKeyCode: 'Identification Key Code',
    gs1TradeItemIdentificationKeyValue: 'Identification Key Name',
    isTradeItemAService: 'Is Service',
    isTradeItemAConsumerUnit: 'Is Consumer Unit',
    AgencyCode: 'Agency Code',
    dutyFeeTaxAgencyCode: 'Duty Fee Tax Agency',
    dutyFeeTaxTypeCode: 'Duty Fee Tax Code',
    dutyFeeTaxTypeDescription: 'Duty Fee Tax Type Description',
    dutyFeeTaxRate: 'Tax Rate',
    tradeItemDateOnPackagingTypeCode: 'Date on Packaging',
    packagingTypeCode: 'Packaging Type Code',
    packagingWeight: 'Packaging Weight',
    packagingTypeDescription: 'Packaging Type Description',
    countryOfOriginCountryCode: 'Country of Origin',
    countryCode: 'Country of Origin',
    countryOfLastProvenance: 'Country of Manufacture',
    tradeItemTemperatureQualCode: 'Temperature Condition', 
    maximumTemperature: 'Temperature Max',
    minimumTemperature: 'Temperature Min',
    temperatureQualifierCode: 'temperature Qualifier Code',
    dangerousGoodsClassificationCode: 'Dangerous Goods Classification',
    dangerousGoodsHazardIdentificationNum: 'Hazard ID Num',
    dangerousGoodsRegulationCode: 'dangerous Goods Regulation Code',
    longProductDescription: 'Product Description',
    tradeItemFeatureBenefit1: 'Features and Benefits',
    tradeItemFeatureBenefit2: 'Features and Benefits',
    tradeItemFeatureBenefit3: 'Features and Benefits',
    tradeItemFeatureBenefit4: 'Features and Benefits',
    tradeItemFeatureBenefit5: 'Features and Benefits',
    tradeItemFeatureBenefit6: 'Features and Benefits',
    featureBenefit: 'feature Benefit',
    tradeItemKeywords: 'Keywords',
    ingredientStatement: 'Ingredients',
    fileFormatName: 'File Format',
    fileEffectiveStartDateTime: 'Valid From',
    fileEffectiveEndDateTime: 'Valid To',
    itemVerified: 'Verified',
    TargetMarketCountryCode: 'Target Market',
    targetMarket: 'Target Market',
    targetMarketName: 'Target Market Name',
    brandOwnerName: 'Brand Owner Name',
    brandName: 'Brand Name',
    informationProviderName: 'Information Provider Name',
    SupplierSKU: 'Sku',
    informationProviderGLN: 'Information Provider GLN',
    '#text': 'Value',
    effectiveDate: 'Effective Date',
    lastModifiedDate: 'last Modified Date',
    publicationDate: 'publication Date',
    startAvailabilityDate: 'start Availability Date',
    hi: 'hi',
    nonGTINPalletHi: 'non GTINPallet Hi',
    ti: 'ti',
    nonGTINPalletTi: 'non GTINPallet Ti',
    numberOfItemsPerPallet: 'number Of Items Per Pallet',
    quantityOfNextLevelWithinInnerPack: 'quantity Of Next Level Within Inner Pack',
    volume: 'volume',
    netContent: 'net Content',
    netWeight: 'net Weight',
    descriptiveSize: 'descriptive Size',
    countryOfOrigin: 'country Of Origin',
    languageSpecificBrandName: 'language Specific Brand Name',
    FunctionalName: 'Functional Name',
    InformationProviderName: 'Information Provider Name',
    shortDescription: 'Short Description',
    invoiceName: 'Invoice Name',
    consumerAvailabilityDateTime: 'consumer Availability Date Time',
    firstOrderDate: 'first Order Date',
    minimumTradeItemLifespanFromProduction: 'minimum Trade Item Lifespan From Production',
    minimumTradeItemLifespanFromArrival: 'minimum Trade Item Lifespan From Arrival',
    productMarkedRecyclable: 'product Marked Recyclable',
    isNetContentDeclaration: 'is Net Content Declaration',
    sellingUnitOfMeasure: 'selling Unit Of Measure',
    isRawMaterialIrradiated: 'is Raw Material Irradiated',
    isTradeItemSeasonal: 'is Trade Item Seasonal',
    tradeItemFinishDescription: 'trade Item Finish Description',
    rejectionReason: 'rejection Reason',
    RejectionCode: 'Rejection Code',
    minimumOrderQuantity: 'minimum Order Quantity',
    orderQuantityMultiple: 'order Quantity Multiple',
    pricingItemIndicator: 'pricing Item Indicator',
    diameter: 'diameter',
    subBrand: 'sub Brand',
    packageMarksFreeFrom: 'package Marks Free From',
    discontinueDate: 'discontinue Date',
    orderingUnitOfMeasure: 'ordering Unit Of Measure',
    variant: 'Variant',
    consumerStorageInstructions: 'consumer Storage Instructions',
    consumerUsageInstructions: 'consumer Usage Instructions',
    tradeItemKeyWords: 'trade Item Key Words',
    tradeItemMarketingMessage: 'trade Item Marketing Message',
    statement: 'ingredient statement'
};

export default productDataDisplayName;