import React, { useState, useEffect } from "react";
import { Link, Grid, Button, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import WestIcon from '@mui/icons-material/West';
import DoneIcon from '@mui/icons-material/Done';

export default function StepFour({setType, type, handleFirst}) {
    const navigate = useNavigate()

    const moveToProductBrowser = (() => {
        let targetUrl = "/product-browser"
        navigate(targetUrl);
    })

    return  <Grid container xs={12} item style={{padding: '50px'}}>
                <Grid container item xs={10} direction="column" rowSpacing={2}>
                    <Grid item container direction="row" columnSpacing={1} alignContent={"center"}>
                        <Grid item>
                            <Grid item><Typography variant={"mainTitleEnrichments"}>File uploaded successfully</Typography></Grid>
                        </Grid>                    
                    </Grid>
                    <Grid item container direction="column" rowSpacing={1}>
                        <Grid item>
                            <Typography variant={"mainEnrichmentsText"}>
                                <Typography variant={"mainEnrichmentsText"}> To enrich the product data of another section, select 
                                <Typography variant={"mainEnrichmentsText"} component="span" style={{color: '#DF5E3F', fontWeight: '700', fontSize: "14px"}}> "Enrichments" </Typography> 
                                    to return to Step 1 alternatively click on <Typography style={{color: '#71A654', fontWeight: '700',fontSize: "14px"}} component="span">"Finish Process"</Typography> to complete your enrichment.</Typography>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container direction="row" columnSpacing={1}>
                        <Grid item><Button variant={"enrichmentsOrange"} onClick={handleFirst}><Typography variant={"enrichmentsButton"}>Enrichments</Typography></Button></Grid>
                        <Grid item><Button variant={"enrichmentsGreen"} endIcon={<DoneIcon />} onClick={moveToProductBrowser}><Typography variant={"enrichmentsButton"}>Finish process</Typography></Button></Grid>
                    </Grid>  
                </Grid>              
            </Grid>;
}