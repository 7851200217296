import React, { useState, useEffect } from "react";
import useToken from '../App/useToken';
import { Grid, Typography, TextField, Button, Autocomplete, FormControlLabel, Switch, Link, Chip, Box} from '@mui/material';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ToggleButton from '@mui/material/ToggleButton';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SearchIcon from '@mui/icons-material/Search';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import toolsProductBrowserApi from "../../tools/toolsProductBrowserApi";
import Multiselect from '../Multiselect/Multiselect';
import { SimpleDialog } from '../Dialog/SimpleDialog';
import { MultiExportDialog } from "../Dialog/MultiExportDialog";
import { useQuery } from '@tanstack/react-query';
import "./style.css";
import { useSelector, useDispatch } from 'react-redux';
import { setProductBrowserResults, setProductBrowserCountResults, setProductBrowserLocalPage } from '../../features/productBrowserSlice';
import useUser from '../App/useUser';
import columnOrderByTypes from "../ProductTable/columnOrderByTypes";
import { PbFiltersDialog } from '../Dialog/PbFiltersDialog';
import FilterListIcon from '@mui/icons-material/FilterList';
import { AutocompleteComboBox } from '../Autocomplete/AutocompleteComboBox';

export default function ProductBrowserFilters({prodCount, selectedRows, setView, view, setGtin, setBrand, setCategory, setImages, setName, setProviderGLN, setSubCategory, setCallToken, setPage, page, setLoading, setSku, setOrderBy, setStatus}) {
    const { getAvailableGlns, getDetailsFilter, searchProducts, exportMulitPdf, getCategoriesByGln } = toolsProductBrowserApi();
    const { token } = useToken();
    
    const filter = useSelector((state) => state.productBrowser.productBrowserFilter); //from redux store
    const filterCount = useSelector((state) => state.productBrowser.productBrowserFilterCount); //from redux store

    const [availableGlns, setAvailableGlns] = useState([]);
    const [availableBrands, setAvailableBrands] = useState([]);
    const [availableCategories, setAvailableCategories] = useState([]);
    const [availableSubCategories, setAvailableSubCategories] = useState([]);
    const [selectableSubCategories, setSelectableSubCategories] = useState([]);
    const [availableStatus, setAvailableStatus] = useState([]);

    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errorModalTitle, setErrorModalTitle] = useState(undefined);
    const [errorModalComponent, setErrorModalComponent] = useState(undefined);

    const [multiExportModalOpen, setMultiExportModalOpen] = useState(false);
    const [multiExportData, setMultiExportData] = useState(undefined);
    const { user } = useUser();
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectableBrands, setSelectableBrands] = useState([]);
    const [subcatAutocompleteValues, setSubcatAutocompleteValues] = useState([]);
    const [catAutocompleteValues, setCatAutocompleteValues] = useState([]);
    const [statusAutocompleteValues, setStatusAutocompleteValues] = useState([]);
    const [brandAutocompleteValues, setBrandAutocompleteValues] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setCallToken(token);
        hasSelectedFilters();
    }, [])

    useEffect(() => {
        hasSelectedFilters();
    }, [filter])

    // API CALLS
    const { data: availableGlnsResponse} = useQuery(['glns'], () => getAvailableGlns({"token": token}), {
    });    

    const { data: detailsFilterResponse } = useQuery(['details'], () => getDetailsFilter({"token": token}),{ 
    });

    const dispatch = useDispatch();

    // STATE CHANGE SUBSCRIPTIONS
    useEffect(() => {
        if( detailsFilterResponse != undefined && !detailsFilterResponse.error){
            if(detailsFilterResponse.response != undefined){
                if(detailsFilterResponse.response.brands != undefined && Array.isArray(detailsFilterResponse.response.brands) && availableBrands.length === 0){
                    setSelectableBrands(detailsFilterResponse.response.brands);
                    let availableBrandsB = [];
                    detailsFilterResponse.response.brands.map((elem, i) => {
                        availableBrandsB.push(elem.label)
                    })
                    let availableBrandsF = availableBrandsB.filter((item,index)=>{
                        return availableBrandsB.indexOf(item) === index;
                    })
                    let availableBrandsMulti = []
                    availableBrandsF.map((item,index)=>{
                        availableBrandsMulti.push({label: item, value: item, key: item})
                    })
                    setAvailableBrands(availableBrandsMulti);
                    
                }
                if(detailsFilterResponse.response.main_category != undefined && Array.isArray(detailsFilterResponse.response.main_category) && availableCategories.length === 0) {
                    let availableCat = [];
                    detailsFilterResponse.response.main_category.map((elem, i) => {
                        availableCat.push({label: elem.label, value: elem.value, key: elem.value})
                    })
                    setAvailableCategories(availableCat);
                }
                if(detailsFilterResponse.response.sub_category != undefined && Array.isArray(detailsFilterResponse.response.sub_category) && selectableSubCategories.length === 0) {
                    let availableSubCat = [];
                    detailsFilterResponse.response.sub_category.map((elem, i) => {
                        availableSubCat.push({label: elem.label, value: elem.value, key: elem.value, category: elem.category})       
                    })
                    setAvailableSubCategories(availableSubCat);
                    setSelectableSubCategories(availableSubCat);
                }

                if(detailsFilterResponse.response.status != undefined && Array.isArray(detailsFilterResponse.response.status) && availableStatus.length === 0) {
                    let availableSta = [];
                    detailsFilterResponse.response.status.map((elem, i) => {
                        availableSta.push({label: elem.label, value: elem.value, key: elem.value})       
                    })
                    
                    availableSta.sort(function (a, b) {
                        if (a.label < b.label) {
                            return -1;
                        }
                        if (a.label > b.label) {
                            return 1;
                        }
                        return 0;
                    });

                    setAvailableStatus(availableSta);
                }
            }
        }
        if( availableGlnsResponse != undefined && !availableGlnsResponse.error && Array.isArray(availableGlnsResponse.data)){
            let availableGlnsB = []
            availableGlnsResponse.data.sort(function(a, b) {
                if (a.CompanyName !== null && b.CompanyName !== null) {
                    var textA = a.CompanyName.toUpperCase();
                    var textB = b.CompanyName.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                } else {
                    return 0;
                }                       
            }).map((elem, i) => {
                availableGlnsB.push({ label: elem.CompanyName + ' - ' + elem.InformationProviderGLN, id: i});
            })
            setAvailableGlns(availableGlnsB);
        }
        if(filter.token == "") {
            setCallToken(token);
        }

        //Set selected autocomplete values
        //Subcategories
        let selectedSubCategories = [];
        selectedSubCategories = availableSubCategories.filter(subcat => filter.search.sub_category.includes(subcat.value));
        setSubcatAutocompleteValues(selectedSubCategories);
        
        //Categories
        let selectedCategories = [];
        selectedCategories = availableCategories.filter(cat => filter.search.category.includes(cat.value));
        setCatAutocompleteValues(selectedCategories);

        //Status
        let selectedStatus = [];
        selectedStatus = availableStatus.filter(status => filter.search.status.includes(status.value));
        setStatusAutocompleteValues(selectedStatus);

        //Brands
        let selectedBrands = [];
        selectedBrands = availableBrands.filter(brand => filter.search.brand.includes(brand.value));
        setBrandAutocompleteValues(selectedBrands);

    }, [availableGlnsResponse, detailsFilterResponse]);

    useEffect(() => {
        if(page !== "") {
            searchProducts(filter).then(result => {
                setLoading(false);
                if(!result.error) {
                    if(result.data != undefined && result.data.products != undefined && Array.isArray(result.data.products)) {
                        dispatch(setProductBrowserResults(result.data.products));
                    }
                }
            });
            if(page === "0"){
                searchProducts(filterCount).then(result => {
                    if(!result.error) {
                        if(result.data != undefined && result.data.products != undefined && Array.isArray(result.data.products)) {
                            dispatch(setProductBrowserCountResults(parseInt(result.data.productCount, 10)));
                        }
                    }
                });
            }
            setPage("");
        }
        if(filter.token == "") {
            setCallToken(token);
        }
    }, [page]);

    useEffect(() => {
        if(multiExportData !== undefined){
            exportMulitPdf(multiExportData).then(result => {
                if(result.code === 1) {
                    setErrorModalTitle("Export PDF Successfully");
                    setErrorModalComponent( <Button 
                                            variant='main' 
                                            component={Link}
                                            href={result.response}
                                            download="multiexport.zip">
                                                    Download zip
                                            </Button>);
                } else {
                    setErrorModalTitle("Export Error");
                    setErrorModalComponent(<Typography>An error occurred during the export</Typography>);
                }
            });
        }
    }, [multiExportData])

    useEffect(()=> {
        if(errorModalComponent !== undefined && errorModalTitle !== undefined){
            setErrorModalOpen(true);
        }
        
    }, [errorModalComponent, errorModalTitle])

    // HANDLERS    
    const handleViewChange = (event, nextView) => {
        if (nextView !== null) {
            setView(nextView);
        }
    };

    const handleMultiExport = (nameFormat) => {
        let processedRows = []
        selectedRows.map((row) => {
            //published-GTIN-GLN-TM
            let splitted = row.split("-");
            processedRows.push({
                GTIN: splitted[1],
                InformationProviderGLN: splitted[2],
                TargetMarketCountryCode: splitted[3],
            });
        })

        setMultiExportData({search_gtin: processedRows, token: token, format: nameFormat})
    }

    const loadSubcategory = ((category) => {
        let selectable = [];
        let selectedCategoriesValues = [];
        
        category.map((cat) => {
            selectedCategoriesValues.push(cat.value)
        })

        if(selectedCategoriesValues !== undefined && selectedCategoriesValues.length > 0) {
            availableSubCategories.map((subcat, index) => {
                if(selectedCategoriesValues.indexOf(subcat.category) > -1) {
                    selectable.push(subcat);
                }
            })
        }

        setSelectableSubCategories(selectable);
    })

    const handleSearch =  (() => {
        setLoading(true);
        setPage("0");
        dispatch(setProductBrowserLocalPage(0));
    })

    const handleCloseErrorModal = (() => {
        setErrorModalOpen(false);
        setErrorModalTitle(undefined);
        setErrorModalComponent(undefined);
    })

    const handleCloseMultiExportModalSuccess = ((nameFormat) => {
        handleMultiExport(nameFormat);
        setMultiExportModalOpen(false);
    })

    const handleCloseMultiExportModalFail = (() => {
        setMultiExportModalOpen(false);
    })

    const handleExport = (() => {
        if(selectedRows.length === 0) {
            setErrorModalTitle("No Rows Selected");
            setErrorModalComponent(<Typography>Select at least 1 row for exportation</Typography>);
        } else {
            setMultiExportModalOpen(true);
        }
    })

    const handleClickOpen = () => {
        setOpen(true);
    };

    const fixedFilters = () => {
        return  <Grid container item  xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={gridCellStyle}>
                        <TextField 
                            label="GTIN"
                            id="gtin-modal" 
                            variant="outlined" 
                            style={inputText}
                            value={filter.search.GTIN}
                            onChange={(e) => {setGtin(e.target.value)}}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={gridCellStyle}>
                        <TextField 
                            label="Product Name"
                            id="name-modal"
                            variant="outlined" 
                            style={inputText}
                            value={filter.search.name}
                            onChange={(e) => {setName(e.target.value)}}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={gridCellStyle}>
                        {availableGlns != undefined && Array.isArray(availableGlns) ?
                            <AutocompleteComboBox
                                id="fixedFilters"
                                filter={filter}
                                availableGlns={availableGlns}
                                user={user}
                                getCategoriesByGln={getCategoriesByGln}
                                setAvailableCategories={setAvailableCategories}
                                setAvailableSubCategories={setAvailableSubCategories}
                                setSelectableSubCategories={setSelectableSubCategories}
                                selectableBrands={selectableBrands}
                                setAvailableBrands={setAvailableBrands}
                                setProviderGLN={setProviderGLN}
                                token={token}
                            />
                            :
                            "Loading GLNs"
                        }
                    </Grid>
                </Grid>;
    };

    const hasSelectedFilters = () => {
        return  filter.search.GTIN !== undefined && filter.search.GTIN !== '' ||
                filter.search.name !== undefined && filter.search.name !== '' ||
                filter.search.providerGLN !== null ||
                filter.search.category.length > 0 ||
                filter.search.sub_category.length > 0 ||
                filter.search.brand.length > 0 ||
                filter.search.sku !== undefined ||
                filter.search.status.length > 0 || filter.search.images !== undefined;
    };
    
    // STYLES
    let filtersStyle = {width:"100%", padding: "41px 44px 41px 44px", fontFamily: "Proxima Nova", background: '#FFFFFF',boxShadow: '6px 6px 10px rgba(30, 177, 224, 0.1)'};
    let gridCellStyle = {padding:"5px 5px"};
    let inputText = {fontWeight: "100", width:"100%", fontFamily: "Proxima Nova"};
    let inputStyle = { 
        width: "100%!important", 
        fontFamily: "Proxima Nova", 
        height: "50.24px",
        "&:root": {
            fontWeight: "100",
            heigth: "1rem",
            fontSize: "1rem",
            lineHeight: "1rem",
        },
    };
    let toggleStyle = {textTransform: "none", selected:{background: "primary"}};
    
    return  <Grid container direction="row" justifyContent="space-between" alignItems="center" style={filtersStyle} sx={{ height: { xs: "15%", md: "15%", lg: "15%", xl: "15%" }}}> 
                <Grid item xs={1.5}>
                    <Typography component="span">Products </Typography><Typography variant="important">{prodCount}</Typography>
                </Grid>
                <Grid item style={{paddingLeft: '20px'}} xs={2}> 
                        <TextField 
                            label="GTIN"
                            id="gtin-modal" 
                            variant="outlined" 
                            style={inputText}
                            value={filter.search.GTIN}
                            onChange={(e) => {setGtin(e.target.value)}}
                        />
                </Grid>
                <Grid item style={{paddingLeft: '20px'}} xs={2}>
                        <TextField 
                            label="Product Name"
                            id="name-modal"
                            variant="outlined" 
                            style={inputText}
                            value={filter.search.name}
                            onChange={(e) => {setName(e.target.value)}}
                        />
                </Grid>
                <Grid item style={{paddingLeft: '20px'}} xs={2}>
                        {availableGlns != undefined && Array.isArray(availableGlns) ?
                            <AutocompleteComboBox
                                id="topFilters"
                                filter={filter}
                                availableGlns={availableGlns}
                                user={user}
                                getCategoriesByGln={getCategoriesByGln}
                                setAvailableCategories={setAvailableCategories}
                                setAvailableSubCategories={setAvailableSubCategories}
                                setSelectableSubCategories={setSelectableSubCategories}
                                selectableBrands={selectableBrands}
                                setAvailableBrands={setAvailableBrands}
                                setProviderGLN={setProviderGLN}
                                token={token}
                            />
                            :
                            "Loading GLNs"
                        }
                </Grid>
                <Grid item style={{paddingLeft: '20px', position: 'relative'}} xs={1.5}>
                    {hasSelectedFilters()?<Box style={{position: 'absolute',
                            width: '14px',
                            height: '14px',
                            right: '-8px',
                            top: '-8px',
                            background: '#E16C43',
                            border: '1px solid #E16C43', zIndex: '9', borderRadius: '15px'}}></Box>:''}
                    <Button variant="inverted-main" style={inputText} startIcon={<FilterListIcon />} onClick={handleClickOpen}>All Filters</Button>
                    
                </Grid>
                <Grid item style={{paddingLeft: '20px'}} xs={1.5}>
                    <Button variant="orange-secondary-nosize" style={inputText} startIcon={<PictureAsPdfIcon/>} onClick={handleExport}>Export PDF</Button>
                </Grid>
                <Grid item style={{paddingLeft: '20px'}} xs={1.5} position="relative">
                    <Button variant="orange-nosize" style={inputText} onClick={handleSearch} startIcon={<SearchIcon/>} > Search </Button>
                </Grid>
                <PbFiltersDialog 
                    fixedFilters={fixedFilters()}
                    availableCategories={availableCategories}
                    setCategory={setCategory}
                    setCatAutocompleteValues={setCatAutocompleteValues}
                    loadSubcategory={loadSubcategory}
                    catAutocompleteValues={catAutocompleteValues}
                    selectableSubCategories={selectableSubCategories}
                    setSubCategory={setSubCategory}
                    setSubcatAutocompleteValues={setSubcatAutocompleteValues}
                    subcatAutocompleteValues={subcatAutocompleteValues}
                    availableBrands={availableBrands}
                    setBrand={setBrand}
                    setBrandAutocompleteValues={setBrandAutocompleteValues}
                    brandAutocompleteValues={brandAutocompleteValues}
                    filter={filter}
                    setSku={setSku}
                    availableStatus={availableStatus}
                    setStatus={setStatus}
                    setStatusAutocompleteValues={setStatusAutocompleteValues}
                    statusAutocompleteValues={statusAutocompleteValues}
                    setImages={setImages}
                    open={open}
                    setOpen={setOpen}
                    handleSearch={handleSearch}
                    setCallToken={setCallToken}
                    token={token}
                />
                <SimpleDialog 
                    open={errorModalOpen} 
                    onClose={handleCloseErrorModal} 
                    title={errorModalTitle} 
                    component={errorModalComponent} 
                />
                <MultiExportDialog 
                    open={multiExportModalOpen} 
                    onCloseSuccess={handleCloseMultiExportModalSuccess}
                    onCloseFail={handleCloseMultiExportModalFail}
                />
            </Grid>
}