import React, { useEffect, useState } from "react";
import { Tabs, Fab, Box, Typography, Grid } from '@mui/material';
import DAMDetail from "./DAMDetail";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PublicDAMDetail from './PublicDAMDetail';

export default function DAM({data, productPermission, userAbility, isPreview, isPublicDomain}) {
    const [selectedDam, setSelectedDam] = useState(0);
    const [productImages, setProductImages] = useState(data["images"]);
    const [mainImage, setMainImage] = useState(data["image_id"]);
    const [hasPrimary, setHasPrimary] = useState(false);
    const left = () => {
        setSelectedDam(selectedDam-1 >= 0 ? selectedDam-1 : 0)
    }

    const right = () => {
        setSelectedDam(selectedDam+1 < productImages.length ? selectedDam+1 : productImages.length-1)
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 1 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    useEffect(()=>{
        productImages.map((img)=>{
            if(img.damInfo.is_primary) {
                setHasPrimary(img.damInfo.is_primary)
            }
        });
    }, []);

    return  data !== undefined && productImages.length > 0 ?
                <Grid container width={"100%"} direction="column">
                    <Grid item container direction="row" width={"100%"}></Grid>
                    <Grid 
                        item
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        xs={12}>
                        {data.enrichments == undefined && isPublicDomain === undefined?
                        <Grid item xs={1}>
                            <Fab 
                                size="small"
                                color="primary" 
                                aria-label="add"
                                disabled={selectedDam-1 < 0} 
                                onClick={left}
                            >
                                <ArrowBackIosNewIcon />
                            </Fab>
                        </Grid>:''}
                        <Grid item xs={isPublicDomain === undefined?10:12}><Box style={{overflow: "auto"}}>
                            {productImages.map((damElement, key) =>(
                                <TabPanel value={selectedDam} index={key}>
                                {isPublicDomain !== undefined ?
                                    <PublicDAMDetail data={damElement} mainImage={mainImage} setMainImage={setMainImage} isPreview={isPreview} productImages={productImages} setProductImages={setProductImages} productPermission={productPermission} userAbility={userAbility} selectedDam={selectedDam} setSelectedDam={setSelectedDam}/ >
                                :
                                    <DAMDetail data={damElement} mainImage={mainImage} setMainImage={setMainImage} isPreview={isPreview} productImages={productImages} setProductImages={setProductImages} productPermission={productPermission} userAbility={userAbility} selectedDam={selectedDam} setSelectedDam={setSelectedDam} hasPrimary={hasPrimary}/>
                                }
                                </TabPanel>
                            ))}
                        </Box></Grid>
                        {data.enrichments == undefined && isPublicDomain === undefined?
                        <Grid item xs={1}>
                            <Fab 
                                size="small"
                                color="primary" 
                                aria-label="add"
                                disabled={selectedDam === productImages.length-1} 
                                onClick={right}
                            >
                                <ArrowForwardIosIcon />
                            </Fab>
                        </Grid>:''}
                    </Grid>
                    
                </Grid>
            : 
            <Typography variant="productSubtitle">
                No data found
            </Typography>
}