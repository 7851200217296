import React from "react";
import DashPanel from "./DashPanel";
import WarningIcon from '@mui/icons-material/Warning';

export default function WithdrawnDashPanel({panel}) {
    let panelStyle = {
        backgroundColor: '#535353', 
        color: "white",
        borderRadius: "10px",
        padding: "25px 10px 25px 40px",
        justifyContent: "center",
        fontWeight: "400",
        fontSize: "18px",
        lineHeight: "22px",
        marginBottom: "10px",
        marginRight: "10px",
        overflowWrap: 'anywhere'
    }
    
    return  <DashPanel panelStyle={panelStyle} panelData={panel} icon={<WarningIcon />} status={"3"}/>;
}