import React from "react";
import { Grid, Box, Typography} from '@mui/material';
import DOMPurify from "dompurify";
import placeholder from "../../img/message-test.jpg";

export default function DashboardMsgElem({data}) {
    let panelStyle = {
        filter: "drop-shadow(0px 4px 4px rgba(50, 132, 198, 0.1))",
        minHeight: '360px',
        paddingBottom: '5px',
        paddingLeft: '5px'
    }
    let imgBoxStyle = {
        background: "#FFFFFF",
        boxShadow: "6px 0px 12px rgba(50, 132, 198, 0.1)",
        borderRadius: "10px",
        justifyContent: "center",
        alignItems: "center",
    };
    let imgStyle = {
        width: "60%",
        borderRadius: "9px",
    };
    let textBoxStyle = {
        padding: "40px 60px 40px 60px",
    }
    let titleStyle = {
        fontFamily: "Proxima Nova",
        color: "#3284C6",
        fontWeight: "800",
        fontSize: "20px",
        lineHeight: "24px",
    }
    let dateStyle = {
        fontFamily: "Proxima Nova",
        fontWeight: "400",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#B6B6B6",
        marginTop: "5px",
    }
    let bodyStyle = {
        fontFamily: "Proxima Nova",
        fontWeight: "400",
        fontSize: "10px",
        textAlign: "justify",
        color: "#4D4D4D",
        lineHeight: "0.9",
        padding: "10px 20px 10px 20px",
        marginTop: "10px",
        lineHeight: '0.75'
    }
    let bodyBoxStyle = {
        
    }
    
    return  <Box  item style={panelStyle}>
                
                    <Typography variant="h2" component="h2" style={titleStyle}>
                        {data.title}
                    </Typography>
                    <Typography variant="h4" component="h4" style={dateStyle}>
                        {data.created_at}
                    </Typography>
                    <Box  className="ck-content" dangerouslySetInnerHTML={{ __html: data.message}}>
                    </Box>
                
            </Box>;
}